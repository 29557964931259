import useSWR, {SWRConfiguration} from 'swr';
import {IGoogleGradeProcessWithStatus} from '../../types/google/GoogleProcess';
import {clearTimeout, setTimeout} from 'timers';
import {IErrorResponse} from 'src/types/common/Error';
import {useNavigate} from 'react-router-dom';

export enum GradeProcessorStatus {
  PENDING = 'pending',
  COMPLETE = 'complete',
  ERROR = 'error'
}

export interface IGradeProcessResponse {
  processorData: IGoogleGradeProcessWithStatus | undefined;
  processorError: IErrorResponse | undefined;
}

const DELAY = 1000;
const STATUS_URL = `${process.env.REACT_APP_BACKEND_URL}/google/process`;

export const useProcessGrade = (uuid: string | undefined): IGradeProcessResponse => {
  const navigate = useNavigate();
  let timeoutInterval: NodeJS.Timeout | undefined;

  const {data, error, mutate} = useSWR<IGoogleGradeProcessWithStatus>(
    uuid ? `${STATUS_URL}/${uuid}` : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data: IGoogleGradeProcessWithStatus) => {
        if (data.status30Days === GradeProcessorStatus.ERROR || data.status90Days === GradeProcessorStatus.ERROR) {
          if (timeoutInterval) clearTimeout(timeoutInterval);
          navigate('/google-grader/error?event=process');
          return;
        }
        if (data.status30Days === GradeProcessorStatus.PENDING || data.status90Days === GradeProcessorStatus.PENDING) {
          timeoutInterval = setTimeout(mutate, DELAY);
        } else {
          if (timeoutInterval) clearTimeout(timeoutInterval);
        }
      }
    } as SWRConfiguration
  );

  return {
    processorData: data,
    processorError: error
  };
};
