export const colors = {
  marine: {
    primary: '#0046FF',
    tint: '#E0E9FF',
    interactive: '#0035C2'
  },
  accent: {
    primary: '#FF4B1B',
    tint: '#FFE8E0'
  },
  gray: {
    light: '#F0F0F0',
    neutral: '#AEAEAD',
    mid: '#716F6F',
    dark: '#292928',
    legacyTint: '#D7D7D7'
  },
  common: {
    white: '#FFFFFF',
    black: '#000000',
    facebookBlue: '#4267B2'
  },
  cream: {
    background: '#F9F5E8',
    tint: '#FDFBF7',
    blocking: '#F9F5E8'
  },
  blue: {
    lightBackground: '#0074C2',
    darkBackground: '#009BFF'
  },
  green: {
    lightBackground: '#008059',
    darkBackground: '#7DBE50'
  },
  orange: {
    lightBackground: '#BA5012',
    darkBackground: '#E86417'
  },
  red: {
    lightBackground: '#C20F1E',
    darkBackground: '#F03C4B'
  },
  interactive: {
    primary: {
      focus: '#E0E9FF'
    },
    black: {
      hover: '#FFFFFFC2'
    }
  },
  score: {
    red: '#BE1E3C',
    redTint: '#F2D2D8',
    yellow: '#FFD000',
    yellowTint: '#FFF6CC',
    green: '#7DBE50',
    greenTint: '#E5F2DC'
  },
  chart: {
    blue: '#009BFF',
    blueLight: '#D3DDEF',
    orange: '#FF6437',
    orangeLight: '#FFD3BC',
    green: '#7DBE50',
    lightGray: '#EEEEEE',
    purple: '#AA50E6'
  }
};
