import {Typography, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {theme} from 'src/components/theme/Theme';
import {useFacebookGrade} from 'src/hooks/facebook/useFacebookGrade';
import {ArrowWithPercentage} from 'src/components/common/metrics/ArrowWithPercentage';
import {LineHeight} from 'src/components/theme/overrides/LineHeight';

export const StatsRow = (): JSX.Element => {
  const {t} = useTranslation(['facebook']);
  const {UUID} = useParams();
  const {grade} = useFacebookGrade(UUID);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ResponsiveRow
      sx={{justifyContent: 'space-around'}}
      gap={isMobile ? 3 : 5}
      breakpoint={600}
    >
      <FlexBoxColumn gap={1} sx={{flex: '1 0 0'}}>
        <BoldTypography variant='h4'>
          {grade?.accountSummary.totalReach.toLocaleString()}
        </BoldTypography>
        <FlexBoxColumn gap={0.5}>
          <BoldTypography variant='overline' textAlign='center'>{t('metrics.accountSummary.reach')}</BoldTypography>
          <Typography variant='caption' textAlign='center' lineHeight={LineHeight.MODERATE}>
            {t('metrics.accountSummary.reachDescription')}
          </Typography>
        </FlexBoxColumn>
      </FlexBoxColumn>
      {grade && <ArrowWithPercentage />}
      <FlexBoxColumn gap={1} sx={{flex: '1 0 0'}}>
        <BoldTypography variant='h4'>
          {grade?.accountSummary.engagements.toLocaleString()}
        </BoldTypography>
        <FlexBoxColumn gap={0.5}>
          <BoldTypography variant='overline' textAlign='center'>{t('metrics.accountSummary.engagements')}</BoldTypography>
          <Typography variant='caption' textAlign='center' lineHeight={LineHeight.MODERATE}>
            {t('metrics.accountSummary.engagementsDescription')}
          </Typography>
        </FlexBoxColumn>
      </FlexBoxColumn>
      <ArrowWithPercentage />
      <FlexBoxColumn gap={1} sx={{flex: '1 0 0'}}>
        <BoldTypography variant='h4'>
          {grade?.accountSummary.totalResults.toLocaleString()}
        </BoldTypography>
        <FlexBoxColumn gap={0.5}>
          <BoldTypography variant='overline'>{t('metrics.accountSummary.results')}</BoldTypography>
          <Typography variant='caption' textAlign="center" lineHeight={LineHeight.MODERATE}>
            {t('metrics.accountSummary.resultsDescription')}
          </Typography>
        </FlexBoxColumn>
      </FlexBoxColumn>
    </ResponsiveRow>
  );
};
