import {TableCell, Typography, TableRow as TableRowBasic} from '@mui/material';
import {theme} from 'src/components/theme/Theme';
import {colors} from 'src/components/theme/common/colors';
import styled from 'styled-components';

export const BlackTableRow = styled(TableRowBasic)({
  boxShadow: 'none',
  borderBottom: `${theme.spacing(0.125)} solid ${colors.gray.neutral}`,
  background: colors.gray.dark,
  color: theme.palette.common.white,
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.common.white
  }
});

const WhiteTableCell = styled(TableCell)({
  color: 'white'
});

type Props = {
  title: string;
  boldTitle?: boolean;
  activeAudiences?: number;
  boldActiveAudiences?: boolean;
  impressions?: number;
  boldImpressions?: boolean;
  conversions?: number;
  boldConversions?: boolean;
  cpc?: number;
  boldCpc?: boolean;
  withBackground?: boolean;
  currencyCode?: string;
}

export const AdPerformanceTableRow = ({title,
  boldTitle,
  activeAudiences,
  boldActiveAudiences,
  impressions,
  boldImpressions,
  conversions,
  boldConversions,
  cpc,
  boldCpc,
  withBackground}: Props): JSX.Element => {
  const TableRow = withBackground ? BlackTableRow : TableRowBasic;
  const TableCellWithColor = withBackground ? WhiteTableCell : TableCell;
  return (
    <>
      <TableRow sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
        <TableCellWithColor align='left'>
          <Typography fontWeight={boldTitle ? 600 : 400} variant='h6' sx={{textAlign: 'left'}}>{title}</Typography>
        </TableCellWithColor>
        <TableCellWithColor align='right' sx={{pr: 5}}>
          <Typography fontWeight={boldActiveAudiences ? 600 : 400} variant='h6'>{activeAudiences?.toLocaleString(process.env.REACT_APP_LOCALE)}</Typography>
        </TableCellWithColor>
        <TableCellWithColor align='right'>
          <Typography fontWeight={boldImpressions ? 600 : 400} variant='h6'>{impressions?.toLocaleString(process.env.REACT_APP_LOCALE)}</Typography>
        </TableCellWithColor>
        <TableCellWithColor align='right'>
          <Typography fontWeight={boldConversions ? 600 : 400} variant='h6'>{conversions?.toLocaleString(process.env.REACT_APP_LOCALE)}</Typography>
        </TableCellWithColor>
        <TableCellWithColor align='right'>
          <Typography fontWeight={boldCpc ? 600 : 400} variant='h6'>{cpc?.toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2, style: 'currency', currency: 'USD'})}</Typography>
        </TableCellWithColor>
      </TableRow>
    </>
  );
};

type MobileProps = {
  text: string;
  value: number | string;
  isTextBold?: boolean;
  isValueBold?: boolean;
}

export const TableRowMobile = ({text, value}: MobileProps): JSX.Element => {

  return (
    <TableRowBasic sx={{display: 'flex', justifyContent: 'space-between', alignSelf: 'stretch'}}>
      <TableCell size='small' component='td' sx={{fontWeight: 600}} align='left'>{text}</TableCell>
      <TableCell size='small' component='td' align='right'>{value}</TableCell>
    </TableRowBasic>
  );
};
