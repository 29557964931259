import {useMediaQuery} from '@mui/material';
import {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {GoogleAdTypeContext} from 'src/components/providers/adType';
import {useGGContext} from 'src/components/providers/googleAds';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {useDisplayGrade} from 'src/hooks/display/useDisplayGrade';
import {useGoogleGrade} from 'src/hooks/google/useGoogleGrade';
import {useOverallGrade} from 'src/hooks/overall/useOverallGrade';
import {IDisplayGrade} from 'src/types/display/DisplayGrade';
import {IGoogleGrade} from 'src/types/google/GoogleGrade';
import {IOverallGrade} from 'src/types/overall/OverallGrades';
import {IAccount} from 'src/types/common/Account';
import {theme} from 'src/components/theme/Theme';
import {IErrorResponse} from 'src/types/common/Error';
import useGATracking from 'src/hooks/google/useGATracking';
import {ConditionalClearTimeout, DelayNavigationToTimedOutError, TimeIDRef} from 'src/utils/helpers';
import {ServiceType} from 'src/enums/ServiceType';
import {useShoppingGrade} from 'src/hooks/shopping/useShoppingGrade';
import {IShoppingGrade} from 'src/types/shopping/ShoppingGrade';

const dataLayer = {
  event: 'gaTriggerEvent',
  gaEventCategory: 'Grader',
  gaEventAction: 'Impression',
  gaEventLabel: 'GraderStepReport'
};

type GetGradeReturnType = {
  grade: IGoogleGrade | IDisplayGrade | IShoppingGrade | IOverallGrade | undefined;
  error: IErrorResponse | undefined;
  b4Shopping?: boolean;
}

const getGrade = (uuid: string | undefined, googleAdType: GoogleAdType): GetGradeReturnType => {
  switch (googleAdType) {
    case GoogleAdType.DISPLAY:
      return {grade: useDisplayGrade(uuid).displayGrade, error: useDisplayGrade(uuid).displayError};
    case GoogleAdType.SEARCH:
      return {...useGoogleGrade(uuid)};
    case GoogleAdType.SHOPPING:
      return {grade: useShoppingGrade(uuid).shoppingGrade, error: useShoppingGrade(uuid).shoppingError};
    case GoogleAdType.OVERALL:
      return {grade: useOverallGrade(uuid).overallGrade, error: useOverallGrade(uuid).overallError, b4Shopping: useOverallGrade(uuid).b4Shopping};
    default:
      return {grade: undefined, error: undefined};
  }
};

interface SetAccountParams {
  grade: IGoogleGrade | IDisplayGrade | IShoppingGrade | IOverallGrade;
  googleAdType: GoogleAdType;
  setter: Dispatch<SetStateAction<IAccount>>;
}

const setAccount = (params: SetAccountParams): void => {
  let accountId, displayName;
  switch (params.googleAdType) {
    case GoogleAdType.OVERALL:
      accountId = (params.grade as IOverallGrade).accountId;
      displayName = (params.grade as IOverallGrade).accountId;
      if ((params.grade as IOverallGrade).accountName) {
        displayName = (params.grade as IOverallGrade).accountName
          + ' (' + (params.grade as IOverallGrade).accountId + ')';
      }
      break;
    default:
      accountId = (params.grade as IGoogleGrade | IDisplayGrade | IShoppingGrade).overallScore.accountId;
      displayName = (params.grade as IGoogleGrade | IDisplayGrade | IShoppingGrade).overallScore.accountId;
      if ((params.grade as IGoogleGrade | IDisplayGrade | IShoppingGrade).overallScore.accountName) {
        displayName = (params.grade as IGoogleGrade | IDisplayGrade | IShoppingGrade).overallScore.accountName
          + ' (' + (params.grade as IGoogleGrade | IDisplayGrade | IShoppingGrade).overallScore.accountId + ')';
      }
  }
  params.setter({accountId, accountName: displayName});
};

interface SetZeroDollarParams {
  grade: IGoogleGrade | IDisplayGrade | IShoppingGrade | IOverallGrade;
  googleAdType: GoogleAdType;
  setter: Dispatch<SetStateAction<boolean>>;
  b4Shopping: boolean;
}

const setZeroDollar = (params: SetZeroDollarParams): void => {
  switch (params.googleAdType) {
    case GoogleAdType.SEARCH:
      params.setter((params.grade as IGoogleGrade)
        .searchPerformanceMetrics?.spend === 0);
      break;
    case GoogleAdType.DISPLAY:
      params.setter((params.grade as IDisplayGrade)
        .displayPerformanceMetrics.spend === 0);
      break;
    case GoogleAdType.SHOPPING:
      params.setter((params.grade as IShoppingGrade)
        .shoppingPerformanceMetrics.spend === 0);
      break;
    case GoogleAdType.OVERALL:
      if (params.b4Shopping) {
        params.setter((params.grade as IOverallGrade).displayScores?.spend === 0 && (params.grade as IOverallGrade).searchScores.spend === 0);
      } else {
        params.setter((params.grade as IOverallGrade).spend === 0);
      }
  }
};

type ReturnType = {
  isZeroDollarsSpent: boolean;
  isMobile: boolean;
}

export const useReport = (): ReturnType => {
  const context = useGGContext();
  const {UUID} = useParams();
  const navigate = useNavigate();
  const {googleAdType} = useContext(GoogleAdTypeContext);
  const {grade, error, b4Shopping = false} = getGrade(UUID, googleAdType);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isZeroDollarsSpent, setIsZeroDollarSpent] = useState<boolean>(false);
  const timeIDRef: TimeIDRef = {
    timeID: undefined
  };
  const {pushCustomGAEvent} = useGATracking(
    {
      pageViewDefaults: {title: 'GoogleAdsGrader Report'},
      triggerOnMount: true
    });

  useEffect(() => {
    if (UUID === undefined) navigate('/');
  }, []);

  useEffect(() => {
    if (!grade && !error) {
      timeIDRef.timeID = DelayNavigationToTimedOutError(navigate, ServiceType.GOOGLE);
    } else if (grade && !error) {
      ConditionalClearTimeout(timeIDRef);
      // Currently we are pushing event only with Search Report
      if (googleAdType === GoogleAdType.SEARCH) {
        pushCustomGAEvent(dataLayer);
      } else if (googleAdType === GoogleAdType.DISPLAY) {
        pushCustomGAEvent({...dataLayer, gaEventLabel: 'GraderDisplay'});
      } else if (googleAdType === GoogleAdType.SHOPPING) {
        pushCustomGAEvent({...dataLayer, gaEventLabel: 'GraderShopping'});
      } else if (googleAdType === GoogleAdType.OVERALL) {
        pushCustomGAEvent({...dataLayer, gaEventLabel: 'GraderOverview'});
      }
      setAccount({grade, googleAdType, setter: context.setGoogleAdsAccount});
      setZeroDollar({grade, googleAdType, setter: setIsZeroDollarSpent, b4Shopping});
    } else if (error) {
      ConditionalClearTimeout(timeIDRef);
      navigate('/google-grader/error?event=noRoute');
    }

    return () => ConditionalClearTimeout(timeIDRef);
  }, [grade, error, b4Shopping]);

  return {isZeroDollarsSpent, isMobile};
};
