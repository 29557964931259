import {Typography} from '@mui/material';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {GradeUtils} from 'src/utils/GradeUtils';
import {ScoreSectionType} from 'src/enums/google/ScoreSection';
import {useContext} from 'react';
import {ServiceContext} from 'src/components/providers/service';
import {theme} from 'src/components/theme/Theme';
import {FlexBoxRow} from '../../FlexBoxRow';
import {colors} from 'src/components/theme/common/colors';
import {ScoreName} from 'src/enums/ScoreName';
import styled from 'styled-components';
import {useGradeSectionScore} from 'src/hooks/common/useGradeSectionScore';

type Props = {
  sectionName: ScoreSectionType;
}

const Container = styled(FlexBoxColumn)`
  padding: ${({theme}) => `${theme.spacing(4)} ${theme.spacing(7)}`};
  border: ${({theme}) => `${theme.spacing(0.125)} solid ${colors.gray.dark}`};
  border-radius: ${({theme}) => theme.spacing(1)};
  gap: ${({theme}) => theme.spacing(2)};
`;

const ScoreNameContainer = styled(FlexBoxRow)<{scoreName: ScoreName}>`
  width: ${({theme}) => theme.spacing(15)};
  border: ${({theme}) => theme.spacing(0.25)} solid ${({scoreName}) => GradeUtils.getScoreColor(scoreName, theme)};
  border-radius: ${({theme}) => theme.spacing(0.5)};
  background: white;
  box-sizing: border-box;
`;

export const Score = ({sectionName}: Props): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const {scoreName, scoreValue} = useGradeSectionScore({service, sectionName});

  return <Container>
    {
      scoreName
        ? <>
          <FlexBoxColumn sx={{
            width: theme.spacing(12), height: theme.spacing(12), borderRadius: '50%',
            backgroundColor: GradeUtils.getScoreColor(scoreName, theme)
          }}>
            <FlexBoxColumn>
              <img src={GradeUtils.getIcon(scoreName)} alt='Score Icon'
                width={theme.spacing(5.652)} height={theme.spacing(5.652)} />
              <ScoreNameContainer scoreName={scoreName} >
                <Typography variant="overline">
                  {scoreName}
                </Typography>
              </ScoreNameContainer>
            </FlexBoxColumn>
          </FlexBoxColumn>
          <Typography variant="h3" className='display'>{scoreValue}</Typography>
        </>
        : <></>
    }
  </Container>;
};
