import {SxProps, Theme, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {GoogleAdsScoreSection} from 'src/enums/google/ScoreSection';
import {ScoreName} from 'src/enums/ScoreName';
import {useGGradeScore} from 'src/hooks/google/useGGradeScore';
import {IGoogleGrade} from 'src/types/google/GoogleGrade';
import {GradeUtils} from 'src/utils/GradeUtils';
import {ReactComponent as AlertIcon} from 'src/assets/AlertIconCurrent.svg';
import {ServiceType} from 'src/enums/ServiceType';
import {theme} from 'src/components/theme/Theme';
import styled from 'styled-components';

interface Props {
  sectionName: GoogleAdsScoreSection;
}

const Metric = ({sectionName, grade}: Props & {grade: IGoogleGrade}) => {
  switch (sectionName) {
    case GoogleAdsScoreSection.WASTED_SPEND:
      return grade.negativeKeyword.wasteLocal
        .toLocaleString(
          process.env.REACT_APP_LOCALE,
          {minimumFractionDigits: 2, style: 'currency', currency: grade.currencyCode}
        );
    case GoogleAdsScoreSection.QUALITY_SCORE:
      return grade.qualityScore.duration90.weightedQualityScore
        .toLocaleString(
          process.env.REACT_APP_LOCALE,
          {maximumFractionDigits: 1, minimumFractionDigits: 1}
        );
    case GoogleAdsScoreSection.IMPRESSION_SHARE:
      return (grade.impressionShare.impressionShare / 100)
        .toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 0, style: 'percent'});
    case GoogleAdsScoreSection.ACCOUNT_ACTIVITY:
      return grade.accountActivity.duration30.accountStats.totalChanges
        .toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 0});
  }
};

interface IconProps {
  scorename: ScoreName | undefined;
  theme: Theme;
}

const StyledAlertIcon = styled(AlertIcon)`
  fill: ${({scorename, theme}: IconProps) => scorename ? GradeUtils.getScoreColor(scorename, theme) : 'none'};
`;

const Duration = ({sectionName}: Props): JSX.Element => {
  const Text = (text: string) => <Typography variant='overline'
    textTransform={'uppercase'} sx={{fontWeight: 'fontWeightMedium'}}>
    {text}
  </Typography>;

  switch (sectionName) {
    case GoogleAdsScoreSection.WASTED_SPEND:
      return Text('Next 12 Months');
    case GoogleAdsScoreSection.ACCOUNT_ACTIVITY:
      return Text('Last 30 Days');
    default:
      return <></>;
  }
};

interface ContentProps {
  title: string;
  scoreName: ScoreName | undefined;
  sectionName: GoogleAdsScoreSection;
  metric: string;
}

const Content = ({title, scoreName, sectionName, metric}: ContentProps): JSX.Element => {
  const sx: SxProps = {
    whiteSpace: sectionName === GoogleAdsScoreSection.WASTED_SPEND ? 'pre' : 'normal',
    width: sectionName !== GoogleAdsScoreSection.WASTED_SPEND ? theme.spacing(36) : 'auto'
  };

  return (
    <FlexBoxColumn gap={2}>
      <FlexBoxColumn gap={0.5} sx={{...sx}}>
        <BoldTypography textAlign={'center'} variant='h4'>
          {title}
        </BoldTypography>
        {Duration({sectionName})}
      </FlexBoxColumn>
      <FlexBoxRow gap={1}>
        {
          scoreName !== ScoreName.ADVANCED && <StyledAlertIcon scorename={scoreName} width={40} height={40} />
        }
        <Typography variant='h3' whiteSpace={'pre'}>{metric}</Typography>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export const SearchKeyMetric = ({sectionName, sectionKey}: Props & {sectionKey: string}): JSX.Element => {
  const {searchGrade, scoreName} = useGGradeScore({sectionName});
  const metric = searchGrade && Metric({sectionName, grade: searchGrade});
  const {t} = useTranslation([ServiceType.GOOGLE]);

  return searchGrade
    ? Content(
      {
        title: t('grade.keyMetricScoreTitle', {context: sectionKey}),
        scoreName,
        sectionName,
        metric: metric ?? '-'
      }
    )
    : <></>;
};
