import {LinearProgress, LinearProgressProps} from '@mui/material';
import {useProgress} from './useProgress';
import {theme} from 'src/components/theme/Theme';
import styled from 'styled-components';

interface IProps extends LinearProgressProps {
  first: number;
  middle: number;
  last: number;
}

const LinearProgresss = styled(LinearProgress).attrs((props: IProps) => ({...props, variant: 'determinate'}))(({first, middle, last}: IProps) => ({
  width: '100%',
  background: theme.palette.grey[500],
  height: theme.spacing(2.5),
  borderRadius: theme.spacing(2),
  span: {
    borderRight: `${theme.spacing(0.125)} solid ${theme.palette.common.white}`,
    background: `linear-gradient(90deg, #0046FF ${first}%, #F03C4B ${middle}%, #FF6437 ${last}%)`
  }
}));

type Props = {
  callback?: () => void;
  throttle?: boolean;
  barheight?: number;
}

export const StyledLinearProgress = ({callback, throttle}: Props): JSX.Element => {
  const {value} = useProgress({callback, throttle});
  const transformX = 100 - value;
  // 52.08th %
  const middle = transformX + value * 0.5208;
  // 100 %
  const last = transformX + 100;
  return <LinearProgresss value={value} first={transformX} middle={middle} last={last} />;
};
