import {useTranslation} from 'react-i18next';
import {ServiceType} from 'src/enums/ServiceType';
import {useMetricsSubscribed} from 'src/hooks/common/useMetricsSubscribed';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {Divider, Switch, Typography} from '@mui/material';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {useContext, useEffect, useState} from 'react';
import {ServiceContext} from 'src/components/providers/service';
import BadgeFooter from 'src/components/common/BadgeFooter';
import GooglePartnerSVG from 'src/assets/GooglePartner.svg';
import {GoogleAdsPage} from 'src/components/googleAds/page/Page';
import {GridType} from 'src/enums/GridType';
import {ContentCardForErrors} from 'src/components/common/ContentCard';
import {FacebookAdsPage} from 'src/components/facebook/page/Page';
import MetaPartnerSVG from '../assets/MetaPartner.svg';
import {colors} from '../components/theme/common/colors';
import {Email} from '../components/common/metrics/Email';

export const PerformanceTrackerEmail = (): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const {t} = useTranslation([ServiceType.GOOGLE, ServiceType.FACEBOOK]);
  const [manualToggle, setManualToggle] = useState(false);
  const {subscribed, onToggle} = useMetricsSubscribed();

  const handleManualToggle = () => {
    setManualToggle(true);
    onToggle();
  };

  useEffect(() => {
    if (subscribed && !manualToggle) {
      onToggle();
    }
  }, [subscribed]);

  return (
    <FlexBoxColumn gap={1}>
      <FlexBoxColumn>
        <FlexBoxColumn gap={1}>
          <Typography variant="h4" fontWeight={'fontWeightMedium'}>{t('error.footer.nospend_header')}</Typography>
          <FlexBoxRow>
            <Typography variant='body1'>{t('error.footer.nospend_email_subscribe')}</Typography>
            <Switch color={'success'} checked={subscribed} onChange={handleManualToggle} />
          </FlexBoxRow>
        </FlexBoxColumn>
        <Divider sx={{width: '100%', borderBottomWidth: 1, my: 2}} />
        {service === ServiceType.GOOGLE
          ? <BadgeFooter i18nNameSpace={ServiceType.GOOGLE} badgeSVG={GooglePartnerSVG} iconHref={t('googleBadgeLink')} />
          : <BadgeFooter i18nNameSpace={ServiceType.FACEBOOK} iconHref={t('facebookBadgeLink')} badgeSVG={MetaPartnerSVG} />}
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export const UnsubscribeCardContent = (): JSX.Element => {
  const {t} = useTranslation(['common']);

  return (
    <ContentCardForErrors title={t('unsubscribe.header')} body={t('unsubscribe.message')}>
      <Divider sx={{width: '100%', marginY: 5, borderColor: colors.gray.legacyTint}} />
      <Email />
    </ContentCardForErrors>
  );
};

export const Unsubscribe = (): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const {t} = useTranslation([ServiceType.GOOGLE, ServiceType.FACEBOOK]);

  return (
    <>
      { service === ServiceType.GOOGLE
        ? <GoogleAdsPage
          gridType={GridType.FORM}
          additionalFooter={<BadgeFooter i18nNameSpace={ServiceType.GOOGLE} badgeSVG={GooglePartnerSVG} iconHref={t('googleBadgeLink')} />}
        >
          <UnsubscribeCardContent />
        </GoogleAdsPage>
        : <FacebookAdsPage
          gridType={GridType.FORM}
          additionalFooter={<BadgeFooter i18nNameSpace={ServiceType.FACEBOOK} iconHref={t('facebookBadgeLink')} badgeSVG={MetaPartnerSVG} />}
        >
          <UnsubscribeCardContent />
        </FacebookAdsPage>}
    </>
  );
};
