import {Divider, Table, TableBody, TableCell, TableHead, TableRow as TableRowBasic} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FacebookAdsScoreSection} from 'src/enums/facebook/ScoreSection';
import {useFAGradeScore} from 'src/hooks/facebook/useFAGradeScore';
import {getScoreName} from '../table/getScoreName';
import {TableRow} from '../table/TableRow';
import {YouVsCompetitorMobile} from '../table/YouVsCompetitorMobile';
import {ServiceType} from 'src/enums/ServiceType';
import {AccountActivityStatNameKey} from 'src/enums/facebook/AccountActivity';
import {colors} from 'src/components/theme/common/colors';
import {theme} from 'src/components/theme/Theme';

export const AccountActivityResults = (): JSX.Element => {
  const {grade} = useFAGradeScore({sectionName: FacebookAdsScoreSection.ACCOUNT_ACTIVITY});
  const {t} = useTranslation([ServiceType.FACEBOOK]);

  return (
    <Table sx={{tableLayout: 'fixed'}}>
      <TableHead>
        <TableRowBasic sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
          <TableCell size='small' align='right' colSpan={2}>
            {t('grade.you')}
          </TableCell>
          <TableCell size='small' align='right'>
            {t('grade.competitorAvg')}
          </TableCell>
        </TableRowBasic>
      </TableHead>
      <TableBody>
        <TableRow
          withIcon={false}
          text={t(`${AccountActivityStatNameKey.ORGANIC_POSTS}`)}
          you={grade?.accountActivity.organicPosts}
          competitors={grade?.accountActivity.organicPostsBenchmark}
          scoreName={getScoreName({
            you: grade?.accountActivity.organicPosts,
            benchmark: grade?.accountActivity.organicPostsBenchmark,
            difference: grade?.accountActivity.organicPostsPercentDiff
          })}
        />
        <TableRow
          withIcon={false}
          text={t(`${AccountActivityStatNameKey.ADS_CREATED}`)}
          you={grade?.accountActivity.adsCreated}
          competitors={grade?.accountActivity.adsCreatedBenchmark}
          scoreName={getScoreName({
            you: grade?.accountActivity.adsCreated,
            benchmark: grade?.accountActivity.adsCreatedBenchmark,
            difference: grade?.accountActivity.adsCreatedPercentDiff
          })}
        />
        <TableRow
          withIcon={false}
          text={t(`${AccountActivityStatNameKey.ADS_CHANGED}`)}
          you={grade?.accountActivity.adsChanged}
          competitors={grade?.accountActivity.adsChangedBenchmark}
          scoreName={getScoreName({
            you: grade?.accountActivity.adsChangedPercentDiff,
            benchmark: grade?.accountActivity.adsChangedBenchmark,
            difference: grade?.accountActivity.adsChangedPercentDiff
          })}
        />
        <TableRow
          withIcon={false}
          text={t(`${AccountActivityStatNameKey.AUDIENCE_CHANGE}`)}
          you={grade?.accountActivity.audienceTypeChanges}
          competitors={grade?.accountActivity.audienceTypeChangesBenchmark}
          scoreName={getScoreName({
            you: grade?.accountActivity.audienceTypeChanges,
            benchmark: grade?.accountActivity.audienceTypeChangesBenchmark,
            difference: grade?.accountActivity.audienceTypeChangesPercentDiff
          })}
        />
        <TableRow
          withIcon={false}
          withBackground={true}
          text={t(`${AccountActivityStatNameKey.TOTAL_ACTIVITY}`)}
          you={grade?.accountActivity.totalActions}
          competitors={grade?.accountActivity.totalActionsBenchmark}
          scoreName={getScoreName({
            you: grade?.accountActivity.totalActions,
            benchmark: grade?.accountActivity.totalActionsBenchmark,
            difference: grade?.accountActivity.totalActionsPercentDiff
          })}
        />
      </TableBody>
    </Table>
  );
};

export const AccountActivityResultsMobile = (): JSX.Element => {
  const {grade} = useFAGradeScore({sectionName: FacebookAdsScoreSection.ACCOUNT_ACTIVITY});
  const {t} = useTranslation([ServiceType.FACEBOOK]);

  return (
    <Table sx={{width: '100%', tableLayout: 'fixed', borderSpacing: `0 ${theme.spacing(1)}`, borderCollapse: 'separate'}}>
      <TableBody>
        <YouVsCompetitorMobile title={t(`${AccountActivityStatNameKey.ORGANIC_POSTS}`)}
          scoreName={getScoreName({
            you: grade?.accountActivity.organicPosts,
            benchmark: grade?.accountActivity.organicPostsBenchmark,
            difference: grade?.accountActivity.organicPostsPercentDiff
          })}
          you={grade?.accountActivity.organicPosts}
          competitors={grade?.accountActivity.organicPostsBenchmark} />
        <TableRowBasic><TableCell colSpan={2} sx={{py: 0.5, px: 0}}><Divider sx={{width: '100%', borderColor: colors.gray.neutral}} /></TableCell></TableRowBasic>
        <YouVsCompetitorMobile title={t(`${AccountActivityStatNameKey.ADS_CREATED}`)}
          scoreName={getScoreName({
            you: grade?.accountActivity.adsCreated,
            benchmark: grade?.accountActivity.adsCreatedBenchmark,
            difference: grade?.accountActivity.adsCreatedPercentDiff
          })}
          you={grade?.accountActivity.adsCreated}
          competitors={grade?.accountActivity.adsCreatedBenchmark} />
        <TableRowBasic><TableCell colSpan={2} sx={{py: 0.5, px: 0}}><Divider sx={{width: '100%', borderColor: colors.gray.neutral}} /></TableCell></TableRowBasic>
        <YouVsCompetitorMobile title={t(`${AccountActivityStatNameKey.ADS_CHANGED}`)}
          scoreName={getScoreName({
            you: grade?.accountActivity.adsChangedPercentDiff,
            benchmark: grade?.accountActivity.adsChangedBenchmark,
            difference: grade?.accountActivity.adsChangedPercentDiff
          })}
          you={grade?.accountActivity.adsChanged}
          competitors={grade?.accountActivity.adsChangedBenchmark} />
        <TableRowBasic><TableCell colSpan={2} sx={{py: 0.5, px: 0}}><Divider sx={{width: '100%', borderColor: colors.gray.neutral}} /></TableCell></TableRowBasic>
        <YouVsCompetitorMobile title={t(`${AccountActivityStatNameKey.AUDIENCE_CHANGE}`)}
          scoreName={getScoreName({
            you: grade?.accountActivity.audienceTypeChanges,
            benchmark: grade?.accountActivity.audienceTypeChangesBenchmark,
            difference: grade?.accountActivity.audienceTypeChangesPercentDiff
          })}
          you={grade?.accountActivity.audienceTypeChanges}
          competitors={grade?.accountActivity.audienceTypeChangesBenchmark} />
        <TableRowBasic>
          <TableCell colSpan={2} padding='none' sx={{borderTop: `1px solid ${colors.gray.neutral}`}}>
            <Table sx={{width: '100%', borderSpacing: `0 ${theme.spacing(1)}`, borderCollapse: 'separate', background: colors.gray.light}}>
              <TableBody>
                <YouVsCompetitorMobile title={t(`${AccountActivityStatNameKey.TOTAL_ACTIVITY}`)}
                  scoreName={getScoreName({
                    you: grade?.accountActivity.totalActions,
                    benchmark: grade?.accountActivity.totalActionsBenchmark,
                    difference: grade?.accountActivity.totalActionsPercentDiff
                  })}
                  you={grade?.accountActivity.totalActions}
                  competitors={grade?.accountActivity.totalActionsBenchmark} />
              </TableBody>
            </Table>
          </TableCell>
        </TableRowBasic>
      </TableBody>
    </Table>
  );
};
