import {Divider, Table, TableBody, TableCell, TableRow} from '@mui/material';
import {IAPStats} from 'src/types/facebook/FacebookGrade';
import {TableRowMobile} from '../TableRowMobile';
import {colors} from 'src/components/theme/common/colors';
import {theme} from 'src/components/theme/Theme';
import {GradeUtils} from 'src/utils/GradeUtils';

export enum AudiencePerformanceStat {
  CTR = 'CTR',
  IMPRESSIONS = 'Impressions',
  CLICKS = 'Clicks',
  SPEND = 'Spend',
  RESULTS = 'Results'
}

interface Props extends IAPStats {
  title: string;
  color: string;
  currencySymbol: string;
}

export const APTableCellMobile = ({title, color, currencySymbol, ctr, impressions, clicks, spend, results}: Props): JSX.Element => {
  return (
    <Table sx={{width: '100%', tableLayout: 'fixed', borderSpacing: `0 ${theme.spacing(1)}`, borderCollapse: 'separate'}}>
      <TableBody>
        <TableRow>
          <TableCell padding='none' sx={{px: 1}}>
            {title}
          </TableCell>
        </TableRow>
        <TableRowMobile text={AudiencePerformanceStat.CTR} value={ctr ? `${ctr}%` : '-'} isBold={true} highlightColor={color} />
        <TableRowMobile text={AudiencePerformanceStat.IMPRESSIONS} value={impressions?.toLocaleString() ?? '-'} />
        <TableRowMobile text={AudiencePerformanceStat.CLICKS} value={clicks?.toLocaleString() ?? '-'} />
        <TableRowMobile text={AudiencePerformanceStat.SPEND} value={spend ? `${currencySymbol}${GradeUtils.roundoff(spend!, 2).toFixed(2)}` : '-'} />
        <TableRowMobile text={AudiencePerformanceStat.RESULTS} value={results?.toLocaleString() ?? '-'} />
        <TableRow><TableCell colSpan={2} padding='none'><Divider sx={{width: '100%', borderColor: colors.gray.neutral}} /></TableCell></TableRow>
      </TableBody>
    </Table>
  );
};
