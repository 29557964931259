import {Box, Button, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, styled} from '@mui/material';
import {FormProvider} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {StyledFormLabel} from 'src/components/common/forms/FormLabel';
import {FormInputText} from 'src/components/common/forms/inputs/FormInputText';
import {BusinessType} from 'src/enums/BusinessType';
import {IEmailKeywordResponse} from 'src/types/keywords/KeywordResponse';
import useEmailForm from './useEmailForm';
import {useSearchParams} from 'react-router-dom';
import {useEffect} from 'react';
import {FontWeight} from 'src/components/theme/overrides/FontWeight';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';

type Props = {
  onResponse: (response: IEmailKeywordResponse) => void;
  onClose: () => void;
}

const StyledForm = styled('form')`
  width: 100%;
  display: block;
`;

const EmailForm = ({onClose, onResponse}: Props): JSX.Element => {
  const {t} = useTranslation(['keywords', 'common']);
  const {isMobile, methods, onDownload} = useEmailForm({onClose, onResponse});
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('cid')) {
      methods.setValue('cid', searchParams.get('cid') || '');
    }
  }, [searchParams]);

  return (
    <FormProvider {...{...methods}}>
      <StyledForm
        onSubmit={methods.handleSubmit(onDownload)}
        data-testid="kwdEmailForm"
      >
        <FlexBoxColumn gap={isMobile ? 2.5 : 3.5} sx={{width: '100%', alignItems: 'flex-start'}}>
          <Typography variant='h5' fontWeight={FontWeight.SemiBold}>
            {t('emailModal.title')}
          </Typography>
          <FlexBoxColumn width={'100%'} sx={{gap: 1}}>
            <StyledFormLabel>{t('common:enterEmail', {context: 'modal'})}</StyledFormLabel>
            <FormInputText
              data-testid='keywordsDownloadEmail'
              name='email'
              size='medium'
              sx={{backgroundColor: 'common.white'}}
              placeholder='myemailaddress@gmail.com' />
          </FlexBoxColumn>
          <FlexBoxColumn width={'100%'} sx={{alignItems: 'flex-start', gap: 1}}>
            <FormLabel id='modal-modal-description' component='span' sx={{whiteSpace: 'normal'}}>
              {t('emailModal.businessType')}
            </FormLabel>
            <RadioGroup
              row={!isMobile}
              sx={{gap: 2, ml: 0}}
              name='isClient-radio-buttons-group'
            >
              <FormControlLabel control={<Radio
                size='medium'
                data-testid="businessTypeSelf"
                {...methods.register('businessType')}
                value={BusinessType.SELF}
                sx={{paddingX: 0, paddingY: 0.5, alignSelf: 'center'}}
              />} label={BusinessType.SELF} sx={{ml: 0}} />

              <FormControlLabel control={<Radio
                size='medium'
                {...methods.register('businessType')}
                value={BusinessType.CLIENT}
                data-testid="businessTypeClient"
                sx={{paddingX: 0, paddingY: 0.5, alignSelf: 'center'}}
              />} label={BusinessType.CLIENT} sx={{ml: 0}} />
            </RadioGroup>
            <Box sx={{alignSelf: 'flex-start', pt: 0.25, color: 'error.main'}}>
              {methods.formState.errors.businessType && <span>{methods.formState.errors.businessType.message}</span>}
            </Box>
          </FlexBoxColumn>
          <FlexBoxRow width={'100%'} sx={{justifyContent: 'flex-start'}}>
            <Button
              id='email-keywords'
              data-testid='emailKeywordsSubmit'
              variant='contained'
              disableElevation={true}
              color='secondary'
              type='submit'
              size='large'
            >
              {t('emailModal.getKeywords', {context: 'button'})}
            </Button>
          </FlexBoxRow>
        </FlexBoxColumn>
        <input type="hidden" {...methods.register('cid')} />
      </StyledForm>
    </FormProvider>
  );
};

export default EmailForm;
