import {Typography, useMediaQuery} from '@mui/material';
import {StyledCardWithPadding, StyledCardWithPaddingForErrors} from './styled/StyledCard';
import {FlexBoxColumn} from './FlexBoxColumn';
import {theme} from '../theme/Theme';

type Props = {
  title: string;
  body: string | JSX.Element;
  children?: JSX.Element | JSX.Element[];
}

export const ContentCard = ({title, body, children}: Props): JSX.Element => {
  return (
    <StyledCardWithPadding sx={{gap: 5}}>
      <FlexBoxColumn sx={{gap: 2}}>
        <Typography textAlign='center' variant='h3'>{title}</Typography>
        <Typography textAlign='center' variant='body1'>{body}</Typography>
      </FlexBoxColumn>
      {children}
    </StyledCardWithPadding>
  );
};

export const ContentCardForErrors = ({title, body, children}: Props): JSX.Element => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StyledCardWithPaddingForErrors>
      <FlexBoxColumn sx={{gap: isMobile ? 1 : 2, pb: children ? isMobile ? 3 : 5 : 0}}>
        <Typography textAlign='center' variant='h3'>{title}</Typography>
        <Typography textAlign='center' variant='body1'>{body}</Typography>
      </FlexBoxColumn>
      {children}
    </StyledCardWithPaddingForErrors>
  );
};
