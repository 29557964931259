import {Typography, useMediaQuery} from '@mui/material';
import {LineHeight} from 'src/components/theme/overrides/LineHeight';
import {theme} from 'src/components/theme/Theme';
import useDateRange from './useDateRange';

export const DateRange = (): JSX.Element => {
  const {dateRange} = useDateRange();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return <Typography data-testid="dateRange"
    variant='body1'
    sx={{fontSize: isMobile ? 14 : 16}}
    lineHeight={LineHeight.LOOSE}
    fontStyle="normal" color='common.black'>{dateRange}</Typography>;
};
