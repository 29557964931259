import {Divider, Typography, useMediaQuery} from '@mui/material';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {useTranslation} from 'react-i18next';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {StatsRow} from './StatsRow';
import {AccountStructure} from './AccountStructure';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {useFAGradeScore} from 'src/hooks/facebook/useFAGradeScore';
import {theme} from 'src/components/theme/Theme';
import {SpendRow} from 'src/components/common/metrics/PerformanceMetrics';
import {colors} from 'src/components/theme/common/colors';

export const AccountSummary = (): JSX.Element => {
  const {grade, currencySymbol} = useFAGradeScore({sectionName: undefined});
  const {t} = useTranslation(['facebook', 'common']);
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <FlexBoxColumn gap={isMobile ? 3 : 4.5} width={'100%'}>
      <ResponsiveRow breakpoint={600}
        sx={{alignSelf: 'stretch', justifyContent: 'space-between', marginBottom: isMobile ? 5 : 3.5}}
      >
        <BoldTypography variant='h4' color={'common.black'}>
          {t('metrics.accountSummary.metrics')}
        </BoldTypography>
        <Typography variant='body2' color={'text.disabled'}
          sx={{fontStyle: 'italic'}}
        >
          {t('last30', {ns: 'common'})}
        </Typography>
      </ResponsiveRow>
      <SpendRow
        spendText={t('metrics.accountSummary.spend')}
        spend={grade?.accountSummary.totalSpend.toLocaleString(process.env.LOCALE, {minimumFractionDigits: 2}) ?? '0'}
        currencySymbol={currencySymbol}
      >
        <StatsRow />
      </SpendRow>
      <Divider sx={{width: '100%', mt: isMobile ? 3 : 0.5, borderColor: colors.gray.neutral}} />
      <FlexBoxColumn gap={2}>
        <ResponsiveRow breakpoint={600}
          sx={{alignSelf: 'stretch', justifyContent: 'space-between', gap: 1}}
        >
          <BoldTypography variant='h4'>{t('metrics.accountSummary.accountStructure')}</BoldTypography>
          <Typography variant='body2' color={'text.disabled'} fontStyle='italic'>{t('metrics.accountSummary.current')}</Typography>
        </ResponsiveRow>
        <AccountStructure />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};
