import {styled, Theme, Typography, useMediaQuery} from '@mui/material';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {GradeUtils} from 'src/utils/GradeUtils';
import {ScoreName} from 'src/enums/ScoreName';
import {useDisplayGradeScore} from 'src/hooks/display/useDisplayGradeScore';
import {theme} from 'src/components/theme/Theme';
import {GoogleDisplayScoreSection} from 'src/enums/google/ScoreSection';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {colors} from 'src/components/theme/common/colors';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';

type Props = {
  metric?: number | string;
  metricLabel?: string;
  title?: string;
  description?: string;
  sectionName?: GoogleDisplayScoreSection;
};

interface ContainerProps {
  scorename: ScoreName;
  theme: Theme;
}

const Container = styled(ResponsiveRow)`
  gap: ${({theme}) => theme.spacing(3)};
  border-radius: ${({theme}) => theme.spacing(1)};
  border-left: ${({theme}) => theme.spacing(1)} solid ${({scorename, theme}: ContainerProps) => GradeUtils.getScoreColor(scorename, theme)};
  border-left-width: ${({theme}) => theme.spacing(2)};
  background-color: ${colors.cream.background};
  border-top: ${({theme}) => theme.spacing(0.125)} solid ${colors.gray.dark};
  border-bottom: ${({theme}) => theme.spacing(0.125)} solid ${colors.gray.dark};
  border-right: ${({theme}) => theme.spacing(0.125)} solid ${colors.gray.dark};
  padding: ${({theme}) => theme.spacing(5)} ${({theme}) => theme.spacing(5)};
  ${({theme}) => theme.breakpoints.down('sm')} {
    gap: ${({theme}) => theme.spacing(2)};
    padding: ${({theme}) => theme.spacing(3)};
    border-left-width: ${({theme}) => theme.spacing(1)};
  }
`;

export const DataSegment = ({metric, metricLabel, title, description, sectionName}: Props): JSX.Element => {
  const {scoreName} = useDisplayGradeScore({sectionName});
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <>
      {
        scoreName
          ? <Container scorename={scoreName} breakpoint={600} sx={{alignItems: isMobile ? 'center' : 'flex-start', alignSelf: 'stretch'}}>
            <FlexBoxColumn sx={{alignItems: 'flex-start', width: '100%'}} gap={2}>
              <BoldTypography variant='overline'>{title}</BoldTypography>
              <Typography variant='h3'>{metric} {metricLabel}</Typography>
              <Typography variant='h5'>{description}</Typography>
            </FlexBoxColumn>
          </Container>
          : <></>
      }
    </>
  );
};
