import {useTranslation} from 'react-i18next';
import {Typography, useMediaQuery} from '@mui/material';
import {ServiceType} from 'src/enums/ServiceType';
import {theme} from 'src/components/theme/Theme';
import {LineHeight} from 'src/components/theme/overrides/LineHeight';
import {colors} from 'src/components/theme/common/colors';
import {FlexBoxColumn} from '../../../common/FlexBoxColumn';
import {ChartLabel} from 'src/components/common/grade/charts/Labels';

export const ImpressionShareLabels = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.GOOGLE]);
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <FlexBoxColumn gap={isMobile ? 0.5 : 1} sx={{alignItems: 'flex-start', alignSelf: 'stretch', pl: isMobile ? 4 : 0, mt: isMobile ? -2 : 0}}>
      <ChartLabel bcolor={colors.chart.green}>
        <Typography sx={{pl: 1.5}} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t('grade.impressionShare.chart.shareAcquired')}
        </Typography>
      </ChartLabel>
      <ChartLabel bcolor={colors.chart.blue}>
        <Typography sx={{pl: 1.5}} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t('grade.impressionShare.chart.shareLostToBudget')}
        </Typography>
      </ChartLabel>
      <ChartLabel bcolor={colors.chart.orange}>
        <Typography sx={{pl: 1.5}} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t('grade.impressionShare.chart.shareLostToAdRank')}
        </Typography>
      </ChartLabel>
    </FlexBoxColumn>
  );
};
