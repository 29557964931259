import {useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {theme} from 'src/components/theme/Theme';
import {DisplayAdSection, DisplayAdType} from 'src/enums/display/DisplayAds';
import {useDisplayGrade} from 'src/hooks/display/useDisplayGrade';
import {AdditionalMetrics} from './AdditionalMetrics';
import {AdMetric, AdMetricMobile} from './AdMetric';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {IDisplayAdTypeMetrics} from 'src/types/display/DisplayGrade';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';

export const AdFormats = () : JSX.Element => {
  const {t} = useTranslation([GoogleAdType.DISPLAY]);
  const {UUID} = useParams();
  const {displayGrade} = useDisplayGrade(UUID);
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const adTypes = Object.values(DisplayAdType);

  return (
    <FlexBoxColumn sx={{width: '100%', gap: isMobile ? 2 : 3}}>
      <BoldTypography variant='h4' sx={{alignSelf: 'flex-start'}}>{t('grade.displayAdTypes.adFormatTypes')}</BoldTypography>
      <FlexBoxColumn sx={{width: '100%', gap: isMobile ? 4 : 6}}>
        {
          adTypes.map((adType: DisplayAdType) =>
            // eslint-disable-next-line
            displayGrade && displayGrade.adTypesStats && (displayGrade.adTypesStats[DisplayAdSection[adType]] as IDisplayAdTypeMetrics)
              ? isMobile
                ? <FlexBoxColumn key={`${DisplayAdSection[adType]}-mobile`} sx={{gap: 2}}>
                  <AdMetricMobile data={displayGrade.adTypesStats[DisplayAdSection[adType]] as IDisplayAdTypeMetrics} key={`${DisplayAdSection[adType]}-ad-metric-mobile`} />
                  <AdditionalMetrics
                    key={`${DisplayAdSection[adType]}-additional-metric-mobile`}
                    metrics={{
                      adsCount: (displayGrade.adTypesStats[DisplayAdSection[adType]] as IDisplayAdTypeMetrics).adsCount,
                      adGroupsCount: (displayGrade.adTypesStats[DisplayAdSection[adType]] as IDisplayAdTypeMetrics).adGroupsCount,
                      impressions: (displayGrade.adTypesStats[DisplayAdSection[adType]] as IDisplayAdTypeMetrics).impressions
                    }}
                  />
                </FlexBoxColumn>
                : <FlexBoxColumn key={`${DisplayAdSection[adType]}-desktop`} sx={{width: '100%', gap: 3}}>
                  <AdMetric data={displayGrade.adTypesStats[DisplayAdSection[adType]] as IDisplayAdTypeMetrics} key={`${DisplayAdSection[adType]}-ad-metric`} />
                  <AdditionalMetrics
                    key={`${DisplayAdSection[adType]}-additional-metric`}
                    metrics={{
                      adsCount: (displayGrade.adTypesStats[DisplayAdSection[adType]] as IDisplayAdTypeMetrics).adsCount,
                      adGroupsCount: (displayGrade.adTypesStats[DisplayAdSection[adType]] as IDisplayAdTypeMetrics).adGroupsCount,
                      impressions: (displayGrade.adTypesStats[DisplayAdSection[adType]] as IDisplayAdTypeMetrics).impressions
                    }}
                  />
                </FlexBoxColumn>
              : <></>
          )
        }
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};
