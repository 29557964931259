import {TableCell, TableHead, TableRow} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {ServiceType} from 'src/enums/ServiceType';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {colors} from 'src/components/theme/common/colors';
import {FontWeight} from 'src/components/theme/overrides/FontWeight';
import {pxToRem} from 'src/components/theme/overrides/Typography';
import {theme} from 'src/components/theme/Theme';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import styled from 'styled-components';

const TableCellSmall = styled(TableCell).attrs((props) => ({...props}))({
  padding: theme.spacing(1),
  paddingRight: 0,
  fontWeight: FontWeight.Regular,
  fontSize: pxToRem(12),
  lineHeight: pxToRem(16)
});

export const CustomerJourneyHeader = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);

  return <TableHead>
    <TableRow sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
      <TableCell size='small' align='left' sx={{verticalAlign: 'baseline'}}>
        <FlexBoxRow gap={2} sx={{justifyContent: 'flex-start'}}>
          <FlagOutlinedIcon />
          <BoldTypography variant='h4' sx={{fontSize: '24px', lineHeight: '30px', whiteSpace: 'pre'}}>
            {t('grade.campaignGoals.table.customerJourney.header')}
          </BoldTypography>
        </FlexBoxRow>
      </TableCell>
      <TableCell size='small'>
        <FlexBoxColumn sx={{alignItems: 'flex-end'}}>
          <TableCell sx={{padding: 0}} component='div'>
            {t('grade.campaignGoals.table.customerJourney.awarenessStage')}
          </TableCell>
          <TableCellSmall component='div'>
            {t('grade.campaignGoals.table.customerJourney.impressions')}
          </TableCellSmall>
        </FlexBoxColumn>
      </TableCell>
      <TableCell size='small'>
        <FlexBoxColumn sx={{alignItems: 'flex-end'}}>
          <TableCell sx={{padding: 0}} component='div'>
            {t('grade.campaignGoals.table.customerJourney.considerationStage')}
          </TableCell>
          <TableCellSmall component='div'>
            {t('grade.campaignGoals.table.customerJourney.clicks')}
          </TableCellSmall>
        </FlexBoxColumn>
      </TableCell>
      <TableCell size='small'>
        <FlexBoxColumn sx={{alignItems: 'flex-end'}}>
          <TableCell sx={{padding: 0}} component='div'>
            {t('grade.campaignGoals.table.customerJourney.conversionStage')}
          </TableCell>
          <TableCellSmall component='div'>
            {t('grade.campaignGoals.table.customerJourney.results')}
          </TableCellSmall>
        </FlexBoxColumn>
      </TableCell>
    </TableRow>
  </TableHead>;
};
