import {useTranslation} from 'react-i18next';
import {ServiceType} from 'src/enums/ServiceType';
import {Divider, Table, TableBody, TableCell, TableHead, TableRow as TableRowBasic} from '@mui/material';
import {getScoreName} from 'src/components/facebook/grade/table/getScoreName';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {YouVsCompetitorMobile} from 'src/components/facebook/grade/table/YouVsCompetitorMobile';
import {IFacebookGrade} from 'src/types/facebook/FacebookGrade';
import {useMemo} from 'react';
import {GradeUtils} from 'src/utils/GradeUtils';
import {TableRow} from '../../grade/table/TableRow';
import {colors} from 'src/components/theme/common/colors';

export const SummaryTable = ({grade}: {grade: IFacebookGrade}): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const activeAdsBenchmark = useMemo(() => GradeUtils.roundoff(grade.accountSummary.activeAdsBenchmark!, 0).toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 0}), [grade.accountSummary.activeAdsBenchmark]);
  const activeAdSetsBenchmark = useMemo(() => GradeUtils.roundoff(grade.accountSummary.activeAdSetsBenchmark!, 0).toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 0}), [grade.accountSummary.activeAdSetsBenchmark]);

  return (
    <Table>
      <TableHead>
        <TableRowBasic sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
          <TableCell colSpan={2} size='small' align='right'>
            {t('grade.you')}
          </TableCell>
          <TableCell size='small' align='right'>
            {t('grade.competitorAvg')}
          </TableCell>
        </TableRowBasic>
      </TableHead>
      <TableBody>
        <TableRow
          withIcon={false}
          text={t('metrics.adPerformance.benchmark.ads')}
          you={grade.accountSummary.activeAds}
          competitors={activeAdsBenchmark}
          scoreName={getScoreName({
            you: grade.accountSummary.activeAds,
            benchmark: grade.accountSummary.activeAdsBenchmark,
            difference: grade.accountSummary.activeAdsPercentDiff
          })}
        />
        <TableRow
          withIcon={false}
          text={t('metrics.adPerformance.benchmark.adSets')}
          you={grade.accountSummary.activeAdSets}
          competitors={activeAdSetsBenchmark}
          scoreName={getScoreName({
            you: grade.accountSummary.activeAdSets,
            benchmark: grade.accountSummary.activeAdSetsBenchmark,
            difference: grade.accountSummary.activeAdSetsPercentDiff
          })}
        />
      </TableBody>
    </Table>
  );
};

export const SummaryTableMobile = ({grade}: {grade: IFacebookGrade}): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  return (
    <FlexBoxColumn sx={{width: '100%', height: '100%'}}>
      <Divider sx={{width: '100%', borderColor: 'primary.light'}} />
      <table>
        <tbody>
          <YouVsCompetitorMobile title={t('metrics.adPerformance.benchmark.ads')}
            scoreName={getScoreName({
              you: grade.accountSummary.activeAds,
              benchmark: grade.accountSummary.activeAdsBenchmark,
              difference: grade.accountSummary.activeAdsPercentDiff
            })}
            you={grade.accountSummary.activeAds}
            competitors={grade.accountSummary.activeAdsBenchmark} />
        </tbody>
      </table>
      <Divider sx={{width: '100%', borderColor: 'primary.light'}} />
      <table>
        <tbody>
          <YouVsCompetitorMobile title={t('metrics.adPerformance.benchmark.adSets')}
            scoreName={getScoreName({
              you: grade.accountSummary.activeAdSets,
              benchmark: grade.accountSummary.activeAdSetsBenchmark,
              difference: grade.accountSummary.activeAdSetsPercentDiff
            })}
            you={grade.accountSummary.activeAdSets}
            competitors={grade.accountSummary.activeAdSetsBenchmark} />
        </tbody>
      </table>
      <Divider sx={{width: '100%', borderColor: 'primary.light'}} />
    </FlexBoxColumn>
  );
};
