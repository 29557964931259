import {Card, Divider, Typography, Button, useMediaQuery} from '@mui/material';
import {ScoreName} from 'src/enums/ScoreName';
import {FlexBoxColumn} from './FlexBoxColumn';
import {FlexBoxRow} from './FlexBoxRow';
import {ResponsiveRow} from './ResponsiveRow';
import {GradeSize, ScoreBox} from './ScoreBox';
import {Trans, useTranslation} from 'react-i18next';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {FontWeight} from '../theme/overrides/FontWeight';
import {theme} from '../theme/Theme';
import {colors} from '../theme/common/colors';

interface Props {
  isMobile: boolean;
  adType: GoogleAdType | null;
  url?: string;
  scoreName?: ScoreName;
  scoreValue?: string | number;
}

export const ReportLink = ({isMobile, adType, url, scoreName, scoreValue}: Props): JSX.Element => {
  const {t} = useTranslation(['common']);

  return (
    <Card sx={{width: '100%', p: 5, pb: 3}}>
      <ResponsiveRow breakpoint={600} sx={{alignItems: 'flex-start', justifyContent: 'flex-start', gap: isMobile ? 2 : 5}}>
        <FlexBoxRow sx={{gap: 2, justifyContent: 'flex-start'}}>
          <ScoreBox scoreName={scoreName} scoreValue={scoreValue}
            size={useMediaQuery(theme.breakpoints.down('sm')) ? GradeSize.Small : GradeSize.Medium}
          />
          {
            isMobile &&
            <Typography variant='h4' sx={{fontWeight: FontWeight.SemiBold, textTransform: 'capitalize'}}>
              {adType ?? 'Overall'}&nbsp;{'Results'}
            </Typography>
          }
        </FlexBoxRow>
        {isMobile && <Divider sx={{borderBottom: `1px solid ${colors.gray.neutral}`, width: '100%'}} />}
        <FlexBoxColumn sx={{alignItems: 'flex-start', gap: 1}}>
          {
            !isMobile &&
            <Typography variant='h4' sx={{fontWeight: FontWeight.SemiBold, textTransform: 'capitalize'}}>
              {adType ?? 'Overall'}&nbsp;{'Results'}
            </Typography>
          }
          {
            <Typography variant='h5' sx={{fontWeight: FontWeight.SemiBold, textTransform: 'capitalize'}}>
              {
                scoreValue !== '-' && scoreName
              }
            </Typography>
          }
          {
            scoreName && <Typography variant='body1'>
              <Trans t={t} context={scoreValue === '-' ? 'nospend' : `${scoreName.toLowerCase()}`} i18nKey='reportLink' />
            </Typography>
          }
          <Button variant='text' sx={{ml: theme.spacing(-2.5), textTransform: 'capitalize'}}
            disabled={scoreValue === '-'}
            onClick={(() => url && window.location.replace(url))}
          >
            {`See ${adType ?? 'Overall'} Results`}
          </Button>
        </FlexBoxColumn>
      </ResponsiveRow>
    </Card>
  );
};
