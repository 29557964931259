import {styled, Table, TableBody, TableCell, TableRow, Typography, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {theme} from 'src/components/theme/Theme';
import {ScoreName} from 'src/enums/ScoreName';
import {BenchmarkTable, BenchmarkTableMobile} from './BenchmarkTable';
import {useBenchmarkFill} from './useBenchmarkFill';
import {ServiceType} from 'src/enums/ServiceType';
import {colors} from 'src/components/theme/common/colors';
import {FontWeight} from '../../../../theme/overrides/FontWeight';

type Props = {
  rowTitle: string;
  scoreName: ScoreName;
  you?: number;
  competitor?: number;
  color?: string;
}

type InnerBarProps = {
  color: string;
  width: string;
}

const Bar = styled('div')(({theme, color}) => ({
  height: theme.spacing(3),
  background: `linear-gradient(to right, transparent 1%, ${color} 1%, ${color} 100%)`,
  width: '100%',
  borderRadius: theme.spacing(3),
}));

const InnerBar = styled('div')<InnerBarProps>(({theme, color, width}) => ({
  height: '100%',
  width: '100%',
  maxWidth: width,
  background: color,
  borderTopLeftRadius: theme.spacing(3),
  borderBottomLeftRadius: theme.spacing(3),
  borderTopRightRadius: width === '100%' ? theme.spacing(3) : 0,
  borderBottomRightRadius: width === '100%' ? theme.spacing(3) : 0,
}));

export const Benchmark = ({rowTitle, scoreName, you, competitor}: Props): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {youBoxFill, competitorBoxFill} = useBenchmarkFill({you, competitor, isMobile});

  return (
    <FlexBoxColumn sx={{alignSelf: 'stretch', gap: 1}}>
      <BoldTypography variant='body2' sx={{alignSelf: 'stretch'}}>
        {t('grade.campaignGoals.table.benchmark.title')}
      </BoldTypography>
      <FlexBoxColumn sx={{gap: 2, pb: isMobile ? 2 : 4}}>
        <Table sx={{tableLayout: 'fixed', borderCollapse: 'collapse'}}>
          <TableBody>
            {
              isMobile
                ? <>
                  <TableRow>
                    <TableCell sx={{p: 0}}>
                      <Typography variant='body2' align='left'
                        fontWeight={FontWeight.Regular}
                      >{t('grade.campaignGoals.table.benchmark.you')}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{p: 0}}>
                      <Bar color={colors.chart.orangeLight}>
                        <InnerBar color={colors.chart.orange} width={youBoxFill} />
                      </Bar>
                    </TableCell>
                  </TableRow>
                </>
                : <TableRow>
                  <TableCell sx={{p: 0, paddingRight: '16px'}}>
                    <Typography variant='body2' align='right'
                      fontWeight={FontWeight.SemiBold}
                    >{t('grade.campaignGoals.table.benchmark.you')}</Typography>
                  </TableCell>
                  <TableCell colSpan={2} sx={{p: 0}}>
                    <Bar color={colors.chart.orangeLight}>
                      <InnerBar color={colors.chart.orange} width={youBoxFill} />
                    </Bar>
                  </TableCell>
                </TableRow>
            }
          </TableBody>
        </Table>
        <Table sx={{tableLayout: 'fixed', borderCollapse: 'collapse'}}>
          <TableBody>
            {
              isMobile
                ? <>
                  <TableRow>
                    <TableCell sx={{p: 0}}>
                      <Typography variant='body2'
                        align='left'
                        fontWeight={FontWeight.Regular}
                      >{t('grade.competitorAvgFull')}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{p: 0}}>
                      <Bar color={colors.chart.blueLight}>
                        <InnerBar color={colors.chart.blue} width={competitorBoxFill} />
                      </Bar>
                    </TableCell>
                  </TableRow>
                </>
                : <TableRow>
                  <TableCell sx={{p: 0, paddingRight: '16px'}}>
                    <Typography variant='body2'
                      align='right'
                      fontWeight={FontWeight.SemiBold}
                    >{t('grade.competitorAvgFull')}</Typography>
                  </TableCell>
                  <TableCell colSpan={2} sx={{p: 0}}>
                    <Bar color={colors.chart.blueLight}>
                      <InnerBar color={colors.chart.blue} width={competitorBoxFill} />
                    </Bar>
                  </TableCell>
                </TableRow>
            }
          </TableBody>
        </Table>
      </FlexBoxColumn>
      {
        isMobile
          ? <BenchmarkTableMobile rowTitle={rowTitle} scoreName={scoreName} you={you} competitor={competitor} />
          : <BenchmarkTable rowTitle={rowTitle} scoreName={scoreName} you={you} competitor={competitor} />
      }
    </FlexBoxColumn>
  );
};
