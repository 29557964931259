import {useParams} from 'react-router-dom';
import {useOverallGrade} from 'src/hooks/overall/useOverallGrade';
import {useMemo, useState} from 'react';
import {FindingsRow} from './FindingsRow';
import {useTranslation} from 'react-i18next';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {GradeUtils} from 'src/utils/GradeUtils';
import {IDisplayScores, ISearchScores, IShoppingScores} from 'src/types/overall/OverallGrades';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {
  GoogleAdsScoreSection,
  GoogleDisplayScoreSection,
  GoogleShoppingScoreSection
} from 'src/enums/google/ScoreSection';
import {ScoreName} from 'src/enums/ScoreName';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {FontWeight} from 'src/components/theme/overrides/FontWeight';
import {theme} from 'src/components/theme/Theme';

type Props = {
  adType: GoogleAdType;
}

const BackendSectionScoreMappings = {
  [GoogleAdsScoreSection.IMPRESSION_SHARE as string]: 'impression',
  [GoogleAdsScoreSection.QUALITY_SCORE as string]: 'averageQuality',
  [GoogleShoppingScoreSection.ACCOUNT_STRUCTURE as string]: 'accountSize'
};

export const KeyFindings = ({adType}: Props): JSX.Element => {
  const {t} = useTranslation(['common']);
  const {UUID} = useParams();
  const overallGrade = useOverallGrade(UUID);
  const [dataPoints, setDataPoints] = useState<{text: string; score: number | undefined; scoreName: ScoreName}[]>([]);
  const [scoreSectionSpend, setScoreSectionSpend] = useState<number | undefined>(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentScoreSection = adType === GoogleAdType.SEARCH
    ? GoogleAdsScoreSection
    : adType === GoogleAdType.DISPLAY
      ? GoogleDisplayScoreSection
      : GoogleShoppingScoreSection;

  useMemo(() => {
    setScoreSectionSpend(
      adType === GoogleAdType.SEARCH
        ? overallGrade.overallGrade?.searchScores.spend
        : adType === GoogleAdType.DISPLAY
          ? overallGrade.overallGrade?.displayScores?.spend
          : overallGrade.overallGrade?.shoppingScores?.spend
    );
    const dataPoints = Object.values(currentScoreSection).reduce((dataPoints, scoreSectionName) => {
      const scoreItem = `${(BackendSectionScoreMappings[scoreSectionName] as string | undefined) ?? GradeUtils.getSectionKey(scoreSectionName)}Score`;
      const score = adType === GoogleAdType.SEARCH
        ? overallGrade.overallGrade?.searchScores && overallGrade.overallGrade.searchScores[scoreItem as keyof ISearchScores]
        : adType === GoogleAdType.DISPLAY
          ? overallGrade.overallGrade && overallGrade.overallGrade.displayScores && overallGrade.overallGrade.displayScores[scoreItem as keyof IDisplayScores]
          : overallGrade.overallGrade && overallGrade.overallGrade.shoppingScores && overallGrade.overallGrade.shoppingScores[scoreItem as keyof IShoppingScores];
      const scoreName = GradeUtils.getScoreNameFromNumber(score as number);
      dataPoints = [...dataPoints, {text: scoreSectionName, score, scoreName}];
      return dataPoints;
    }, []);
    setDataPoints(dataPoints);
  }, [overallGrade.overallGrade]);

  return (
    <Box sx={{alignSelf: 'flex-start', minHeight: isMobile ? 'fit-content' : 120, pb: 2, width: '100%'}}>
      {
        scoreSectionSpend && scoreSectionSpend > 0
          ? <FlexBoxColumn sx={{gap: 1}}>
            {dataPoints.map((section) => {
              return <FindingsRow text={section.text} score={section.score}
                scoreName={section.scoreName} withIcon={true} key={section.text}
              />;
            })}
          </FlexBoxColumn>
          : <Typography variant='body1' sx={{textAlign: 'center', fontWeight: FontWeight.SemiBold, textTransform: 'capitalize'}}>
            {t('overall.noSpendDetected')}
          </Typography>
      }
    </Box>
  );
};
