import {Button} from '@mui/material';
import {useSignIn} from 'src/hooks/common/useSignIn';
import {useTranslation} from 'react-i18next';
import {ServiceType} from 'src/enums/ServiceType';
import {theme} from '../theme/Theme';
import GoogleGIcon from 'src/assets/platformIcons/GoogleGIcon.svg';

export const GoogleIconSignIn = (): JSX.Element => {
  const {signIn} = useSignIn({signTo: 'google'});
  const {t} = useTranslation([ServiceType.GOOGLE]);

  return (
    <Button
      variant='outlined'
      color={'primary'}
      size={'medium'}
      data-testid='googleButton'
      aria-label='Sign in with Google'
      onClick={signIn}
      startIcon={<img src={GoogleGIcon} />}
      sx={{
        borderRadius: theme.spacing(5),
        padding: `${theme.spacing(1.375)} ${theme.spacing(3)}`,
        width: theme.spacing(26.5)
      }}
    >
      {t('start.signin')}
    </Button>
  );
};
