import {useState} from 'react';
import {GoogleAdsScoreSection} from 'src/enums/google/ScoreSection';
import {FadeInTransition} from '../common/FadeInTransition';
import {FlexBoxColumn} from '../common/FlexBoxColumn';
import {GradeHeader} from '../common/grade/GradeHeader';
import {ChartRenderer} from './grade/charts/ChartRenderer';
import {KeyScore} from '../common/grade/keyScore/KeyScore';
import {KeyMetrics} from '../common/grade/keyMetrics/KeyMetrics';
import {ScoreSummary} from '../common/grade/summaries/ScoreSummary';
import {HowToImprove} from '../common/grade/howTo/HowToImprove';
import {Tabs} from '../common/styled/Tabs';
import {Tab} from '../common/styled/Tab';
import {TabsWrapper} from '../common/styled/TabsWrapper';
import {useMediaQuery} from '@mui/material';
import {theme} from '../theme/Theme';

interface Props {
  isMobile: boolean;
  sectionName: GoogleAdsScoreSection;
}

export const SectionContent = ({sectionName, isMobile}: Props): JSX.Element =>
  <FlexBoxColumn width={'100%'} gap={isMobile ? 3 : 5}>
    <KeyScore
      key={`${sectionName}-about-score`}
      sectionName={sectionName}
    />
    <KeyMetrics
      key={`${sectionName}-key-metrics`}
      sectionName={sectionName}
    />
    <ChartRenderer key={`${sectionName}-chart`} section={sectionName} />
    <ScoreSummary key={`${sectionName}-score-summary`} sectionName={sectionName} />
    <HowToImprove
      key={`${sectionName}-how-to-improve`}
      sectionName={sectionName}
    />
  </FlexBoxColumn>;

export const GoogleGradeTabs = (): JSX.Element => {
  const [selected, setSelected] = useState<GoogleAdsScoreSection>(GoogleAdsScoreSection.WASTED_SPEND);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TabsWrapper>
      <Tabs
        value={selected}
        onChange={(event, newValue) => setSelected(newValue)}
      >
        {
          Object.values(GoogleAdsScoreSection)
            .map((sectionName) =>
              <Tab icon={<GradeHeader sectionName={sectionName} />} value={sectionName} key={sectionName} />
            )
        }
      </Tabs>
      <div role='tab-panel'>
        {
          Object.values(GoogleAdsScoreSection)
            .map((sectionName) =>
              sectionName === selected && <FadeInTransition key={sectionName}>
                <SectionContent sectionName={sectionName} key={sectionName} isMobile={isMobile} />
              </FadeInTransition>
            )
        }
      </div>
    </TabsWrapper>
  );
};
