import {TableCell, TableRow} from '@mui/material';
import {ScoreName} from 'src/enums/ScoreName';
import {GradeUtils} from 'src/utils/GradeUtils';
import {colors} from 'src/components/theme/common/colors';
import {FontWeight} from 'src/components/theme/overrides/FontWeight';

type Props = {
  text: string;
  you: number | string | undefined;
  competitors: number | string | undefined;
  clicks: number | string | undefined;
  scoreName: ScoreName;
  cellsWidth?: string;
}

export const AdFormatTableRow = ({text, you, competitors, clicks, scoreName, cellsWidth}: Props): JSX.Element => {
  const bgColor = GradeUtils.getScoreColorLight(scoreName as ScoreName);

  return (
    <TableRow sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
      <TableCell sx={{width: '40%'}}>
        {text}
      </TableCell>
      <TableCell align='right' sx={{backgroundColor: bgColor, width: cellsWidth, fontWeight: FontWeight.SemiBold}}>
        {you ?? '-'}
      </TableCell>
      <TableCell align='right' sx={{width: cellsWidth}}>{competitors ?? '-'}</TableCell>
      <TableCell align='right' sx={{width: cellsWidth}}>{clicks ?? '-'}</TableCell>
    </TableRow>
  );
};
