import {GoogleDisplayScoreSection} from 'src/enums/google/ScoreSection';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useDisplayGrade} from 'src/hooks/display/useDisplayGrade';
import {Divider, Table, TableBody, TableHead, Typography, useMediaQuery} from '@mui/material';
import {theme} from 'src/components/theme/Theme';
import React from 'react';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {DisplayAdPerformanceMetricsKey, IDisplayAdPlacementMetrics} from 'src/types/display/DisplayGrade';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {DisplayAdPerformanceType} from 'src/enums/display/DisplayAds';
import {AdPerformanceTableRow, TableRowHeader} from './styled/TableRow';
import {MobileTableSection} from './styled/MobileTableSection';
import {colors} from 'src/components/theme/common/colors';

export const AdPerformance = ({sectionName}: {sectionName: GoogleDisplayScoreSection}) : JSX.Element => {
  const {t} = useTranslation([GoogleAdType.DISPLAY]);
  const {UUID} = useParams();
  const {displayGrade} = useDisplayGrade(UUID);
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const performanceTypesWithData = Object.values(DisplayAdPerformanceType)
    .filter((perfType) => displayGrade && Array.isArray(displayGrade.adsPlacementStats[`${perfType.toLowerCase()}PerformingPlacements` as DisplayAdPerformanceMetricsKey])
      && displayGrade.adsPlacementStats[`${perfType.toLowerCase()}PerformingPlacements` as DisplayAdPerformanceMetricsKey].length > 0
    );

  return (
    <FlexBoxColumn sx={{gap: isMobile ? 2 : 3}}>
      <BoldTypography variant='h4' sx={{alignSelf: 'flex-start'}}>{t('grade.displayAdPlacements.performanceRange')}</BoldTypography>
      <FlexBoxColumn sx={{width: '100%', gap: isMobile ? 4 : 5}}>
        {
          performanceTypesWithData.map((perfType) =>
            <FlexBoxColumn key={perfType} sx={{gap: isMobile ? 3 : 4}}>
              <FlexBoxColumn sx={{alignItems: 'flex-start', gap: isMobile ? 1 : 2}}>
                <BoldTypography variant='h5'>
                  {t(`grade.displayAdPlacements.${perfType.toLowerCase()}PerformingPlacements_title`)}
                </BoldTypography>
                <Typography variant='body1'>
                  {t(`grade.displayAdPlacements.${perfType.toLowerCase()}PerformingPlacements_text`)}
                </Typography>
              </FlexBoxColumn>
              {
                displayGrade?.adsPlacementStats[`${perfType.toLowerCase()}PerformingPlacements` as DisplayAdPerformanceMetricsKey].map((data: IDisplayAdPlacementMetrics, index) =>
                  <FlexBoxColumn key={data.name} sx={{width: '100%', mt: index > 0 ? isMobile ? -1 : 1 : 0}}>
                    <BoldTypography variant='h6' sx={{alignSelf: 'flex-start'}}>{data.name}</BoldTypography>
                    { isMobile
                      ? <FlexBoxColumn sx={{width: '100%'}}>
                        <Divider sx={{width: '100%', marginY: 1, borderColor: colors.gray.neutral}} />
                        <Table>
                          <TableBody>
                            <MobileTableSection
                              sectionName={sectionName}
                              impressions={data.impressions}
                              cpc={data.cpc}
                              ctr={data.ctr}
                              spend={data.spend}
                              clicks={data.clicks}
                            />
                          </TableBody>
                        </Table>
                        <Divider sx={{width: '100%', marginY: 1, borderColor: colors.gray.neutral}} />
                      </FlexBoxColumn>
                      : <>
                        <Table>
                          <TableHead>
                            <TableRowHeader />
                          </TableHead>
                          <TableBody>
                            <AdPerformanceTableRow
                              cellsWidth='20%'
                              sectionName={sectionName}
                              impressions={data.impressions}
                              cpc={data.cpc}
                              ctr={data.ctr}
                              spend={data.spend}
                              clicks={data.clicks}
                            />
                          </TableBody>
                        </Table>
                      </>}
                  </FlexBoxColumn>
                )
              }
            </FlexBoxColumn>
          )
        }
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};
