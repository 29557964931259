import {Typography, Link, Divider, useMediaQuery, SxProps} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {PopOverSection} from 'src/components/common/grade/header/PopOverSection';
import {StyledButton} from 'src/components/common/styled/StyledButton';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {useContext} from 'react';
import {ServiceContext} from 'src/components/providers/service';
import {BackgroundContainer} from 'src/components/common/styled/StyledFooterElements';
import {SubFooter} from 'src/components/common/SubFooter';
import {colors} from 'src/components/theme/common/colors';
import {BoldTypography} from '../../styled/BoldTypography';
import {theme} from 'src/components/theme/Theme';
import {FlexBoxRow} from '../../FlexBoxRow';
import {StyledPageContent} from '../../styled/StyledPageElements';
import styled from 'styled-components';

const StyledCtaBox = styled(FlexBoxColumn)`
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(${({theme}) => theme.spacing(1)});
  color: ${({theme}) => theme.palette.common.white};
`;

const ExternalLink = styled(StyledButton)<{target?: string}>`
  text-decoration: none;
  text-align: center;
  ${({theme}) => theme.breakpoints.down(600)} {
    width: 100%;
  }
`;

ExternalLink.defaultProps = {
  target: '_blank'
};

interface IProps {
  sx?: SxProps;
}

export const Footer = ({sx}: IProps): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const {t} = useTranslation([service]);
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <BackgroundContainer sx={sx}>
        <StyledPageContent>
          <ResponsiveRow breakpoint={'sm'}
            sx={{
              px: isMobileOrTablet ? 1 : 0,
              py: isMobileOrTablet ? 3 : 5,
              gap: isMobileOrTablet ? 4 : 2,
              alignItems: 'stretch',
              justifyContent: 'space-between'
            }}
          >
            <FlexBoxColumn gap={1} sx={{alignItems: 'flex-start', justifyContent: 'stretch', color: 'common.white', flex: '1 0 0'}}>
              <Typography variant='h3'>{t('footer.overallGrade', {ns: 'common'})}</Typography>
              <PopOverSection sx={{my: 2, alignSelf: 'flex-start', color: 'common.black'}} pointDown={false} />
              <BoldTypography variant='h4'>{t('footer.haveQuestions', {ns: 'common'})}</BoldTypography>
              <Typography variant='h4'>{t('callUs', {ns: 'common'})}</Typography>
            </FlexBoxColumn>
            <FlexBoxRow sx={{flex: '1 0 0'}}>
              <StyledCtaBox sx={{p: isMobileOrTablet ? 3 : 5, justifyContent: 'stretch', alignItems: 'flex-start'}}>
                <Typography variant='h3' sx={{mb: 1}}>{t('footer.whiteGloveService', {ns: 'common'})}</Typography>
                <Typography variant='body1' sx={{mb: 4}}>{t('footer.serviceDescription', {ns: 'common'})}</Typography>
                <ExternalLink variant='contained' color='secondary' LinkComponent={Link}
                  href={t('grade.footer.startDrivingUrl')}>
                  {t('grade.footer.startDriving')}
                </ExternalLink>
              </StyledCtaBox>
            </FlexBoxRow>
          </ResponsiveRow>
        </StyledPageContent>
      </BackgroundContainer>
      <Divider sx={{width: '100%', borderColor: colors.cream.blocking}} />
      <SubFooter />
    </>
  );
};
