import React, {Component} from 'react';
import {Chart, ChartOptions, ChartDataset} from 'chart.js';
import {getResponsiveChartFont} from 'src/utils/helpers';
import {colors} from 'src/components/theme/common/colors';

export interface IQualityScoreChartProps {
  dataset: Record<'you' | 'industry', number[]>;
  labels: number[];
  xAxisText: string;
  yAxisText: string;
  options?: ChartOptions;
  isMobile?: boolean;
}

const lineStyling: Partial<ChartDataset<'shadowLine'>> = {
  type: 'shadowLine',
  borderWidth: 3.4359,
  backgroundColor: colors.chart.lightGray,
  borderColor: colors.chart.blue,
  pointRadius: 0,
  tension: 0
};

const categoryBarStyling: Partial<ChartDataset<'bar'>> = {
  borderColor: 'rgba(215, 215, 215, 0.25)',
  backgroundColor: colors.chart.orange,
  borderWidth: 1,
  borderRadius: {
    topLeft: 24,
    topRight: 24,
    bottomLeft: 0,
    bottomRight: 0
  },
  maxBarThickness: 24,
  categoryPercentage: 1,
  barPercentage: 0.6
};

export class LineGraph extends Component<IQualityScoreChartProps> {
  canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();

  chart: Chart | undefined;

  options: ChartOptions = {
    ...this.props.options,
    layout: {
      padding: {
        right: 0,
        bottom: 0,
        top: 0,
        left: 0
      }
    },
    font: {
      size: this.props.isMobile ? 12 : 16,
      lineHeight: this.props.isMobile ? '16px' : '24px',
      weight: 'bold',
      family: 'Unify Sans'
    },
    scales: {
      x: {
        title: {
          display: true,
          text: this.props.xAxisText,
          color: '#000',
          font: getResponsiveChartFont
        },
        grid: {
          drawTicks: false,
          drawBorder: true,
          drawOnChartArea: true,
          borderColor: '#737373',
          color: '#737373'
        },
        ticks: {
          align: 'start',
          ...this.props.options
            && this.props.options.scales
            && this.props.options.scales.x
            ? {
              ...this.props.options.scales.x.ticks,
              maxTicksLimit: 12,
              padding: this.props.isMobile ? 8 : 16,
              color: '#000',
              font: {
                size: this.props.isMobile
                  ? 12 : 14,
                lineHeight: '16px',
                family: 'Unify Sans',
                weight: 'normal'
              }
            } : {}
        }
      },
      y: {
        title: {
          display: true,
          text: this.props.yAxisText,
          padding: this.props.isMobile ? 8 : 16,
          color: '#000',
          font: getResponsiveChartFont
        },
        grid: {
          drawTicks: false,
          drawBorder: true,
          drawOnChartArea: true,
          borderColor: '#737373',
          color: '#737373'
        },
        ticks: {
          align: 'start',
          ...this.props.options
            && this.props.options.scales
            && this.props.options.scales.y
            ? {
              ...this.props.options.scales.y.ticks,
              maxTicksLimit: 6,
              padding: this.props.isMobile ? 8 : 16,
              color: '#000',
              font: {
                size: this.props.isMobile
                  ? 12 : 14,
                lineHeight: '16px',
                family: 'Unify Sans',
                weight: 'normal'
              }
            } : {},
          callback: (tickValue) => {
            if (tickValue as number > 1000) {
              return tickValue as number / 1000 + 'k';
            }
            return tickValue;
          }
        },
        afterFit: (scaleInstance) => {
          if (this.chart?.width as number > 600) {
            scaleInstance.width = 120;
          } else {
            scaleInstance.width = 72;
          }
        }
      }
    },
  };

  drawChart (): void {
    const canvasContext = this.canvasRef.current?.getContext('2d') as CanvasRenderingContext2D;

    this.chart = new Chart(canvasContext, {
      type: 'shadowLine',
      data: {
        labels: this.props.labels,
        datasets: [
          {
            data: [...this.props.dataset.industry],
            ...lineStyling
          },
          {
            type: 'bar',
            data: [...this.props.dataset.you],
            ...categoryBarStyling,
            order: 1
          }
        ]
      },
      options: {
        ...this.options
      }
    });
  }

  componentDidUpdate (): void {
    this.chart?.destroy();
    this.drawChart();
  }

  componentDidMount (): void {
    this.chart?.destroy();
    this.drawChart();
  }

  render (): JSX.Element {
    return <div style={{height: this.props.isMobile ? '240px' : '440px', width: '100%'}}>
      <canvas id='chart' ref={this.canvasRef} />
    </div>;
  }
}
