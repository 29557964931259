import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableRow} from '@mui/material';
import {GradeUtils} from 'src/utils/GradeUtils';
import {useTranslation} from 'react-i18next';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {CampaignType, OptimizationLevelType} from 'src/enums/shopping/CampaignRank';
import {ICampaignRank} from 'src/types/shopping/ShoppingGrade';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {colors} from 'src/components/theme/common/colors';

type Props = {
  type: CampaignType.RANKING_WORST | CampaignType.RANKING_BEST;
  campaigns: ICampaignRank[];
  currencySymbol: string;
};

const getBgColor = (level: OptimizationLevelType) => {
  const scoreName = GradeUtils.mapOptimizationLevelToScoreName(level);
  return scoreName ? GradeUtils.getScoreColorLight(scoreName) : '';
};

export const MobileCampaignRankTable = ({type, campaigns, currencySymbol}: Props): JSX.Element => {
  const {t} = useTranslation([GoogleAdType.SHOPPING]);

  return (
    <FlexBoxColumn sx={{alignSelf: 'stretch', gap: 2}}>
      <BoldTypography variant="h6" sx={{alignSelf: 'flex-start'}}>
        {type === CampaignType.RANKING_WORST
          ? t('grade.campaignRankings.table.titles.optimizations')
          : t('grade.campaignRankings.table.titles.topPerformer')}
      </BoldTypography>
      <TableContainer>
        <Table sx={{width: '100%'}}>
          <TableBody>
            {campaigns.map((campaign, index) =>
              <React.Fragment key={index}>
                <TableRow sx={{borderTop: type === CampaignType.RANKING_BEST ? `1px solid ${colors.gray.neutral}` : ''}}>
                  <TableCell colSpan={2} sx={{p: 1, backgroundColor: type === CampaignType.RANKING_BEST ? '' : getBgColor(campaign.optimizationLevel)}}>
                    {type === CampaignType.RANKING_BEST ? '' : `${index + 1}.`} {campaign.campaignName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size='small' sx={{fontWeight: 600}}>
                    {t('grade.campaignRankings.table.colHeaders.spend')}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {currencySymbol}{campaign.spend.toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2})}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size='small' sx={{fontWeight: 600}}>
                    {t('grade.campaignRankings.table.colHeaders.impressionsShare')}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {campaign.impressionShare.toLocaleString(process.env.REACT_APP_LOCALE, {maximumFractionDigits: 0})}%
                  </TableCell>
                </TableRow>
                <TableRow sx={{borderBottom: type === CampaignType.RANKING_BEST ? `1px solid ${colors.gray.neutral}` : ''}}>
                  <TableCell size='small' sx={{fontWeight: 600}}>
                    {t('grade.campaignRankings.table.colHeaders.ctr')}
                  </TableCell>
                  <TableCell size='small' align='right'>
                    {campaign.ctr.toLocaleString(process.env.REACT_APP_LOCALE, {maximumFractionDigits: 2, style: 'percent'})}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </FlexBoxColumn>
  );
};
