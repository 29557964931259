import {Box, Card, Divider, dividerClasses, Typography, useMediaQuery} from '@mui/material';
import {ScoreName} from 'src/enums/ScoreName';
import {TFunction, Trans, useTranslation} from 'react-i18next';
import {GradeUtils} from 'src/utils/GradeUtils';
import {GoogleAdsScoreSection, GoogleScoreSectionType, GoogleShoppingScoreSection} from 'src/enums/google/ScoreSection';
import {theme} from 'src/components/theme/Theme';
import {useContext} from 'react';
import {GoogleAdTypeContext} from 'src/components/providers/adType';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ServiceType} from 'src/enums/ServiceType';
import {GGradeScoreType, useGGradeScore} from 'src/hooks/google/useGGradeScore';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {ResponsiveRow} from '../../ResponsiveRow';
import {ReactComponent as Warning} from 'src/assets/WarningIcon.svg';
import {ReactComponent as DiamondTurnRight} from 'src/assets/DiamondTurnRight.svg';
import {ReactComponent as CircleCheck} from 'src/assets/CircleCheck.svg';
import {useGradeSectionScore} from 'src/hooks/common/useGradeSectionScore';
import {ServiceContext} from 'src/components/providers/service';
import styled from 'styled-components';
import {ShoppingGradeScoreType, useShoppingGradeScore} from 'src/hooks/shopping/useShoppingGradeScrore';

interface Props {
  sectionName: GoogleScoreSectionType;
}

interface TransProps {
  sectionName: GoogleScoreSectionType;
  scoreName: ScoreName;
  t: TFunction;
}

const TransLocal = ({sectionName, scoreName, t}: TransProps): JSX.Element => {
  const {isSearch, isShopping} = useContext(GoogleAdTypeContext);
  const sectionKey = GradeUtils.getSectionKey(sectionName);
  const {searchGrade} = isSearch ? useGGradeScore({sectionName: undefined}) : {} as GGradeScoreType;
  const {shoppingGrade} = isShopping ? useShoppingGradeScore({sectionName: undefined}) : {} as ShoppingGradeScoreType;

  switch (sectionName) {
    case GoogleAdsScoreSection.QUALITY_SCORE:
      return <Trans t={t}
        i18nKey={
          t(
            'grade.summaryContent.' + scoreName.toLowerCase(),
            {
              context: sectionKey,
              weightedQualityScore: searchGrade
                ? searchGrade.qualityScore.duration90.weightedQualityScore
                  .toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 1, maximumFractionDigits: 1})
                : '0',
              pointsToIncrease: searchGrade
                ? searchGrade.qualityScore.duration90.pointsToIncrease
                  .toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 1, maximumFractionDigits: 1})
                : '0',
              savingsSpendLocal: searchGrade
                ? `${searchGrade.currencySymbol}${searchGrade.qualityScore.duration90.savingsSpendLocal
                  .toLocaleString(process.env.REACT_APP_LOCALE, {maximumFractionDigits: 0})}
                  `
                : '$0',
              savingsClicks: searchGrade
                ? searchGrade.qualityScore.duration90.savingsClicks
                  .toLocaleString(process.env.REACT_APP_LOCALE, {maximumFractionDigits: 0})
                : '0'
            }
          )
        }
      />;
    case GoogleAdsScoreSection.IMPRESSION_SHARE:
      return <Trans t={t}
        i18nKey={
          t(
            'grade.summaryContent.' + scoreName.toLowerCase(),
            {
              context: sectionKey,
              budgetLostImpressionShare: searchGrade
                ? (searchGrade.impressionShare.budgetLostImpressionShare / 100)
                  .toLocaleString(process.env.REACT_APP_LOCALE, {maximumFractionDigits: 0, style: 'percent'})
                : '0%',
              rankLostImpressionShare: searchGrade
                ? (searchGrade.impressionShare.rankLostImpressionShare / 100)
                  .toLocaleString(process.env.REACT_APP_LOCALE, {maximumFractionDigits: 0, style: 'percent'})
                : '0%',
              additionalImpressions: searchGrade
                ? searchGrade.impressionShare.additionalImpressions
                  .toLocaleString(process.env.REACT_APP_LOCALE, {maximumFractionDigits: 0})
                : '0',
              additionalClicks: searchGrade
                ? searchGrade.impressionShare.additionalClicks
                  .toLocaleString(process.env.REACT_APP_LOCALE, {maximumFractionDigits: 0})
                : '0'
            }
          )
        }
      />;
    case GoogleShoppingScoreSection.ACCOUNT_STRUCTURE:
      if (shoppingGrade?.accountStructureMetrics.topConversions === 0) {
        return <Trans t={t}
          i18nKey={
            t(
              'grade.summaryContent.' + ScoreName.FOUNDATIONAL.toLowerCase(),
              {context: `${sectionKey}_0`}
            )
          }
        />;
      } else {
        return <Trans t={t}
          i18nKey={
            t(
              'grade.summaryContent.' + scoreName.toLowerCase(),
              {
                context: sectionKey,
                topProducts: shoppingGrade
                  ? shoppingGrade.accountStructureMetrics.topConversions
                    .toLocaleString(process.env.REACT_APP_LOCALE, {
                      maximumFractionDigits: 0
                    })
                  : '0',
                topConversionsPercentage: shoppingGrade
                  ? Number.isFinite(shoppingGrade.accountStructureMetrics.topConversionsValue / shoppingGrade.shoppingPerformanceMetrics.conversionValue)
                    ? (shoppingGrade.accountStructureMetrics.topConversionsValue / shoppingGrade.shoppingPerformanceMetrics.conversionValue)
                      .toLocaleString(process.env.REACT_APP_LOCALE, {maximumFractionDigits: 0, style: 'percent'})
                    : '0%'
                  : '0%',
                topConversionsValue: shoppingGrade
                  ? shoppingGrade.accountStructureMetrics.topConversionsValue
                    .toLocaleString(process.env.REACT_APP_LOCALE, {maximumFractionDigits: 0})
                  : '0',
              }
            )
          }
        />;
      }
    case GoogleShoppingScoreSection.CAMPAIGN_RANKINGS:
      return <Trans t={t}
        i18nKey={
          t(
            'grade.summaryContent.' + scoreName.toLowerCase(),
            {
              context: sectionKey,
              campaigns: shoppingGrade
                ? GradeUtils.sumOfLowAndMediumOptimizationLevels(shoppingGrade.campaignRankings)
                  .toLocaleString(process.env.REACT_APP_LOCALE, {
                    maximumFractionDigits: 0
                  })
                : '0',
            }
          )
        }
      />;
    default:
      return <Trans t={t} i18nKey={t('grade.summaryContent.' + scoreName.toLowerCase(), {context: sectionKey})} />;
  }
};

export const ColoredDivider = styled(Divider)`
  &.${dividerClasses.withChildren}:before,
  &.${dividerClasses.withChildren}:after {
    border-top: ${({theme}) => theme.spacing(0.125)} solid ${({bordercolor}: {bordercolor: string}) => bordercolor};
  }
`;

export const GetIcon = ({scoreName}: {scoreName: ScoreName}): JSX.Element => {
  switch (scoreName) {
    case ScoreName.INTERMEDIATE:
      return <DiamondTurnRight />;
    case ScoreName.ADVANCED:
      return <CircleCheck />;
    default:
      return <Warning />;
  }
};

export const ScoreSummary = ({sectionName}: Props): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const {isDisplay, isShopping} = useContext(GoogleAdTypeContext);
  const {t} = isDisplay ? useTranslation([GoogleAdType.DISPLAY]) : isShopping ? useTranslation([GoogleAdType.SHOPPING]) : useTranslation([ServiceType.GOOGLE]);
  const {scoreName} = useGradeSectionScore({service, sectionName});
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <>
      {
        scoreName
          ? <Card sx={{borderColor: GradeUtils.getScoreColor(scoreName, theme), borderRadius: 0, p: 4, width: '100%'}}>
            <ResponsiveRow breakpoint={'sm'} sx={{alignItems: 'flex-start', gap: 2, justifyContent: 'flex-start'}}>
              {
                isMobile
                  ? <Box sx={{width: '100%'}}>
                    <ColoredDivider bordercolor={GradeUtils.getScoreColor(scoreName, theme)}>
                      {GetIcon({scoreName})}
                    </ColoredDivider>
                  </Box>
                  : <Box>{GetIcon({scoreName})}</Box>
              }
              <FlexBoxColumn sx={{alignItems: 'flex-start', gap: isMobile ? 2 : 1}}>
                <BoldTypography variant={'h5'}>
                  <Trans t={t} i18nKey={t('grade.summaryHeadline.' + scoreName.toLowerCase(), {context: GradeUtils.getSectionKey(sectionName)})} />
                </BoldTypography>
                <Typography variant='body1'>
                  <TransLocal sectionName={sectionName} scoreName={scoreName} t={t} />
                </Typography>
              </FlexBoxColumn>
            </ResponsiveRow>
          </Card>
          : <></>
      }
    </>
  );
};
