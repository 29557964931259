import {GoogleAdType} from 'src/enums/GoogleAdType';
import {useOverallGrade} from 'src/hooks/overall/useOverallGrade';
import {useParams} from 'react-router-dom';
import {GradeUtils} from 'src/utils/GradeUtils';
import {Button, Card, useMediaQuery} from '@mui/material';
import {BlockHeader} from './BlockHeader';
import {BoldTypography} from '../../common/styled/BoldTypography';
import {Trans, useTranslation} from 'react-i18next';
import {theme} from '../../theme/Theme';
import {KeyFindings} from './KeyFindings';

type Props = {
  adType: GoogleAdType;
}

export const ResultBlock = ({adType}: Props): JSX.Element => {
  const {t} = useTranslation(['common']);
  const {UUID} = useParams();
  const overallGrade = useOverallGrade(UUID);
  const notLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const url = `/google-grader/${adType}/${UUID}`;
  const {scoreValue, spend} = adType === GoogleAdType.SHOPPING
    ? {
      scoreValue: overallGrade.overallGrade?.shoppingScores?.overallScore,
      spend: overallGrade.overallGrade?.shoppingScores?.spend ?? 0
    }
    : adType === GoogleAdType.DISPLAY
      ? {
        scoreValue: overallGrade.overallGrade?.displayScores?.overallScore,
        spend: overallGrade.overallGrade?.displayScores?.spend ?? 0
      }
      : {
        scoreValue: overallGrade.overallGrade?.searchScores.overallScore ?? 0,
        spend: overallGrade.overallGrade?.searchScores.spend ?? 0
      };

  return (
    <Card sx={{
      padding: notLargeScreen ? 2 : 3,
      width: notLargeScreen
        ? isMobile ? '100%' : theme.spacing(40)
        : theme.spacing(48),
      cursor: spend ? 'default' : 'not-allowed'
    }}
    >
      <BlockHeader
        adType={adType}
        scoreName={GradeUtils.getScoreNameFromNumber(scoreValue!)}
        scoreValue={spend > 0 ? scoreValue : '-'}
      />
      <BoldTypography variant='h5' sx={{pt: notLargeScreen ? 2 : 3, pb: 2}}>
        {
          spend === 0
            ? <>&nbsp;</>
            : <Trans t={t} i18nKey={'keyAreaFindings'} values={{quantity: adType === GoogleAdType.SEARCH ? '4' : '3'}} />
        }
      </BoldTypography>
      <KeyFindings adType={adType} />
      <Button variant='outlined' color='black' onClick={() => window.location.replace(url)} disabled={spend === 0}>
        {t('reportLink_details')}
      </Button>
    </Card>
  );
};
