import {MenuItem, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useMetricSelect} from 'src/components/common/metrics/useMetricSelect';
import StyledSelect from 'src/components/common/styled/StyledSelect';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {theme} from 'src/components/theme/Theme';

export const MetricSelect = (): JSX.Element => {
  const {value, onSelect} = useMetricSelect();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {t} = useTranslation([GoogleAdType.SHOPPING]);

  return (
    <StyledSelect data-testid="shoppingMetricSelect" value={value} onChange={onSelect} sx={{width: isMobile ? '100%' : '296px'}} size='small'>
      <MenuItem value="overallScore">{t('tracker.overall_score')}</MenuItem>
      <MenuItem value="wastedSpend">{t('tracker.wasted_spend')}</MenuItem>
      <MenuItem value="impressionShare">{t('tracker.impressions')}</MenuItem>
      <MenuItem value="ctr">{t('tracker.ctr')}</MenuItem>
      <MenuItem value="spend">{t('tracker.spend')}</MenuItem>
    </StyledSelect>
  );
};
