import {NavBarContainer} from 'src/components/common/NavBarContainer';
import {GridType} from 'src/enums/GridType';
import {PageContent} from './PageContent';
import {useParams} from 'react-router-dom';
import {useFacebookGrade} from 'src/hooks/facebook/useFacebookGrade';
import {useEffect} from 'react';
import {useFAContext} from 'src/components/providers/facebookAds';
import {IFacebookGrade} from 'src/types/facebook/FacebookGrade';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {useMediaQuery} from '@mui/material';
import {theme} from 'src/components/theme/Theme';
import {FooterContent} from 'src/components/googleAds/page/Page';

type Props = {
  gridType: GridType.TAB | GridType.FORM;
  children: JSX.Element | JSX.Element[];
  additionalFooter?: JSX.Element;
};

type ContentWithBackGroundProps = {
  gridType: GridType.TAB | GridType.FORM;
  elements: JSX.Element | JSX.Element[];
  additionalFooter?: JSX.Element;
  grade: IFacebookGrade | undefined;
  isMobile: boolean;
};

const ContentWithFooter = ({...props}: ContentWithBackGroundProps) => {
  return <>
    <PageContent gridType={props.gridType}>
      {props.elements}
    </PageContent>
    <FlexBoxColumn>
      <FooterContent {...props} />
    </FlexBoxColumn>
  </>;
};

export const FacebookAdsPage = (props: Props): JSX.Element => {
  const {children, additionalFooter, gridType} = props;
  const context = useFAContext();
  const {UUID} = useParams();
  const {grade} = useFacebookGrade(UUID);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (grade) {
      context.setIsNewReport(false);
    }
  }, [grade]);

  return (
    <NavBarContainer>
      <ContentWithFooter
        additionalFooter={additionalFooter}
        elements={children}
        grade={grade}
        gridType={gridType}
        isMobile={isMobile}
      />
    </NavBarContainer>
  );
};
