import {
  BrowserRouter,
  generatePath,
  Navigate,
  NavigateProps,
  Route,
  Routes,
  useParams,
  useSearchParams
} from 'react-router-dom';
import {Theme} from './components/theme/Theme';
import {GoogleAdsHome} from './pages/googleAds/Home';
import {GoogleAdsAccountHome} from './pages/googleAds/AccountHome';
import {FacebookAdsAccountHome} from './pages/facebook/AccountHome';
import {GoogleAdsReport} from './pages/googleAds/Report';
import {SWRProvider} from './components/providers/swr';
import {GoogleAdsError} from './pages/googleAds/Error';
import {KeywordsHome} from './pages/keywords/Home';
import {GradeRefresh} from './pages/googleAds/GradeRefresh';
import {FunctionComponent} from 'react';
import {FacebookAdsHome} from './pages/facebook/Home';
import {withFAProvider} from './components/providers/facebookAds';
import {withGGProvider} from './components/providers/googleAds';
import {FacebookAdsReport} from './pages/facebook/Report';
import {GradeRefresh as FacebookGradeRefresh} from './pages/facebook/GradeRefresh';
import {FacebookAdsError} from './pages/facebook/Error';
import {KeywordsNoRoute} from './pages/keywords/NoRoute';
import {NotFound} from './pages/NotFound';
import {DisplayReport} from './pages/displayAds/Report';
import {ShoppingReport} from './pages/shopping/Report';
import {GoogleAdType} from './enums/GoogleAdType';
import {OverallReport} from './pages/overall/Report';
import {Unsubscribe} from './pages/Unsubscribe';
import {LoadingProvider} from './components/providers/loading';
import {Download} from './pages/keywords/Download';

type Props = {
  children: JSX.Element | JSX.Element[];
}

export const Providers = ({children}: Props): JSX.Element => {
  return (
    <BrowserRouter>
      <Theme>
        <SWRProvider>
          <LoadingProvider>
            {children}
          </LoadingProvider>
        </SWRProvider>
      </Theme>
    </BrowserRouter>
  );
};

const Redirect: FunctionComponent<NavigateProps> = ({to, replace}) => {
  const params = useParams();
  const [queryParams] = useSearchParams();
  const redirectWithParams = generatePath(to as string, params);

  return (
    <Navigate to={{
      pathname: redirectWithParams,
      search: Array.from(queryParams.values()).length > 0
        ? `website=${queryParams.get('website')}`
        : ''
    }} replace={replace}
    />
  );
};

export const App = (): JSX.Element => {

  return (
    <Providers>
      <Routes>
        <Route path='/error' element={<NotFound />} />

        <Route path='/facebook-grader' element={withFAProvider(<FacebookAdsHome />)} />
        <Route path='/facebook-grader/error' element={withFAProvider(<FacebookAdsError />)} />
        <Route path='/facebook-grader/account' element={withFAProvider(<FacebookAdsAccountHome />)} />
        <Route path='/facebook-grader/report/:UUID/unsubscribe' element={withFAProvider(<Unsubscribe />)} />
        <Route path='/facebook-grader/report/:UUID/refresh' element={withFAProvider(<FacebookGradeRefresh />)} />
        <Route path='/facebook-grader/report/:UUID' element={withFAProvider(<FacebookAdsReport />)} />
        <Route path='/facebook-grader/*' element={<Redirect to='/facebook-grader/error?event=noRoute' />} />

        <Route path='/google-grader' element={withGGProvider(<GoogleAdsHome />)} />
        <Route path='/google-grader/error' element={withGGProvider(<GoogleAdsError />)} />
        <Route path='/google-grader/account' element={withGGProvider(<GoogleAdsAccountHome />)} />
        <Route path='/google-grader/report/:UUID/unsubscribe' element={withGGProvider(<Unsubscribe />)} />
        <Route path='/google-grader/report/:UUID/refresh' element={withGGProvider(<GradeRefresh />)} />
        <Route path='/google-grader/report/:UUID' element={withGGProvider(<OverallReport />, GoogleAdType.OVERALL)} />
        <Route path='/google-grader/search/:UUID' element={withGGProvider(<GoogleAdsReport />, GoogleAdType.SEARCH)} />
        <Route path='/google-grader/display/:UUID' element={withGGProvider(<DisplayReport />, GoogleAdType.DISPLAY)} />
        <Route path='/google-grader/shopping/:UUID' element={withGGProvider(<ShoppingReport />, GoogleAdType.SHOPPING)} />
        <Route path='/google-grader/*' element={<Redirect to='/google-grader/error?event=noRoute' />} />

        <Route path='/fkt' element={<KeywordsHome />} />
        <Route path='/fkt/noroute' element={<KeywordsNoRoute />} />
        <Route path='/fkt/download/:UUID' element={<Download />} />
        <Route path='/fkt/*' element={<Redirect to='/fkt/noroute' />} />
        <Route path='/keywords' element={<Redirect to='/fkt' replace={true} />} />
        <Route path='/keywords/download/:UUID' element={<Download />} />
        <Route path='/keywords/*' element={<Redirect to='/fkt/noroute' replace={true} />} />

        <Route path='*' element={<Redirect to='/error' replace={true} />} />
      </Routes>
    </Providers>
  );
};
