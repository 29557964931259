import {FormControlLabelClasses} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';
import {FontWeight} from './FontWeight';
import {pxToRem} from './Typography';
import {colors} from '../common/colors';

type ReturnType = Partial<OverridesStyleRules<keyof FormControlLabelClasses, 'MuiFormControlLabel'>>;

export const MuiFormControlLabelStyleOverrides = (): ReturnType => ({
  label: {
    paddingTop: 4,
    paddingBottom: 4,
    color: colors.common.black,
    alignSelf: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    letterSpacing: '0.25px',
    fontSize: pxToRem(20),
    lineHeight: pxToRem(24),
    fontWeight: FontWeight.Regular
  }
});
