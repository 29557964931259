import {Divider, Typography, useMediaQuery} from '@mui/material';
import {theme} from 'src/components/theme/Theme';
import {IPerformanceMetrics} from 'src/types/overall/OverallGrades';
import {FlexBoxColumn} from '../FlexBoxColumn';
import {FlexBoxRow} from '../FlexBoxRow';
import {ServiceType} from 'src/enums/ServiceType';
import {useTranslation} from 'react-i18next';
import {ResponsiveRow} from '../ResponsiveRow';
import {GradeUtils} from 'src/utils/GradeUtils';
import {ArrowWithPercentage} from './ArrowWithPercentage';
import {BoldTypography} from '../styled/BoldTypography';
import {SpendFlexBoxColumn, SpendWrapperFlexBoxColumn} from './styled/SpendBox';
import {ReportDateRange} from 'src/components/common/metrics/useReportDateRange';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {colors} from 'src/components/theme/common/colors';

type SpendRowProps = {
  spendText: string;
  spend: string;
  currencySymbol: string;
  children: React.ReactNode;
  gap?: number;
} 

export const SpendRow = ({spendText, spend, currencySymbol, gap, children}: SpendRowProps): JSX.Element =>
  <FlexBoxColumn width={'100%'} gap={gap ?? 4.5}>
    <SpendWrapperFlexBoxColumn width={'100%'}>
      <SpendFlexBoxColumn gap={0.5} marginTop={-4.5}>
        <Typography variant='overline'>{spendText}</Typography>
        <Typography variant='h3'>{currencySymbol}{spend}</Typography>
      </SpendFlexBoxColumn>
    </SpendWrapperFlexBoxColumn>
    {children}
  </FlexBoxColumn>;

type Props = (IPerformanceMetrics) & {
  currencySymbol: string;
};

export const PerformanceMetrics = ({metrics, adType}: {metrics: Props; adType: GoogleAdType}): JSX.Element => {
  const {t} = useTranslation([ServiceType.GOOGLE]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {dateRange} = ReportDateRange({adType});
  const isTabletAndMobileScreens = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  // conversionRate was not part of the overallGrade object when this was written
  if (!metrics.conversionRate) {
    metrics.conversionRate = metrics.clicks > 0 ? metrics.conversions / metrics.clicks : 0;
  }

  return (
    <FlexBoxColumn gap={isMobile ? 3 : 4.5} width={'100%'}>
      {/* Header Row Starts */}
      <ResponsiveRow breakpoint={600}
        sx={{alignSelf: 'stretch', justifyContent: 'space-between', marginBottom: isMobile ? 5 : 3.5}}
      >
        <BoldTypography variant='h4' color={'common.black'}>
          {t('metrics.title', {context: adType})}
        </BoldTypography>
        <Typography variant='body2' color={colors.gray.mid}
          sx={{fontStyle: 'italic'}}
        >
          {dateRange}
        </Typography>
      </ResponsiveRow>
      {/* Header Row Starts */}

      {/* 1st Row Starts */}
      {
        <SpendRow
          spendText={t('metrics.searchPerformance.spend')}
          spend={metrics.spend.toLocaleString(process.env.LOCALE, {minimumFractionDigits: 2})}
          currencySymbol={metrics.currencySymbol}
        >
          <ResponsiveRow
            sx={{alignSelf: 'stretch', alignItems: 'stretch', justifyContent: 'space-between', paddingX: isTabletAndMobileScreens ? 0 : 12}}
            gap={isMobile ? 3 : 0}
            breakpoint={600}
          >
            <FlexBoxColumn gap={0.5} width={isMobile ? '100%' : theme.spacing(17.5)}>
              <BoldTypography variant='h3'>
                {metrics.impressions.toLocaleString(process.env.LOCALE)}
              </BoldTypography>
              <BoldTypography variant='overline' textAlign="center">
                {t('metrics.searchPerformance.impressions')}
              </BoldTypography>
            </FlexBoxColumn>
            <ArrowWithPercentage percentage={metrics.ctr} text={t('metrics.searchPerformance.ctr')} />
            <FlexBoxColumn gap={0.5} width={isMobile ? '100%' : theme.spacing(17.5)}>
              <BoldTypography variant='h3'>
                {metrics.clicks.toLocaleString(process.env.LOCALE)}
              </BoldTypography>
              <BoldTypography variant='overline'>
                {t('metrics.searchPerformance.clicks')}
              </BoldTypography>
            </FlexBoxColumn>
            <ArrowWithPercentage percentage={metrics.conversionRate} text={t('metrics.searchPerformance.convRate')} />
            <FlexBoxColumn gap={0.5} width={isMobile ? '100%' : theme.spacing(17.5)}>
              <BoldTypography variant='h3'>
                {metrics.conversions.toLocaleString(process.env.LOCALE)}
              </BoldTypography>
              <BoldTypography variant='overline'>
                {t('metrics.searchPerformance.conversions')}
              </BoldTypography>
            </FlexBoxColumn>
          </ResponsiveRow>
        </SpendRow>
      }
      {/* 1st Row Ends */}

      {/* 3rd Row Starts */}
      <FlexBoxRow width={'100%'} sx={{alignItems: 'stretch', justifyContent: isMobile ? 'space-around' : 'center'}}>
        <FlexBoxColumn gap={0.5} width={isMobile ? '100%' : theme.spacing(17.5)}>
          <BoldTypography variant='h3'>
            {metrics.currencySymbol}{GradeUtils.roundoff(metrics.cpa, 2).toLocaleString(process.env.LOCALE, {minimumFractionDigits: 2})}
          </BoldTypography>
          <BoldTypography variant='overline'>{t('metrics.searchPerformance.cpa')}</BoldTypography>
        </FlexBoxColumn>
        <FlexBoxColumn><Divider sx={{borderBottomWidth: 1, marginX: isMobile ? 0 : 12, alignSelf: 'stretch'}} orientation={'vertical'} /></FlexBoxColumn>
        <FlexBoxColumn gap={0.5} width={isMobile ? '100%' : theme.spacing(17.5)}>
          <BoldTypography variant='h3'>
            {GradeUtils.roundoff(metrics.roas, 2).toLocaleString(process.env.LOCALE, {minimumFractionDigits: 2})}
          </BoldTypography>
          <BoldTypography variant='overline'>{t('metrics.searchPerformance.roas')}</BoldTypography>
        </FlexBoxColumn>
      </FlexBoxRow>
      {/* 3rd Row Ends */}

    </FlexBoxColumn>
  );
};
