import {Card, SxProps, Typography, useMediaQuery} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {theme} from 'src/components/theme/Theme';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {ServiceType} from 'src/enums/ServiceType';
import {ScoreName} from 'src/enums/ScoreName';
import {GradeUtils} from 'src/utils/GradeUtils';

type Props = {
  section: 'awareness' | 'consideration' | 'conversion';
  scoreName: ScoreName;
  result: number;
  percent?: number | string;
}

export const CampaignSectionHeader = ({section, scoreName, result, percent}: Props): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const sxFlexBoxColumn: SxProps = {
    alignItems: 'flex-start',
    borderLeftWidth: isMobile ? theme.spacing(1) : theme.spacing(2),
    borderLeftStyle: 'solid',
    borderColor: GradeUtils.getScoreColor(scoreName),
    padding: isMobile ? theme.spacing(3) : theme.spacing(5),
    gap: isMobile ? theme.spacing(2) : theme.spacing(3)
  };

  percent = typeof percent === 'number' && isNaN(percent) ? 0 : percent;

  return (
    <FlexBoxColumn sx={{alignItems: 'flex-start', gap: isMobile ? 2 : 3}}>
      <BoldTypography variant='h4'>{t(`grade.campaignGoals.table.${section}.overline`)}</BoldTypography>
      <Card sx={{background: theme.palette.background.default, p: 0}}>
        <FlexBoxColumn sx={{...sxFlexBoxColumn}}>
          <Typography variant='h3'>
            {result.toLocaleString()} {t(`grade.campaignGoals.table.${section}.title`)}
          </Typography>
          <ResponsiveRow breakpoint={600} gap={isMobile ? 2 : 3}>
            <Typography variant='body1' sx={{maxWidth: isMobile ? '100%' : '50%'}}>
              <Trans
                t={t}
                i18nKey={t(`grade.campaignGoals.table.${section}.description1`)}
                values={{percent}}
                components={
                  [
                    <Typography key="percentBold" sx={{fontWeight: 600}} component="span" />
                  ]
                }
              /></Typography>
            <Typography variant='body1'>{t(`grade.campaignGoals.table.${section}.description2`)}</Typography>
          </ResponsiveRow>
        </FlexBoxColumn>
      </Card>
    </FlexBoxColumn>
  );
};
