import {Typography, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {MetricsDataProvider} from 'src/components/providers/metricsData';
import {SelectedMetricProvider} from 'src/components/providers/selectedMetrics';
import styled from 'styled-components';
import {DateRange} from '../../../common/metrics/DateRange';
import {DynamicChart} from '../../../common/metrics/DynamicChart';
import {Email} from '../../../common/metrics/Email';
import {MetricSelect} from './MetricSelect';
import {ServiceType} from 'src/enums/ServiceType';
import {useGoogleGrade} from 'src/hooks/google/useGoogleGrade';
import {useParams} from 'react-router-dom';
import {theme} from 'src/components/theme/Theme';

const StyledColumn = styled(FlexBoxColumn)`
    width: 100%;
    align-items: stretch;
`;

const SelectContainer = styled(ResponsiveRow).attrs((props) => ({...props, breakpoint: 600}))`
    width: 100%;
    gap: ${({theme}) => theme.spacing(2)};
    align-items: flex-start;
    justify-content: space-between;
`;

export const PerformanceTracker = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.GOOGLE]);
  const {UUID} = useParams();
  const {grade} = useGoogleGrade(UUID);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SelectedMetricProvider>
      <MetricsDataProvider>
        <StyledColumn sx={{gap: isMobile ? 2 : 3}}>
          <Typography marginRight='auto' variant='h6' fontWeight='medium'>{t('tracker.view')}</Typography>
          <SelectContainer>
            <MetricSelect />
            <DateRange />
          </SelectContainer>
          <DynamicChart currencySymbol={grade?.overallScore.currencySymbol ?? '$'} />
          <Email sx={{mt: 3}} />
        </StyledColumn>
      </MetricsDataProvider>
    </SelectedMetricProvider>
  );
};
