import {TableCell, TableRow} from '@mui/material';
import {colors} from 'src/components/theme/common/colors';
import {ScoreName} from 'src/enums/ScoreName';
import {GradeUtils} from 'src/utils/GradeUtils';

type Props = {
  text: string;
  you: number | string | undefined;
  competitors: number | string | undefined;
  difference: number | undefined;
  scoreName: ScoreName;
  withIcon?: boolean;
}

export const ATTableRow = ({text, you, competitors, scoreName}: Props): JSX.Element => {
  const bgColor = GradeUtils.getScoreColorLight(scoreName);

  return (
    <TableRow sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
      <TableCell>
        {text}
      </TableCell>
      <TableCell sx={{backgroundColor: bgColor}} align='right'>
        {you ?? '-'}
      </TableCell>
      <TableCell align='right'>
        {competitors ?? '-'}
      </TableCell>
    </TableRow>
  );
};
