import {useContext} from 'react';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {useTranslation} from 'react-i18next';
import {
  GoogleAdsScoreSection,
  GoogleDisplayScoreSection,
  GoogleScoreSectionType,
  GoogleShoppingScoreSection
} from 'src/enums/google/ScoreSection';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {Divider, SxProps, useMediaQuery} from '@mui/material';
import {theme} from 'src/components/theme/Theme';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ResponsiveRow} from '../../ResponsiveRow';
import {DisplayKeyMetricInsight} from 'src/components/displayAds/grade/keyMetrics/Insight';
import {SearchKeyMetricInsight} from 'src/components/googleAds/grade/keyMetrics/Insight';
import {ShoppingKeyMetricInsight} from 'src/components/shopping/grade/keyMetrics/Insight';
import {GoogleAdTypeContext} from 'src/components/providers/adType';
import {DisplayKeyMetric} from 'src/components/displayAds/grade/keyMetrics/KeyMetric';
import {SearchKeyMetric} from 'src/components/googleAds/grade/keyMetrics/KeyMetric';
import {ShoppingKeyMetric} from 'src/components/shopping/grade/keyMetrics/KeyMetric';
import {GradeUtils} from 'src/utils/GradeUtils';
import {StyledCard} from 'src/components/common/styled/StyledCard';
import {colors} from 'src/components/theme/common/colors';
import {ScoreName} from 'src/enums/ScoreName';
import {useGradeSectionScore} from 'src/hooks/common/useGradeSectionScore';
import {ServiceContext} from 'src/components/providers/service';

type Props = {
  sectionName: GoogleScoreSectionType;
}

enum ContainerPosition {
  LEFT = 'LEFT',
  RGHT = 'RGHT'
}

type ContainerProps = Props & {
  scoreName: ScoreName;
  isMobile: boolean;
  position: ContainerPosition;
  children: JSX.Element;
}

const Container = (
  {sectionName, scoreName, isMobile, position, children}: ContainerProps
): JSX.Element => {
  const sx: SxProps = {
    alignSelf: 'stretch',
    flex: sectionName === GoogleAdsScoreSection.WASTED_SPEND || sectionName === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS
      ? '1 0 0'
      : 'initial',
    background: sectionName === GoogleAdsScoreSection.WASTED_SPEND || sectionName === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS
      ? theme.palette.common.white
      : position === ContainerPosition.LEFT
        ? scoreName !== ScoreName.ADVANCED
          ? theme.palette.background.default
          : colors.score.greenTint
        : theme.palette.common.white,
    padding: isMobile
      ? theme.spacing(3)
      : sectionName === GoogleAdsScoreSection.WASTED_SPEND || sectionName === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS
        ? theme.spacing(4)
        : theme.spacing(5)
  };
  return <FlexBoxColumn sx={{...sx}}>{children}</FlexBoxColumn>;
};

export const KeyMetrics = ({sectionName}: Props ) : JSX.Element => {
  const [service] = useContext(ServiceContext);
  const {isDisplay, isShopping} = useContext(GoogleAdTypeContext);
  const sectionKey = GradeUtils.getSectionKey(sectionName);
  const {t} = useTranslation([GoogleAdType.DISPLAY]);
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const {scoreName} = useGradeSectionScore({service, sectionName});

  return (
    <FlexBoxColumn sx={{alignItems: isMobile ? 'flex-start' : 'stretch', gap: isMobile ? 2 : 3, alignSelf: 'stretch'}}>
      {/* <!-- Heading --> */}
      <BoldTypography variant={'h4'}>{t('grade.keyMetric')}</BoldTypography>
      {/* <!-- Content --> */}
      <StyledCard key={`${sectionName}-score-description`} sx={{p: 0, alignSelf: 'stretch'}}>
        <ResponsiveRow breakpoint={600}
          sx={{height: '100%', justifyContent: 'stretch', alignItems: 'stretch', alignSelf: 'stretch'}}
        >
          <Container sectionName={sectionName} isMobile={isMobile} position={ContainerPosition.LEFT} scoreName={scoreName!}>
            {
              isDisplay
                ? <DisplayKeyMetric
                  sectionName={sectionName as GoogleDisplayScoreSection}
                  sectionKey={sectionKey}
                />
                : isShopping
                  ? <ShoppingKeyMetric
                    sectionName={sectionName as GoogleShoppingScoreSection}
                    sectionKey={sectionKey}
                  />
                  : <SearchKeyMetric
                    sectionName={sectionName as GoogleAdsScoreSection}
                    sectionKey={sectionKey}
                  />
            }
          </Container>
          <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem={true} sx={{borderColor: colors.gray.dark}} />
          <Container sectionName={sectionName} isMobile={isMobile} position={ContainerPosition.RGHT} scoreName={scoreName!}>
            {
              isDisplay
                ? <DisplayKeyMetricInsight sectionKey={sectionKey} />
                : isShopping
                  ? <ShoppingKeyMetricInsight sectionKey={sectionKey} />
                  : <SearchKeyMetricInsight sectionKey={sectionKey} />
            }
          </Container>
        </ResponsiveRow>
      </StyledCard>
    </FlexBoxColumn>
  );
};
