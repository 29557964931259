import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {Typography, Theme, Box, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ServiceType} from 'src/enums/ServiceType';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {useGGradeScore} from 'src/hooks/google/useGGradeScore';
import {GoogleAdsScoreSection} from 'src/enums/google/ScoreSection';
import {ScoreName} from 'src/enums/ScoreName';
import {GradeUtils} from 'src/utils/GradeUtils';
import {ReactComponent as AlertIcon} from 'src/assets/AlertIconCurrent.svg';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {theme} from 'src/components/theme/Theme';
import styled from 'styled-components';

interface Props {
  sectionKey: string;
}

interface IconProps {
  scorename: ScoreName | undefined;
  theme: Theme;
}

const StyledAlertIcon = styled(AlertIcon)`
  fill: ${({scorename, theme}: IconProps) => scorename ? GradeUtils.getScoreColor(scorename, theme) : 'none'};
`;

export const SearchKeyMetricInsight = ({sectionKey}: Props):JSX.Element => {
  const {t} = useTranslation([ServiceType.GOOGLE]);
  const {scoreName, searchGrade} = useGGradeScore({sectionName: GoogleAdsScoreSection.WASTED_SPEND});
  const isTabletScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    sectionKey !== 'wastedSpend'
      ? <FlexBoxColumn sx={{alignItems: 'flex-start'}} gap={1}>
        <Typography fontWeight={'fontWeightMedium'} variant='h5'>
          {t('grade.keyMetricTextTitle', {context: sectionKey})}
        </Typography>
        <Typography variant='body1'>
          {t('grade.keyMetricText', {context: sectionKey})}
        </Typography>
      </FlexBoxColumn>
      : <FlexBoxColumn gap={2}>
        <FlexBoxColumn gap={0.5}>
          <Box sx={{minHeight: isTabletScreen ? '50px' : 'initial'}}>
            <BoldTypography textAlign={'center'} variant='h4'>
              {t('grade.keyInsightScoreTitle', {context: sectionKey})}
            </BoldTypography>
          </Box>
          <Typography variant='overline'
            textTransform={'uppercase'} sx={{fontWeight: 'fontWeightMedium'}}>
            {t('grade.keyInsightText', {context: 'last90Days'})}
          </Typography>
        </FlexBoxColumn>
        <FlexBoxRow gap={2}>
          <StyledAlertIcon scorename={scoreName} />
          {
            searchGrade && <Typography variant='h3' whiteSpace={'pre'}>
              {
                (searchGrade.negativeKeyword.wasteLocal / 4)
                  .toLocaleString(
                    process.env.REACT_APP_LOCALE,
                    {minimumFractionDigits: 2, style: 'currency', currency: searchGrade.currencyCode}
                  )
              }
            </Typography>
          }
        </FlexBoxRow>
      </FlexBoxColumn>
  );
};
