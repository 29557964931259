import {PaletteOptions} from '@mui/material';
import {colors} from '../common/colors';

export const Palette: PaletteOptions = {
  primary: {
    main: colors.marine.primary,
    light: colors.marine.tint,
    dark: colors.marine.interactive,
    contrastText: colors.common.white
  },
  secondary: {
    main: colors.accent.primary,
    dark: colors.accent.primary,
    light: colors.accent.tint,
    contrastText: colors.common.white
  },
  info: {
    main: colors.blue.lightBackground,
    light: colors.blue.lightBackground,
    dark: colors.blue.darkBackground
  },
  success: {
    main: colors.green.lightBackground,
    light: colors.green.lightBackground,
    dark: colors.green.darkBackground
  },
  warning: {
    main: colors.orange.lightBackground,
    light: colors.orange.lightBackground,
    dark: colors.orange.darkBackground
  },
  error: {
    main: colors.red.lightBackground,
    light: colors.red.lightBackground,
    dark: colors.red.darkBackground
  },
  text: {
    primary: '#000000',
    // secondary: colors.common.white,
    disabled: colors.gray.mid
  },
  background: {
    default: colors.cream.background,
    paper: colors.common.white,
    gray: colors.gray.light
  },
  common: {
    white: colors.common.white,
    black: colors.common.black
  },
  black: {
    main: '#000000',
    light: '#000000',
    dark: '#000000C2',
    contrastText: '#fff'
  },
  white: {
    main: '#FFFFFF',
    light: '#FFFFFF',
    dark: '#FFFFFFC2',
    contrastText: ''
  },
  destructive: {
    main: '#C20F1E',
    light: '#C20F1E',
    dark: '#930B17',
    contrastText: ''
  },
  // TODO: Remove this
  brand: {
    blue: '#F2FAFF',
    greenAccent: '#4B772C', // Not using #50902D due to low color contrast with white text -> 3.91
    greenTint: '#DAF0CD',
    navyAccent: '#688595',
    unselectedTab: 'rgba(0, 0, 0, 0.25)',
    unselectedTabAccent: 'rgba(0, 0, 0, 0.35)',
    unselectedTabText: '#EAEBED'
  },
  // TODO: Remove this
  facebook: {
    main: '#1877F2',
    background: '#3C4861',
    navyAccent: '#688595',
    lightNavy: '#EBF4FF',
    navyCore: '#03334E',
    highlightedRow: 'rgba(215, 215, 215, 0.25)',
    phoneBlue: '#0165D1',
    tabletBlue: '#01448C',
    desktopBlue: '#002247',
    faintGrey: '#F9F9F9'
  },
  // TODO: Remove this
  graph: {
    you: '#CB6905',
    industry: '#D0A700'
  }
};
