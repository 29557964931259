import {Tabs as MuiTabs, TabsProps} from '@mui/material';
import {theme} from 'src/components/theme/Theme';
import styled from 'styled-components';

const Props: TabsProps = {
  key: `tabs-${Math.random()}`,
  variant: 'fullWidth',
  TabIndicatorProps: {
    sx: {
      height: theme.spacing(1),
      backgroundColor: 'primary.main'
    }
  }
};

const Tabs= styled(MuiTabs)`
  border-bottom: ${({theme}) => theme.spacing(0.125)} solid ${({theme}) => theme.palette.common.black};
`;

Tabs.defaultProps = Props;

export {Tabs};
