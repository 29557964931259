import {styled} from '@mui/material/styles';
import {FlexBoxColumn} from '../FlexBoxColumn';
import {FlexBoxRow} from '../FlexBoxRow';
import {colors} from 'src/components/theme/common/colors';

export const BackgroundContainer = styled(FlexBoxColumn)`
  background: linear-gradient(
    101deg,
    ${colors.common.black} 0%,
    #04009D 50%,
    ${({theme}) => theme.palette.primary.main} 100%
  );
  overflow: hidden;
`;

export const SubFooterBox = styled(FlexBoxRow)`
  background-color: ${({theme}) => theme.palette.common.black};
  color: ${colors.cream.blocking};
`;

export const GraderFooterGradient = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    101deg,
    ${colors.common.black} 0%,
    #04009D 50%,
    ${({theme}) => theme.palette.primary.main} 100%
  );
`;

export const KeywordsBackgroundContainer = styled(FlexBoxColumn)`
  background: linear-gradient(
    86deg,
    ${({theme}) => theme.palette.primary.main} 0%,
    #04009D 33%,
    ${colors.common.black} 100%
  );
  ${({theme}) => theme.breakpoints.down('sm')} {
    background: linear-gradient(
      211deg,
      ${({theme}) => theme.palette.primary.main} 0%,
      #04009D 50%,
      ${colors.common.black} 100%
    );
  }
  overflow: hidden; 
`;
