import {Typography, useMediaQuery} from '@mui/material';
import {FlexBoxColumn} from '../../common/FlexBoxColumn';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useGoogleGrade} from 'src/hooks/google/useGoogleGrade';
import {ServiceType} from 'src/enums/ServiceType';
import {PerformanceMetrics} from 'src/components/common/metrics/PerformanceMetrics';
import {theme} from 'src/components/theme/Theme';
import {GoogleAdType} from 'src/enums/GoogleAdType';

export const SearchMetrics = (): JSX.Element => {
  const {UUID} = useParams();
  const {grade} = useGoogleGrade(UUID as string);
  const {t} = useTranslation([ServiceType.GOOGLE]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <FlexBoxColumn gap={isMobile ? 3 : 4.5} sx={{width: '100%'}}>
    {
      grade && grade.searchPerformanceMetrics &&
        <PerformanceMetrics
          metrics={{
            ...grade.searchPerformanceMetrics,
            currencySymbol: grade.currencySymbol
          }}
          adType={GoogleAdType.SEARCH}
        />
    }
    {/*  Footer Starts */}
    <Typography variant='caption' sx={{color: 'text.disabled'}} textAlign={isMobile ? 'center' : 'left'}>
      {t('metrics.performanceFooter', {context: 'search'})}
    </Typography>
    {/*  Footer Ends */}
  </FlexBoxColumn>;
};
