import {useEffect, useState} from 'react';
import {setInterval, clearInterval} from 'timers';
import {Tips as tips} from './tips-data';

type ReturnType = {
  tip: {
    message: string;
    background: string;
  };
}

export const useTips = (): ReturnType => {
  const [tip, setTip] = useState(tips[0]);
    
  useEffect(() => {
    let tipShown = tip;

    const interval = setInterval(() => {
      const index = tips.indexOf(tipShown);
      const nextTip = tips.length > index ? tips[index + 1] : null;

      if (nextTip) {
        setTip(nextTip);
        tipShown = nextTip;
      } else {
        setTip(tips[0]);
        tipShown = tips[0];
      }
        
    }, 5000);
      
    return () => clearInterval(interval);
  }, []);

  return {tip};
};
