import {TableCell, TableCellProps, TableRow, Typography} from '@mui/material';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {SimpleTooltip} from './Tooltip';
import {FontWeight} from 'src/components/theme/overrides/FontWeight';

type Props = {
  text: string;
  value: string | number;
  caption?: string;
  highlightColor?: string;
  isBold?: boolean;
  withTooltip?: boolean;
  rowColor?: string;
}

export const TableRowMobile = ({text, value, caption, highlightColor, isBold, withTooltip, rowColor}: Props): JSX.Element => {
  const props: TableCellProps = caption ? {padding: 'none'} : {size: 'small'};

  return <>
    {
      caption && <TableRow sx={{display: 'flex', mb: -2}}>
        <TableCell {...props} colSpan={2}>
          <BoldTypography
            sx={{
              borderBottom: withTooltip ? '2px dotted #000' : 0,
              cursor: withTooltip ? 'pointer' : 'none'
            }}
            color="common.black" variant="caption"
          >
            {text}
          </BoldTypography>
        </TableCell>
      </TableRow>
    }
    <TableRow sx={{background: rowColor}}>
      {
        !caption && <TableCell {...props}>
          {
            withTooltip
              ? <SimpleTooltip>
                <BoldTypography
                  sx={{
                    borderBottom: '2px dotted #000',
                    cursor: 'pointer'
                  }}
                  color="common.black" variant="caption"
                >
                  {text}
                </BoldTypography>
              </SimpleTooltip>
              : <BoldTypography color="common.black" variant="caption">
                {text}
              </BoldTypography>
          }
        </TableCell>
      }
      {
        caption && <TableCell {...props}>
          {
            caption && <Typography
              color="common.black" variant="caption">
              {caption}
            </Typography>
          }
        </TableCell>
      }
      <TableCell {...props} align='right'
        sx={{
          backgroundColor: highlightColor,
          fontWeight: isBold ? FontWeight.SemiBold : FontWeight.Regular,
        }}
      >
        {value}
      </TableCell>
    </TableRow>
  </>;
};
