import {TableCell, TableHead, Typography, TableRow} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {HelperTooltip} from '../../../common/grade/Tooltip';
import {colors} from 'src/components/theme/common/colors';

export const TableHeader = (): JSX.Element => {
  const {t} = useTranslation([GoogleAdType.DISPLAY]);

  return <TableHead>
    <TableRow sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
      <TableCell>
        <FlexBoxRow sx={{gap: 1, justifyContent: 'flex-start'}}>
          <FlagOutlinedIcon />
          {t('grade.audiencePerformance.breakdown')}
        </FlexBoxRow>
      </TableCell>
      <TableCell>
        <FlexBoxColumn sx={{alignItems: 'flex-end', whiteSpace: 'pre', color: 'white'}}>
          <HelperTooltip
            color='black'
            placement='top'
            title={t('grade.audiencePerformance.activeAudiencesToolTip')}
          >
            <Typography component={'span'} variant='h6' fontWeight={'fontWeightBold'}>
              {t('grade.audiencePerformance.activeAudiences')}
            </Typography>
          </HelperTooltip>
        </FlexBoxColumn>
      </TableCell>
      <TableCell>
        <FlexBoxColumn sx={{alignItems: 'flex-end'}}>
          {t('grade.audiencePerformance.impressions')}
        </FlexBoxColumn>
      </TableCell>
      <TableCell>
        <FlexBoxColumn sx={{alignItems: 'flex-end'}}>
          {t('grade.audiencePerformance.conversions')}
        </FlexBoxColumn>
      </TableCell>
      <TableCell>
        <FlexBoxColumn sx={{alignItems: 'flex-end'}}>
          {t('grade.audiencePerformance.cpc')}
        </FlexBoxColumn>
      </TableCell>
    </TableRow>
  </TableHead>;
};
