import {Table, TableBody} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {FacebookAdsScoreSection} from 'src/enums/facebook/ScoreSection';
import {useFAGradeScore} from 'src/hooks/facebook/useFAGradeScore';
import {GradeUtils} from 'src/utils/GradeUtils';
import {capitalizeFirstLetter} from 'src/utils/helpers';
import {APTableCellMobile} from '../table/audiencePerformance/APTableCellMobile';
import {APTableRow} from '../table/audiencePerformance/APTableRow';
import {CTRResultRow} from '../table/audiencePerformance/CTRResultRow';
import {SummaryMobile, SummaryRow} from '../table/audiencePerformance/SummaryRow';
import {THead} from '../table/audiencePerformance/THead';
import {TopDemographics, TopDemographicsMobile} from '../table/audiencePerformance/TopDemographics';
import {ServiceType} from 'src/enums/ServiceType';
import {colors} from 'src/components/theme/common/colors';

export const AudiencePerformanceResults = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const {grade, currencySymbol} = useFAGradeScore({sectionName: FacebookAdsScoreSection.AUDIENCE_PERFORMANCE});

  return (
    <FlexBoxColumn gap={6} sx={{alignItems: 'flex-start', width: '100%'}}>
      <FlexBoxColumn gap={4} sx={{alignItems: 'flex-start', width: '100%'}}>
        <BoldTypography variant="h5">{t('grade.audiencePerformance.table.topCTR')}</BoldTypography>
        <CTRResultRow
          caption={t('grade.audiencePerformance.table.topCTRAverage')}
          score={grade?.audiencePerformance.totalsBestPerforming.ctr}
          color={colors.score.greenTint}
          text={t('grade.audiencePerformance.table.topCTRText')}
        />
        <Table sx={{tableLayout: 'fixed'}}>
          <THead />
          <TableBody>
            {grade && grade.audiencePerformance.bestPerforming.map((stats) => {
              return <APTableRow key={`${stats.gender}${stats.ageGroup}`} {...stats} color={colors.score.greenTint} currencySymbol={currencySymbol} />;
            })}
            {grade && <SummaryRow {...grade.audiencePerformance.totalsBestPerforming} currencySymbol={currencySymbol} />}
          </TableBody>
        </Table>
      </FlexBoxColumn>
      <FlexBoxColumn gap={4} sx={{alignItems: 'flex-start', width: '100%'}}>
        <BoldTypography variant="h5">{t('grade.audiencePerformance.table.bottomCTR')}</BoldTypography>
        <CTRResultRow
          caption={t('grade.audiencePerformance.table.bottomCTRAverage')}
          score={grade?.audiencePerformance.totalsWorstPerforming.ctr}
          color={colors.score.redTint}
          text={t('grade.audiencePerformance.table.bottomCTRText')}
        />
        <Table sx={{tableLayout: 'fixed'}}>
          <THead />
          <TableBody>
            {grade && grade.audiencePerformance.worstPerforming.map((stats) => {
              return <APTableRow key={`${stats.gender}${stats.ageGroup}`} {...stats} color={colors.score.redTint} currencySymbol={currencySymbol} />;
            })}
            {grade && <SummaryRow {...grade.audiencePerformance.totalsWorstPerforming} currencySymbol={currencySymbol} />}
          </TableBody>
        </Table>
      </FlexBoxColumn>
      <FlexBoxColumn gap={3} sx={{alignItems: 'flex-start', width: '100%'}}>
        <BoldTypography variant="h5">{t('grade.audiencePerformance.table.topAudience')}</BoldTypography>
        <TopDemographics />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export const AudiencePerformanceResultsMobile = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const {grade, currencySymbol} = useFAGradeScore({});

  return (
    <FlexBoxColumn gap={4} sx={{width: '100%'}}>
      <BoldTypography textAlign="center" variant="h4">{t('grade.audiencePerformance.table.topCTR')}</BoldTypography>
      <CTRResultRow caption={t('grade.audiencePerformance.table.topCTRAverage')} score={grade?.audiencePerformance.totalsBestPerforming.ctr} color={colors.score.greenTint} text={t('grade.audiencePerformance.table.topCTRText')} />
      <FlexBoxColumn sx={{alignSelf: 'stretch'}}>
        {grade && grade.audiencePerformance.bestPerforming.map((data) => {
          return <APTableCellMobile color={colors.score.greenTint} key={`${data.gender}${data.ageGroup}`} currencySymbol={currencySymbol} title={`${capitalizeFirstLetter(data.gender)} ${GradeUtils.clearAgeGroup(data.ageGroup)}`} {...data} />;
        })}
        {grade && <SummaryMobile currencySymbol={currencySymbol} {...grade.audiencePerformance.totalsBestPerforming} />}
      </FlexBoxColumn>
      <BoldTypography textAlign="center" variant="h4">{t('grade.audiencePerformance.table.bottomCTR')}</BoldTypography>
      <CTRResultRow caption={t('grade.audiencePerformance.table.bottomCTRAverage')} score={grade?.audiencePerformance.totalsWorstPerforming.ctr} color={colors.score.redTint} text={t('grade.audiencePerformance.table.bottomCTRText')} />
      <FlexBoxColumn sx={{alignSelf: 'stretch'}}>
        {grade && grade.audiencePerformance.worstPerforming.map((data) => {
          return <APTableCellMobile color={colors.score.redTint} key={`${data.gender}${data.ageGroup}`} currencySymbol={currencySymbol} title={`${capitalizeFirstLetter(data.gender)} ${GradeUtils.clearAgeGroup(data.ageGroup)}`} {...data} />;
        })}
        {grade && <SummaryMobile currencySymbol={currencySymbol} {...grade.audiencePerformance.totalsWorstPerforming} />}
      </FlexBoxColumn>
      <FlexBoxColumn sx={{alignSelf: 'stretch', gap: 2}}>
        <BoldTypography textAlign="left" variant="h4" sx={{alignSelf: 'flex-start'}}>{t('grade.audiencePerformance.table.topAudience')}</BoldTypography>
        <TopDemographicsMobile />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};
