import {Table, TableBody, TableCell, TableRow, TableHead, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AdditionalMetrics} from 'src/enums/display/AudiencePerformance';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {theme} from 'src/components/theme/Theme';
import {colors} from 'src/components/theme/common/colors';

type Props = {
  metrics: Record<AdditionalMetrics, number>;
}

export const AdditionalMetric = ({metrics}: Props): JSX.Element => {
  const {t} = useTranslation([GoogleAdType.DISPLAY]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      { isMobile
        ? <Table sx={{tableLayout: 'fixed'}}>
          <TableBody>
            <TableRow>
              <TableCell sx={{padding: 1}}>
                {t('grade.audiencePerformance.summary')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size='small' sx={{fontWeight: 600}}>
                {t('grade.audiencePerformance.additionalMetrics.spend')}
              </TableCell>
              <TableCell align='right' sx={{padding: 1}}>
                {metrics.spend.toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2, style: 'currency', currency: 'USD'})}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size='small' sx={{fontWeight: 600}}>
                {t('grade.audiencePerformance.additionalMetrics.clicks')}
              </TableCell>
              <TableCell align='right' sx={{padding: 1}}>
                {metrics.clicks.toLocaleString(process.env.REACT_APP_LOCALE)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        : <Table>
          <TableHead>
            <TableRow sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
              <TableCell />
              <TableCell size='small' align='right'>
                {t('grade.audiencePerformance.additionalMetrics.spend')}
              </TableCell>
              <TableCell size='small' align='right'>
                {t('grade.audiencePerformance.additionalMetrics.clicks')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
              <TableCell align='left' sx={{width: '60%'}}>
                {t('grade.audiencePerformance.summary')}
              </TableCell>
              <TableCell align='right' sx={{width: '20%'}}>
                {metrics.spend.toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2, style: 'currency', currency: 'USD'})}
              </TableCell>
              <TableCell align='right' sx={{width: '20%'}}>
                {metrics.clicks.toLocaleString(process.env.REACT_APP_LOCALE)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>}
    </>
  );
};
