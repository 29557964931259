import {SvgIconTypeMap, Theme} from '@mui/material';
import {ScoreName, ScoreRangeMax} from 'src/enums/ScoreName';
import {IGoogleGrade} from 'src/types/google/GoogleGrade';
import {
  GoogleAdsScoreSection,
  GoogleDisplayScoreSection,
  GoogleShoppingScoreSection,
  ScoreSectionType
} from 'src/enums/google/ScoreSection';
import {FacebookAdsScoreSection} from 'src/enums/facebook/ScoreSection';
import {IFacebookGrade} from 'src/types/facebook/FacebookGrade';
import {IGrade} from 'src/types/common/Grade';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {ServiceType} from 'src/enums/ServiceType';
import {IDisplayGrade} from '../types/display/DisplayGrade';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {GoogleAdsMetricsTabs, MetricTabTypes} from 'src/enums/MetricTabType';
import {colors} from 'src/components/theme/common/colors';

import WarningIcon from '@mui/icons-material/Warning';
import DirectionsIcon from '@mui/icons-material/Directions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AdvancedIcon from 'src/assets/Advanced.svg';
import FoundationIcon from 'src/assets/Foundation.svg';
import IntermediateIcon from 'src/assets/Intermediate.svg';

import AdvancedIconColored from 'src/assets/AdvancedColored.svg';
import FoundationIconColored from 'src/assets/FoundationColored.svg';
import IntermediateIconColored from 'src/assets/IntermediateColored.svg';
import {ICampaignRank, ICampaignRankings, IShoppingGrade} from '../types/shopping/ShoppingGrade';
import {CampaignType, OptimizationLevelType} from '../enums/shopping/CampaignRank';

type HowToKeyProps = {
  sectionName: FacebookAdsScoreSection;
  scoreName: ScoreName;
  grade?: IFacebookGrade;
}

type DisplayHowToKeyProps = {
  grade: IDisplayGrade | undefined;
  sectionName: GoogleDisplayScoreSection;
}

export class GradeUtils {
  // TODO: theme params for below function is used by old compoennts, remove this once re-branding is done
  // eslint-disable-next-line
  static getScoreColor = (name: ScoreName | undefined, theme?: Theme): string => {
    switch (name) {
      case ScoreName.FOUNDATIONAL:
        return colors.score.red;
      case ScoreName.INTERMEDIATE:
        return colors.score.yellow;
      case ScoreName.ADVANCED:
        return colors.score.green;
      default:
        return colors.score.yellow;
    }
  };

  static getScoreColorLight = (name: ScoreName): string => {
    switch (name) {
      case ScoreName.FOUNDATIONAL:
        return colors.score.redTint;
      case ScoreName.INTERMEDIATE:
        return colors.score.yellowTint;
      case ScoreName.ADVANCED:
        return colors.score.greenTint;
      default:
        return colors.score.yellowTint;
    }
  };

  static getIcon = (name: ScoreName): string => {
    switch (name) {
      case ScoreName.FOUNDATIONAL:
        return FoundationIcon;
      case ScoreName.INTERMEDIATE:
        return IntermediateIcon;
      case ScoreName.ADVANCED:
        return AdvancedIcon;
      default:
        return IntermediateIcon;
    }
  };

  static getIconColored = (name: ScoreName): string => {
    switch (name) {
      case ScoreName.FOUNDATIONAL:
        return FoundationIconColored;
      case ScoreName.INTERMEDIATE:
        return IntermediateIconColored;
      case ScoreName.ADVANCED:
        return AdvancedIconColored;
      default:
        return IntermediateIconColored;
    }
  };

  static getTipIcon = (scoreName: ScoreName): OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
    muiName: string;
  } => {
    switch (scoreName) {
      case ScoreName.FOUNDATIONAL:
        return WarningIcon;
      case ScoreName.INTERMEDIATE:
        return DirectionsIcon;
      case ScoreName.ADVANCED:
        return CheckCircleIcon;
      default:
        return DirectionsIcon;
    }
  };

  // ex; 'Quality Score' -> 'qualityScore'
  static getSectionKey = (section: string): string => {
    return section.split(' ')
      .map((key, index) => index > 0
        ? key.charAt(0).toUpperCase() + key.slice(1)
        : key.toLowerCase()
      )
      .join('');
  };

  static getScoreValue = (
    sectionName: ScoreSectionType,
    grade: IGrade,
    service: ServiceType,
    adType?: GoogleAdType,
  ): number | string => {
    switch (service) {
      case ServiceType.GOOGLE:
        return adType === GoogleAdType.DISPLAY
          ? this.getGoogleDisplaySectionScoreValue(sectionName as GoogleDisplayScoreSection, grade as IDisplayGrade)
          : adType === GoogleAdType.SHOPPING
            ? this.getGoogleShoppingSectionScoreValue(sectionName as GoogleShoppingScoreSection, grade as IShoppingGrade)
            : this.getGoogleAdsSectionScoreValue(sectionName as GoogleAdsScoreSection, grade as IGoogleGrade);
      case ServiceType.FACEBOOK:
        return this.getFacebookAdsSectionScoreValue(sectionName as FacebookAdsScoreSection, grade as IFacebookGrade);
    }
    return '-';
  };

  static getScoreName = (
    grade: IGrade | IDisplayGrade | IShoppingGrade,
    service: ServiceType,
    sectionName?: ScoreSectionType,
    adType?: GoogleAdType,
  ): ScoreName => {
    const score = sectionName
      ? GradeUtils.getScoreValue(sectionName, grade, service, adType)
      : GradeUtils.roundoff(grade.overallScore.overallScore);
    return this.getScoreNameFromNumber(+score > -1 ? score as number : -1);
  }

  static getScoreNameFromNumber = (score: number): ScoreName => {
    switch (true) {
      case score > 0 && ScoreRangeMax.FOUNDATIONAL >= score:
        return ScoreName.FOUNDATIONAL;
      case score > ScoreRangeMax.FOUNDATIONAL && ScoreRangeMax.INTERMEDIATE >= score:
        return ScoreName.INTERMEDIATE;
      case score > ScoreRangeMax.INTERMEDIATE:
        return ScoreName.ADVANCED;
      default:
        return ScoreName.FOUNDATIONAL;
    }
  }

  static roundoff = (num: number, decimalPlaces?: number, asPercent?: boolean): number => {
    num = asPercent ? num * 100 : num;
    if (decimalPlaces) {
      const multiplicationFactor = Math.pow(10, decimalPlaces);
      return Math.round((num + Number.EPSILON) * multiplicationFactor) / multiplicationFactor;
    }
    return Math.round(num);
  }

  static clearAgeGroup = (ageGroup: string | undefined): string => {
    if (!ageGroup) return '';

    if (ageGroup.includes('_to_')) {
      return ageGroup.replace('_to_', ' - ');
    }

    if (ageGroup.includes('_plus')) {
      return ageGroup.replace('_plus', '+');
    }

    return ageGroup;
  };

  static getImprovementTipKey = ({grade, sectionName, scoreName}: HowToKeyProps): ScoreName => {
    if (!grade) return ScoreName.FOUNDATIONAL;

    if (sectionName !== FacebookAdsScoreSection.CAMPAIGN_GOALS) return scoreName;
    const {awareness, consideration, conversion} = grade.campaignGoals;

    const hasNoGoals = !this.isRunnningCampaigns(awareness.campaignCount) && !this.isRunnningCampaigns(consideration.campaignCount) && !this.isRunnningCampaigns(conversion.campaignCount);
    if (hasNoGoals) return ScoreName.FOUNDATIONAL;

    const hasAllGoals = this.isRunnningCampaigns(awareness.campaignCount) && this.isRunnningCampaigns(consideration.campaignCount) && this.isRunnningCampaigns(conversion.campaignCount);
    if (hasAllGoals) return ScoreName.ADVANCED;

    // Reaching this point means that the campaign is missing one of the three types - awareness/consideration/conversion
    return ScoreName.INTERMEDIATE;
  }

  static getHowToKey = ({grade, sectionName, scoreName}: HowToKeyProps): ScoreName => {
    if (!grade) return ScoreName.FOUNDATIONAL;

    if (sectionName !== FacebookAdsScoreSection.CAMPAIGN_GOALS) return scoreName;
    const {awareness, consideration, conversion} = grade.campaignGoals;

    const hasNoGoals = !this.isRunnningCampaigns(awareness.campaignCount) && !this.isRunnningCampaigns(consideration.campaignCount) && !this.isRunnningCampaigns(conversion.campaignCount);
    if (hasNoGoals) return ScoreName.FOUNDATIONAL;

    const hasAllGoals = this.isRunnningCampaigns(awareness.campaignCount) && this.isRunnningCampaigns(consideration.campaignCount) && this.isRunnningCampaigns(conversion.campaignCount);
    if (hasAllGoals) return ScoreName.ADVANCED;

    return ScoreName.INTERMEDIATE;
  };

  static getDisplayHowToContent = ({grade, sectionName}: DisplayHowToKeyProps): string[] | [] => {
    if (grade && sectionName === GoogleDisplayScoreSection.STATUS) {
      const hasResponsive = !!grade.adTypesStats.responsiveAdsMetrics && grade.adTypesStats.responsiveAdsMetrics.adsCount > 0;
      const hasAnimated = !!grade.adTypesStats.animatedAdsMetrics && grade.adTypesStats.animatedAdsMetrics.adsCount > 0;

      if (hasResponsive && hasAnimated) {
        return ['animatedAndResponsive'];
      } else if (!hasResponsive && !hasAnimated) {
        return ['animated', 'responsive', 'addOn1', 'addOn2'];
      } else if (hasAnimated && !hasResponsive) {
        return ['animated', 'addOn1', 'addOn2'];
      } else if (hasResponsive && !hasAnimated) {
        return ['responsive', 'addOn1', 'addOn2'];
      }
    } else if (grade && sectionName === GoogleDisplayScoreSection.PLACEMENT) {
      return [this.getScoreName(grade, ServiceType.GOOGLE, sectionName, GoogleAdType.DISPLAY).toLowerCase()];
    } else if (grade && sectionName === GoogleDisplayScoreSection.AUDIENCE_PERFORMANCE) {
      const hasRemarketing = !!grade.displayAudienceMetrics.remarketingSpend;
      const hasAffinity = !!grade.displayAudienceMetrics.affinitySpend;
      const hasIntent = !!grade.displayAudienceMetrics.intentSpend;
      let result = [];

      if (!hasRemarketing) {
        result.push('noRemarketing');
      }
      if (!hasAffinity) {
        result.push('noAffinity');
      }
      if (!hasIntent) {
        result.push('noIntent');
      }
      if (result.length === 0) {
        result = ['hasAllRemarketing'];
      }

      return result;
    }

    return [];
  };

  static filterHowToSuggestions = ({suggestions, grade, sectionName, scoreName}: {suggestions: string[]} & HowToKeyProps): string[] => {
    if (!grade || sectionName !== FacebookAdsScoreSection.CAMPAIGN_GOALS || scoreName !== ScoreName.INTERMEDIATE) return suggestions;

    const {awareness, consideration, conversion} = grade.campaignGoals;

    return suggestions.filter((suggestion) => {
      if (suggestion.includes('Awareness')) {
        return !this.isRunnningCampaigns(awareness.campaignCount);
      }

      if (suggestion.includes('Consideration')) {
        return !this.isRunnningCampaigns(consideration.campaignCount);
      }

      if (suggestion.includes('Conversion')) {
        return !this.isRunnningCampaigns(conversion.campaignCount);
      }
      return false;
    });
  }

  //Transforms BRAND_AWARENESS to Brand Awareness
  static transformToPascal = (text: string): string => {
    return text.split('_').map((word) => {
      const lowerCaseWord = word.toLowerCase();
      return lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
    }).join(' ');
  }

  static getCurrencySymbol = (currency: string | undefined | null): string => {
    switch (currency) {
      //TODO - Other currencies
      case 'USD':
      case '':
      case undefined:
      case null: {
        return '$';
      }
    }

    return currency;
  }

  // This will split only on the first occurrence of the delimeter
  static formatSectionName = (sectionName: GoogleAdsScoreSection | GoogleDisplayScoreSection | GoogleShoppingScoreSection | FacebookAdsScoreSection | MetricTabTypes, delim = ' '): string => {
    const [first, ...rest] = sectionName.split(delim);
    const remaining = rest.join(delim);
    return sectionName === GoogleAdsMetricsTabs.SIZE_OF_ACCOUNT
      ? `${first} ${rest[0]}\n${rest[1]}`
      :`${first}\n${remaining}`;
  }

  static sumOfLowAndMediumOptimizationLevels = (campaignRankings: ICampaignRankings): number => {
    return campaignRankings.campaigns
      .filter((campaign: ICampaignRank) =>
        campaign.optimizationLevel === OptimizationLevelType.LOW ||
        campaign.optimizationLevel === OptimizationLevelType.MEDIUM).length;
  }

  static getWorstShoppingCampaigns = (campaigns: ICampaignRank[]): ICampaignRank[] => {
    return campaigns
      .filter(campaign =>
        campaign.optimizationLevel === 'LOW' ||
        campaign.optimizationLevel === 'MEDIUM'
      )
      .sort((a, b) => b.campaignRank - a.campaignRank);
  };

  static getBestShoppingCampaign = (campaigns: ICampaignRank[]): ICampaignRank[] => {
    return campaigns.filter(campaign => campaign.campaignType === CampaignType.RANKING_BEST);
  };

  static mapOptimizationLevelToScoreName = (level: OptimizationLevelType): ScoreName | undefined => {
    return level === OptimizationLevelType.LOW
      ? ScoreName.FOUNDATIONAL
      : level === OptimizationLevelType.MEDIUM
        ? ScoreName.INTERMEDIATE
        : undefined;
  }

  private static getGoogleAdsSectionScoreValue = (sectionName: GoogleAdsScoreSection, grade: IGoogleGrade): number => {
    switch (sectionName) {
      case GoogleAdsScoreSection.QUALITY_SCORE:
        return GradeUtils.roundoff(grade.qualityScore.duration30.relativeRanking);
      case GoogleAdsScoreSection.ACCOUNT_ACTIVITY:
        return GradeUtils.roundoff(grade.accountActivity.duration30.activityScore);
      case GoogleAdsScoreSection.IMPRESSION_SHARE:
        return GradeUtils.roundoff(grade.impressionShare.relativeRanking);
      case GoogleAdsScoreSection.WASTED_SPEND:
        return GradeUtils.roundoff(grade.negativeKeyword.relativeRanking);
    }
  }

  private static getGoogleDisplaySectionScoreValue = (sectionName: GoogleDisplayScoreSection, grade: IDisplayGrade): number => {
    switch (sectionName) {
      case GoogleDisplayScoreSection.STATUS:
        return GradeUtils.roundoff(grade.adTypesStats.score);
      case GoogleDisplayScoreSection.PLACEMENT:
        return GradeUtils.roundoff(grade.adsPlacementStats.score);
      case GoogleDisplayScoreSection.AUDIENCE_PERFORMANCE:
        return GradeUtils.roundoff(grade.displayAudienceMetrics.score);
    }
    return 0;
  }

  private static getGoogleShoppingSectionScoreValue = (sectionName: GoogleShoppingScoreSection, grade: IShoppingGrade): number => {
    switch (sectionName) {
      case GoogleShoppingScoreSection.ACCOUNT_STRUCTURE:
        return GradeUtils.roundoff(grade.accountStructureMetrics.score);
      case GoogleShoppingScoreSection.CAMPAIGN_RANKINGS:
        return GradeUtils.roundoff(grade.campaignRankings.score);
      case GoogleShoppingScoreSection.WASTED_SPEND:
        return GradeUtils.roundoff(grade.negativeKeywords.relativeRanking);
    }
    return 0;
  }

  private static getFacebookAdsSectionScoreValue = (sectionName: FacebookAdsScoreSection, grade: IFacebookGrade): number => {
    switch (sectionName) {
      case FacebookAdsScoreSection.CAMPAIGN_GOALS:
        return GradeUtils.roundoff(grade.campaignGoals.score);
      case FacebookAdsScoreSection.ACCOUNT_ACTIVITY:
        return GradeUtils.roundoff(grade.accountActivity.score);
      case FacebookAdsScoreSection.AUDIENCE_PERFORMANCE:
        return GradeUtils.roundoff(grade.audiencePerformance.score);
      case FacebookAdsScoreSection.AUDIENCE_TYPES:
        return GradeUtils.roundoff(grade.audienceTypes.score);
    }
  }

  private static isRunnningCampaigns = (campaignCount: number | undefined) => campaignCount && campaignCount > 0
}
