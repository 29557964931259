import {SxProps, Typography, useMediaQuery} from '@mui/material';
import {useContext} from 'react';
import {TFunction, Trans, useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {GoogleAdTypeContext} from 'src/components/providers/adType';
import {theme} from 'src/components/theme/Theme';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ServiceType} from 'src/enums/ServiceType';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {ArrowWithPercentage} from './ArrowWithPercentage';
import {SpendWrapperFlexBoxColumn} from './styled/SpendBox';
import {useOverallGradeScore} from 'src/hooks/overall/userOverallGradeScore';

type AbcProps = {
  children: JSX.Element | JSX.Element[];
  sx?: SxProps;
}

const NestedSpendWrapperFlexBoxColumn = ({children, sx}: AbcProps) => <FlexBoxColumn sx={sx}>
  <SpendWrapperFlexBoxColumn sx={{height: theme.spacing(5), width: '100%'}} />
  {children}
</FlexBoxColumn>;

export interface ShoppingSizeOfAccountProps extends SizeOfAccountProps {
  activeCampaigns: number;
  activeProductGroups: number;
  averageProductGroupsPerAdGroup: number;
  activeProducts: number;
}

export interface DisplaySizeOfAccountProps extends SizeOfAccountProps {
  activeAds: number;
  activeDisplayCampaigns: number;
  activeAudiences: number;
  averageAdsPerAdGroup: number;
}

export interface SearchSizeOfAccountProps extends SizeOfAccountProps {
  activeAds: number;
  activeSearchCampaigns: number;
  activeKeywords: number;
  activeResponsiveSearchAds: number;
  averageActiveKeywordsPerAdGroup: number;
  responsiveSearchAdsPercentage: number;
}

interface SizeOfAccountProps {
  activeAdGroups: number;
  averageAdGroupsPerCampaign: number;
}

type RowWrapperProps = {
  sx?: SxProps;
  children: JSX.Element | JSX.Element[];
}

const RowWrapper = ({sx, children}: RowWrapperProps) => <ResponsiveRow
  sx={{
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    ...sx
  }}
  breakpoint={600}
>
  {children}
</ResponsiveRow>;

type RowProps = {
  isTabletAndMobileScreens: boolean;
  isMobile: boolean;
  t: TFunction;
  activeCampaigns: number;
  activeAdGroups: number;
  activeKeywords: number | undefined;
  activeAds: number | undefined;
  activeShoppingProductGroups: number | undefined;
  activeShoppingProducts: number | undefined;
};

const FirstRow = ({...props}: RowProps): JSX.Element =>
  <RowWrapper sx={{paddingX: props.isTabletAndMobileScreens ? 0 : 12, gap: props.isMobile ? 3 : 0, mt: props.isMobile ? -3 : -2}}>
    <FlexBoxColumn sx={{textAlign: 'center', gap: 0.5}}>
      <Typography variant='h3'>{props.activeCampaigns}</Typography>
      <Typography variant='overline'>{props.t('metrics.campaigns')}</Typography>
    </FlexBoxColumn>
    <ArrowWithPercentage />
    <FlexBoxColumn sx={{textAlign: 'center', gap: 0.5}}>
      <Typography variant='h3'>{props.activeAdGroups}</Typography>
      <Typography variant='overline'>{props.t('metrics.adGroups')}</Typography>
    </FlexBoxColumn>
    {
      props.activeKeywords !== undefined
        ? <>
          <ArrowWithPercentage />
          <FlexBoxColumn sx={{textAlign: 'center', gap: 0.5}}>
            <Typography variant='h3'>{props.activeKeywords}</Typography>
            <Typography variant='overline'>{props.t('metrics.keywords')}</Typography>
          </FlexBoxColumn>
        </>
        : <></>
    }
    {
      props.activeShoppingProductGroups !== undefined
        ? <>
          <ArrowWithPercentage />
          <FlexBoxColumn sx={{textAlign: 'center', gap: 0.5}}>
            <Typography variant='h3'>{props.activeShoppingProductGroups}</Typography>
            <Typography variant='overline'>{props.t('metrics.activeProductGroups')}</Typography>
          </FlexBoxColumn>
        </>
        : <></>
    }
    {
      props.activeShoppingProducts !== undefined
        ? <>
          <ArrowWithPercentage />
          <FlexBoxColumn sx={{textAlign: 'center', gap: 0.5}}>
            <Typography variant='h3'>{props.activeShoppingProducts}</Typography>
            <Typography variant='overline'>{props.t('metrics.activeProducts')}</Typography>
          </FlexBoxColumn>
        </>
        : <></>
    }
    {
      props.activeAds !== undefined
        ? <>
          <ArrowWithPercentage />
          <FlexBoxColumn sx={{textAlign: 'center'}}>
            <Typography variant='h3'>{props.activeAds}</Typography>
            <Typography variant='overline'>{props.t('metrics.ads')}</Typography>
          </FlexBoxColumn>
        </>
        :<></>
    }
  </RowWrapper>;

export const SizeOfAccount = ({...props}: SearchSizeOfAccountProps | DisplaySizeOfAccountProps | ShoppingSizeOfAccountProps): JSX.Element => {
  const {googleAdType, isDisplay, isShopping, isSearch} = useContext(GoogleAdTypeContext);
  const nameSpace = isDisplay ? GoogleAdType.DISPLAY : isShopping ? GoogleAdType.SHOPPING : ServiceType.GOOGLE;
  const {t} = useTranslation([nameSpace]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {formattedDate} = useOverallGradeScore();
  const isTabletAndMobileScreens = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  return <FlexBoxColumn width={'100%'} gap={isMobile ? 2: 3}>
    <BoldTypography variant='h4' color={'common.black'}
      sx={{alignSelf: isMobile ? 'center' : 'flex-start'}}
    >
      {t('metrics.activeCampaigns')}
    </BoldTypography>
    {/* Section Starts */}
    <FlexBoxColumn sx={{width: '100%', gap: isMobile ? 1 : 3}}>
      <BoldTypography variant='h5' sx={{alignSelf: isMobile ? 'center' : 'flex-start'}}>
        {t('metrics.structures')}
      </BoldTypography>
      <NestedSpendWrapperFlexBoxColumn sx={{alignSelf: 'stretch'}}>
        {/* 1st Row Starts */}
        <FirstRow {...{
          isMobile, isTabletAndMobileScreens, googleAdType, t,
          activeCampaigns: isDisplay
            ? (props as DisplaySizeOfAccountProps).activeDisplayCampaigns
            : isShopping
              ? (props as ShoppingSizeOfAccountProps).activeCampaigns
              : (props as SearchSizeOfAccountProps).activeSearchCampaigns,
          activeAdGroups: props.activeAdGroups,
          activeAds: isShopping
            ? undefined
            : isDisplay
              ? (props as DisplaySizeOfAccountProps).activeAds
              : (props as SearchSizeOfAccountProps).activeAds,
          activeKeywords: isSearch
            ? (props as SearchSizeOfAccountProps).activeKeywords
            : undefined,
          activeShoppingProductGroups: isShopping
            ? (props as ShoppingSizeOfAccountProps).activeProductGroups
            : undefined,
          activeShoppingProducts: isShopping
            ? (props as ShoppingSizeOfAccountProps).activeProducts
            : undefined
        }} />
        {/* 1st Row Ends */}
        {/* 2nd Row Starts */}
        <RowWrapper sx={{mt: isMobile ? 3 : 6, paddingX: isMobile ? 4.5 : isTabletAndMobileScreens ? 6 : 12, gap: isMobile ? 3 : 4}}>
          {/* 2nd Row First Part Starts */}
          <FlexBoxColumn sx={{
            gap: isMobile ? 1 : 3,
            width: isMobile ? '100%' : 'initial',
            flex: googleAdType === GoogleAdType.SEARCH && (props as SearchSizeOfAccountProps).activeResponsiveSearchAds > 0 && !isMobile
              ? '0.60 0 0'
              : '1 0 0'
          }}>
            <BoldTypography variant='h6'>{t('metrics.average')}</BoldTypography>
            <NestedSpendWrapperFlexBoxColumn sx={{alignSelf: 'stretch', width: isMobile ? '100%' : 'initial'}}>
              <RowWrapper sx={{mt: -2, gap: isMobile ? 3 : 0, justifyContent: 'space-around'}}>
                <FlexBoxColumn sx={{textAlign: 'center', gap: 0.5}}>
                  <Typography variant='overline' sx={{whiteSpace: isMobile ? 'nowrap' : 'pre'}}>
                    {t('metrics.adGroupsPerCampaign')}
                  </Typography>
                  <Typography variant='h3'>
                    {props.averageAdGroupsPerCampaign.toLocaleString(process.env.LOCALE, {maximumFractionDigits: 1})}
                  </Typography>
                </FlexBoxColumn>
                {
                  isDisplay
                    ? <RowWrapper>
                      <FlexBoxColumn sx={{textAlign: 'center', gap: 0.5}}>
                        <Typography variant='overline' sx={{whiteSpace: isMobile ? 'nowrap' : 'pre'}}>
                          {t('metrics.adsPerAdGroup')}
                        </Typography>
                        <Typography variant='h3'>
                          {(props as DisplaySizeOfAccountProps).averageAdsPerAdGroup.toLocaleString(process.env.LOCALE, {maximumFractionDigits: 1})}
                        </Typography>
                      </FlexBoxColumn>
                    </RowWrapper>
                    : isShopping
                      ? <RowWrapper>
                        <FlexBoxColumn sx={{textAlign: 'center', gap: 0.5}}>
                          <Typography variant='overline' sx={{whiteSpace: isMobile ? 'nowrap' : 'pre'}}>
                            {t('metrics.productGroupsPerAdGroup')}
                          </Typography>
                          <Typography variant='h3'>
                            {(props as ShoppingSizeOfAccountProps).averageProductGroupsPerAdGroup.toLocaleString(process.env.LOCALE, {maximumFractionDigits: 1})}
                          </Typography>
                        </FlexBoxColumn>
                      </RowWrapper>
                      : <FlexBoxColumn sx={{textAlign: 'center', gap: 0.5}}>
                        <Typography variant='overline' sx={{whiteSpace: isMobile ? 'nowrap' : 'pre'}}>
                          {t('metrics.keywordsPerAdGroup')}
                        </Typography>
                        <Typography variant='h3'>
                          {(props as SearchSizeOfAccountProps).averageActiveKeywordsPerAdGroup.toLocaleString(process.env.LOCALE, {maximumFractionDigits: 1})}
                        </Typography>
                      </FlexBoxColumn>
                }
              </RowWrapper>
            </NestedSpendWrapperFlexBoxColumn>
          </FlexBoxColumn>
          {/* 2nd Row First Part Ends */}
          {/* 2nd Row Second Part Starts, this is only for Search Ads */}
          {
            googleAdType === GoogleAdType.SEARCH && (props as SearchSizeOfAccountProps).activeResponsiveSearchAds > 0
              ? <FlexBoxColumn sx={{gap: isMobile ? 1 : 3, flex: isMobile ? '1 0 0' : '0.30 0 0', width: isMobile ? '100%' : 'initial'}}>
                <BoldTypography variant='h6'>{t('metrics.adType')}</BoldTypography>
                <NestedSpendWrapperFlexBoxColumn sx={{alignSelf: 'stretch'}}>
                  <RowWrapper sx={{gap: isMobile ? 2 : 0, mt: -2, justifyContent: 'space-around'}}>
                    <FlexBoxColumn sx={{textAlign: 'center', gap: 0.5}}>
                      <Typography variant='overline' sx={{whiteSpace: isMobile ? 'nowrap' : 'pre'}}>
                        {t('metrics.rsa')}
                      </Typography>
                      <Typography variant='h3'>
                        {
                          ((props as SearchSizeOfAccountProps).responsiveSearchAdsPercentage / 100)
                            .toLocaleString(process.env.LOCALE, {maximumFractionDigits: 0, style: 'percent'})
                        }
                      </Typography>
                    </FlexBoxColumn>
                  </RowWrapper>
                </NestedSpendWrapperFlexBoxColumn>
              </FlexBoxColumn>
              : <></>
          }
          {/* 2nd Row Second Part Ends */}
        </RowWrapper>
      </NestedSpendWrapperFlexBoxColumn>
    </FlexBoxColumn>
    {/* 2nd Row Ends */}
    {/* Section Ends */}

    {/*  Footer Starts */}
    <Typography variant='caption' sx={{color: 'text.disabled'}} textAlign={isMobile ? 'center' : 'left'}>
      <Trans t={t} i18nKey={'metrics.sizeOfAccountFooter'} values={{reportDate: formattedDate}} />
    </Typography>
    {/*  Footer Ends */}
  </FlexBoxColumn>;
};
