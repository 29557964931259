import React from 'react';
import {theme} from '../../theme/Theme';
import {ISpendDataKey} from './SpendBreakdown';
import {ISpend} from '../../../types/overall/OverallGrades';
import {useTranslation} from 'react-i18next';
import {ServiceType} from '../../../enums/ServiceType';
import {Table, TableBody, TableCell, TableRow, useMediaQuery} from '@mui/material';
import {ResponsiveRow} from '../../common/ResponsiveRow';
import {FontWeight} from 'src/components/theme/overrides/FontWeight';
import {colors} from 'src/components/theme/common/colors';

type ISpendProps = {
  item: ISpendDataKey;
  keys: ISpendDataKey[];
  spendData: ISpend;
  currencySymbol: string;
  isMobile: boolean;
}

export const SpendCell = ({item, keys, spendData, currencySymbol, isMobile}: ISpendProps): JSX.Element => {
  const {t} = useTranslation([ServiceType.GOOGLE]);
  return (
    <Table key={item.key}>
      <TableBody>
        <TableRow sx={{borderBottom: isMobile ? undefined : keys.length > 1 ? `1px solid ${colors.gray.neutral}` : undefined}}>
          <TableCell size={isMobile ? 'small' : 'medium'} sx={{fontWeight: isMobile ? FontWeight.SemiBold : FontWeight.Regular}}>
            {t(`metrics.overallSpend.${item.key}`)}
          </TableCell>
          <TableCell align='right' sx={{fontWeight: FontWeight.SemiBold}} size={isMobile ? 'small' : 'medium'}>
            {currencySymbol}{spendData[item.key].toLocaleString(process.env.LOCALE, {minimumFractionDigits: 2})}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const EmptySpendCell = (): JSX.Element => {
  return (
    <Table>
      <tbody>
        <TableRow>
          <TableCell>
            &nbsp;
          </TableCell>
        </TableRow>
      </tbody>
    </Table>
  );
};

interface IProps {
  keys: ISpendDataKey[];
  spendData: ISpend;
  currencySymbol: string;
}

export const OtherSpendRow = ({keys, spendData, currencySymbol}: IProps): JSX.Element => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <ResponsiveRow
      sx={{alignSelf: 'stretch', alignItems: 'stretch'}}
      gap={isMobile ? 1 :6}
      breakpoint={600}
      key={`responsive-row-${keys.map(key => key.key).join('-')}`}
    >
      {keys.map((item: ISpendDataKey, index: number) =>
        <React.Fragment key={`fragment-${index}`}>
          {keys.length === 1 && !isMobile && <EmptySpendCell key={`key_1-${index}`} />}
          <SpendCell key={`spend-cell-${item.key}-${index}`} item={item} keys={keys}
            spendData={spendData} currencySymbol={currencySymbol} isMobile={isMobile} />
          {keys.length < 3 && !isMobile && <EmptySpendCell key={`key_2-${index}`} />}
        </React.Fragment>
      )}
    </ResponsiveRow>
  );
};
