import {
  accordionSummaryClasses, createTheme, inputBaseClasses,
  selectClasses, SimplePaletteColorOptions, tableCellClasses
} from '@mui/material';
import {ThemeProvider} from 'styled-components';

import {colors} from './common/colors';
import {Palette} from './overrides/Palette';

import {MuiButtonStyleOverrides} from './overrides/Button';
import {MuiCardStyleOverrides} from './overrides/Card';
import {MuiFormLabelStyleOverrides} from './overrides/FormLabel';
import {MuiOutlinedInputStyleOverrides} from './overrides/OutlinedInput';
import {pxToRem, Typography} from './overrides/Typography';
import {FontWeight} from './overrides/FontWeight';
import {MuiFormControlLabelStyleOverrides} from './overrides/FormControlLabel';

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 905,
    lg: 1240,
    xl: 1440,
  }
};

const BlackReBrandPaletteColor: SimplePaletteColorOptions = {
  main: '#000000',
  light: '#000000',
  dark: '#000000C2',
  contrastText: '#FFFFFF'
};

const WhiteReBrandPaletteColor: SimplePaletteColorOptions = {
  main: '#FFFFFF',
  light: '#FFFFFF',
  dark: '#FFFFFFC2',
  contrastText: '#000000'
};

const DestructiveReBrandPaletteColor: SimplePaletteColorOptions = {
  main: '#C20F1E',
  light: '#C20F1E',
  dark: '#930B17'
};

const rawThemeWithPallete = createTheme({
  breakpoints: {values: {...breakpoints.values}},
  palette: {
    ...Palette,
    black: BlackReBrandPaletteColor,
    white: WhiteReBrandPaletteColor,
    destructive: DestructiveReBrandPaletteColor
  }
});

export const theme = createTheme({
  typography: {...Typography(createTheme({breakpoints}))},
  breakpoints: {values: {...rawThemeWithPallete.breakpoints.values}},
  palette: {
    ...rawThemeWithPallete.palette,
    black: rawThemeWithPallete.palette.augmentColor({
      color: BlackReBrandPaletteColor
    }),
    white: rawThemeWithPallete.palette.augmentColor({
      color: WhiteReBrandPaletteColor
    }),
    destructive: rawThemeWithPallete.palette.augmentColor({
      color: DestructiveReBrandPaletteColor
    })
  },
  components: {
    MuiCard: {
      styleOverrides: {
        ...MuiCardStyleOverrides(rawThemeWithPallete)
      }
    },
    MuiButton: {
      styleOverrides: {
        ...MuiButtonStyleOverrides(rawThemeWithPallete)
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        ...MuiFormLabelStyleOverrides()
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        ...MuiFormControlLabelStyleOverrides()
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        ...MuiOutlinedInputStyleOverrides(rawThemeWithPallete)
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: rawThemeWithPallete.spacing(2.5),
          lineHeight: '24px',
          letterSpacing: '0.25px',
          height: 'auto',
        },
        inputSizeSmall: {
          fontSize: rawThemeWithPallete.spacing(2),
          lineHeight: '24px',
          letterSpacing: '0.25px',
          height: 'auto'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'black',
          // couldn't find the right class to make padding right 0, hence using important here
          paddingRight: '8px !important'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          right: rawThemeWithPallete.spacing(2),
        },
        outlined: {
          textOverflow: 'ellipsis',
          [`&.${selectClasses.outlined}`]: {
            minHeight: 'auto',
            paddingLeft: '16px',
            paddingRight: '24px',
            // negative margins used below to woraround the issue of select menu not of
            // same width as select input caused due to padding on outlined input
            // which is as per designs
            marginLeft: '-16px',
            marginRight: '-16px'
          },
          '.MuiTypography-body2': {
            fontSize: rawThemeWithPallete.spacing(2.5),
            lineHeight: '24px',
            letterSpacing: '0.25px'
          },
          [`&.${inputBaseClasses.inputSizeSmall}`]: {
            '.MuiTypography-body2': {
              fontSize: rawThemeWithPallete.spacing(2)
            }
          }
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: rawThemeWithPallete.spacing(5),
          '.MuiTabs-flexContainer': {
            justifyContent: 'space-between'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#000000',
          opacity: 1,
          textAlign: 'left',
          padding: `${rawThemeWithPallete.spacing(3)} ${rawThemeWithPallete.spacing(2)}`,
          '&:active': {
            backgroundColor: '#F5F8FF',
            boxShadow: 'inset 0px 0px 0px 3px #6982C8'
          },
          '&:hover': {
            backgroundColor: '#F5F8FF'
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontFamily: 'Unify Sans',
          color: colors.gray.dark,
          border: `1px solid ${colors.gray.dark}`,
          borderRadius: '0px 0px 2px 2px',
          boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.20),
                      0px 1px 10px 0px rgba(0, 0, 0, 0.12),
                      0px 4px 5px 0px rgba(0, 0, 0, 0.14)
          `
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.25px',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          '&:hover': {
            backgroundColor: '#F5F8FF'
          },
          '.MuiTypography-body2': {
            fontSize: 16,
            lineHeight: '24px',
            letterSpacing: '0.25px'
          },
          '&.Mui-selected': {
            backgroundColor: colors.marine.tint
          },
        },
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          borderRadius: '0px 0px 2px 2px',
          border: `1px solid ${colors.gray.dark}`,
          boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.20),
                      0px 1px 10px 0px rgba(0, 0, 0, 0.12),
                      0px 4px 5px 0px rgba(0, 0, 0, 0.14)
          `,
          '.MuiAutocomplete-option': {
            fontSize: 16,
            lineHeight: '24px',
            letterSpacing: '0.25px'
          },
          '.MuiAutocomplete-option.Mui-focused': {
            backgroundColor: '#F5F8FF'
          },
          '.MuiAutocomplete-option:selected': {
            backgroundColor: colors.marine.tint
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          '&::before': {
            background: rawThemeWithPallete.palette.common.black
          }
        },
        tooltip: {
          background: rawThemeWithPallete.palette.common.black,
          fontFamily: 'Unify Sans',
          fontSize: pxToRem(16),
          lineHeight: pxToRem(24),
          fontWeight: FontWeight.Regular
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: rawThemeWithPallete.spacing(1),
          border: `${rawThemeWithPallete.spacing(0.125)} solid ${colors.gray.neutral}`,
          boxShadow: 'none',
          '&::before': {
            display: 'none'
          },
          ':first-of-type': {
            borderTopLeftRadius: rawThemeWithPallete.spacing(1),
            borderTopRightRadius: rawThemeWithPallete.spacing(1),
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: rawThemeWithPallete.spacing(2),
          [`.${accordionSummaryClasses.content}`]: {
            margin: 0
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: `${rawThemeWithPallete.spacing(0)} ${rawThemeWithPallete.spacing(2)}`,
          paddingBottom: rawThemeWithPallete.spacing(2)
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          verticalAlign: 'bottom',
          borderBottom: 0,
          outlineOffset: '1px',
          [`&.${tableCellClasses.sizeSmall}`]: {
            fontSize: pxToRem(12),
            lineHeight: pxToRem(16),
            padding: rawThemeWithPallete.spacing(1)
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          width: '75vw',
          borderRadius: '8px',
          background: 'white',
          border: `1px solid ${colors.gray.dark}`,
          borderLeft: `8px solid ${rawThemeWithPallete.palette.error.main}`,
          gap: rawThemeWithPallete.spacing(2),
          padding: rawThemeWithPallete.spacing(2),
          '.MuiAlert-icon': {
            width: '32px',
            height: '32px',
            marginRight: 0,
            padding: 0
          },
          '.MuiAlert-message': {
            alignSelf: 'center',
            padding: 0
          },
          '.MuiAlert-action': {
            alignSelf: 'center',
            marginRight: 0,
            padding: 0,
          },
          [rawThemeWithPallete.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            gap: rawThemeWithPallete.spacing(1),
            width: '100%',
            '.MuiAlert-icon': {
              width: '32px',
              height: '32px',
              marginRight: 0,
              padding: 0
            },
            '.MuiAlert-message': {
              flex: '1 0 75%',
              padding: 0
            },
            '.MuiAlert-action': {
              flexBasis: '100%',
              marginRight: 0,
              padding: 0,
              paddingTop: rawThemeWithPallete.spacing(1),
              // 32px -> image width, 8px -> padding
              paddingLeft: 'calc(32px + 8px)'
            }
          }
        }
      }
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    }
  }
});

type Props = {
  children: JSX.Element | JSX.Element[];
}

export const Theme = ({children}: Props): JSX.Element =>
  <ThemeProvider theme={{...theme}}> {children} </ThemeProvider>;
