import {Theme} from '@mui/material';
import {LineHeight} from './LineHeight';
import {FontWeight} from './FontWeight';
import {TypographyOptions} from '@mui/material/styles/createTypography';

const FONT_FAMILY = 'Unify Sans, Arial, Helvetica, sans-serif';
const HTML_FONT_SIZE = 16; // px
const FONT_SIZE = 14; // px

const COEF = FONT_SIZE / 14;

export const pxToRem = (size: number): string => `${size / HTML_FONT_SIZE * COEF}rem`;

export const Typography = (theme: Theme): TypographyOptions => ({
  fontFamily: FONT_FAMILY,
  fontWeightLight: FontWeight.Light,
  fontWeightRegular: FontWeight.Regular,
  fontWeightMedium: FontWeight.SemiBold,
  fontWeightBold: FontWeight.Bold,
  htmlFontSize: HTML_FONT_SIZE,
  fontSize: FONT_SIZE,
  body1: {
    fontSize: pxToRem(18),
    fontWeight: FontWeight.Regular,
    lineHeight: LineHeight.LOOSE,
    letterSpacing: '0.5px'
  },
  body2: {
    fontSize: pxToRem(16),
    fontWeight: FontWeight.Regular,
    lineHeight: LineHeight.LOOSE,
    letterSpacing: '0.25px'
  },
  h1: {
    fontSize: pxToRem(32),
    lineHeight: LineHeight.TIGHT,
    fontWeight: FontWeight.SemiBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: pxToRem(48),
      lineHeight: LineHeight.TIGHT,
      fontWeight: FontWeight.SemiBold
    },
    '&.display': {
      fontSize: pxToRem(56),
      lineHeight: LineHeight.TIGHT,
      [theme.breakpoints.up('lg')]: {
        fontSize: pxToRem(68),
        lineHeight: LineHeight.TIGHT
      }
    }
  },
  h2: {
    fontSize: pxToRem(28),
    lineHeight: LineHeight.MODERATE,
    fontWeight: FontWeight.SemiBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: pxToRem(40),
      lineHeight: LineHeight.MODERATE,
      fontWeight: FontWeight.SemiBold,
    },
    '&.display': {
      fontSize: pxToRem(42),
      lineHeight: LineHeight.TIGHT,
      [theme.breakpoints.up('lg')]: {
        fontSize: pxToRem(60),
        lineHeight: LineHeight.TIGHT
      }
    }
  },
  h3: {
    fontSize: pxToRem(24),
    lineHeight: LineHeight.MODERATE,
    fontWeight: FontWeight.SemiBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: pxToRem(32),
      lineHeight: LineHeight.MODERATE,
      fontWeight: FontWeight.SemiBold
    },
    '&.display': {
      fontSize: pxToRem(36),
      lineHeight: LineHeight.TIGHT,
      [theme.breakpoints.up('lg')]: {
        fontSize: pxToRem(56),
        lineHeight: LineHeight.TIGHT
      }
    }
  },
  h4: {
    fontSize: pxToRem(20),
    lineHeight: LineHeight.MODERATE,
    [theme.breakpoints.up('lg')]: {
      fontSize: pxToRem(24),
      lineHeight: LineHeight.MODERATE
    }
  },
  h5: {
    fontSize: pxToRem(18),
    lineHeight: LineHeight.MODERATE,
    letterSpacing: '0.18px',
    [theme.breakpoints.up('lg')]: {
      fontSize: pxToRem(20),
      lineHeight: LineHeight.MODERATE
    }
  },
  h6: {
    fontSize: pxToRem(16),
    lineHeight: LineHeight.MODERATE,
    letterSpacing: '0.15px',
    fontWeight: FontWeight.Regular
  },
  button: {
    fontSize: pxToRem(14),
    fontWeight: FontWeight.Regular,
    lineHeight: LineHeight.MODERATE
  },
  caption: {
    fontSize: pxToRem(12),
    fontWeight: FontWeight.Regular,
    lineHeight: LineHeight.LOOSE,
    letterSpacing: '0.4px'
  },
  overline: {
    fontSize: pxToRem(12),
    fontWeight: FontWeight.SemiBold,
    lineHeight: LineHeight.MODERATE,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  subtitle1: {
    fontSize: pxToRem(18),
    fontWeight: FontWeight.Regular,
    lineHeight: LineHeight.LOOSE,
    letterSpacing: '0.15px'
  },
  subtitle2: {
    fontSize: pxToRem(16),
    fontWeight: FontWeight.SemiBold,
    lineHeight: LineHeight.LOOSE,
    letterSpacing: '0.1px'
  },
  // TODO: is this needed ?
  spend: {
    fontSize: 9.95771,
    fontWeight: FontWeight.SemiBold,
    lineHeight: LineHeight.LOOSE,
    letterSpacing: '0.829809px',
    textTransform: 'uppercase'
  }
});
