import {Divider, SxProps, Typography, useMediaQuery} from '@mui/material';
import {ScoreName} from 'src/enums/ScoreName';
import {GradeUtils} from 'src/utils/GradeUtils';
import {theme} from 'src/components/theme/Theme';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {colors} from '../theme/common/colors';
import {LineHeight} from '../theme/overrides/LineHeight';
import {FontWeight} from '../theme/overrides/FontWeight';
import styled from 'styled-components';

const COMMON_DENOMINATOR = 100;

export enum GradeSize {
  Large = 'lg',
  Medium = 'md',
  Small = 'sm'
}

const GetSizeInPixels = (size: GradeSize): string => {
  switch (size) {
    case GradeSize.Large:
      return theme.spacing(14.5);
    case GradeSize.Medium:
      return theme.spacing(12);
    case GradeSize.Small:
      return theme.spacing(8);
  }
};

const GetBorderThicknessInPixels = (size: GradeSize): string => {
  switch (size) {
    case GradeSize.Large:
      return theme.spacing(0.625);
    case GradeSize.Medium:
      return theme.spacing(0.5);
    case GradeSize.Small:
      return theme.spacing(0.375);
  }
};

const GetNumeratorFontSize = (size: GradeSize): string => {
  switch (size) {
    case GradeSize.Large:
      return '3.375rem';
    case GradeSize.Medium:
      return '2.5rem';
    case GradeSize.Small:
      return '2rem';
  }
};

const GetDenominatorFontSize = (size: GradeSize): string => {
  switch (size) {
    case GradeSize.Large:
      return '1.5625rem';
    case GradeSize.Medium:
      return '1.25rem';
    case GradeSize.Small:
      return '0.875rem';
  }
};

const GetDividerHeight = (size: GradeSize): string => {
  switch (size) {
    case GradeSize.Large:
      return theme.spacing(0.5);
    case GradeSize.Medium:
      return theme.spacing(0.375);
    case GradeSize.Small:
      return theme.spacing(0.25);
  }
};

interface Props {
  scoreName: ScoreName | undefined;
  scoreValue: number | string | undefined;
  size?: GradeSize;
  inverse?: boolean;
  expandBorder?: boolean;
}

const Numerator = styled(Typography)<{fs: string}>`
  font-size: ${(props) => props.fs};
`;

export const ScoreBox = ({scoreName, scoreValue, size = GradeSize.Medium, inverse = false, expandBorder = false}: Props): JSX.Element => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const color = scoreValue === '-'
    ? colors.gray.neutral
    : inverse ? 'common.white' : 'common.black';

  const sx: SxProps = {
    color,
    fontWeight: FontWeight.SemiBold,
    lineHeight: LineHeight.TIGHT
  };

  return <FlexBoxColumn
    sx={{
      width: isMobile && expandBorder ? '100%' : GetSizeInPixels(size),
      height: GetSizeInPixels(size),
      borderWidth: GetBorderThicknessInPixels(size),
      borderStyle: 'solid',
      borderColor: scoreValue === '-'
        ? 'action.disabled'
        : GradeUtils.getScoreColor(scoreName, theme),
      borderRadius: theme.spacing(0.375),
      gap: size === GradeSize.Small
        ? theme.spacing(0.5)
        : theme.spacing(0.75),
      flexShrink: 0,
      zIndex: 1
    }}
  >
    <Numerator fs={GetNumeratorFontSize(size)} sx={{...sx}}>
      {`${scoreValue}`}
    </Numerator>
    <Divider sx={{borderColor: color, borderBottomWidth: GetDividerHeight(size), width: '66%', maxWidth: '100px'}} />
    <Typography sx={{...sx, fontSize: GetDenominatorFontSize(size)}}>
      {COMMON_DENOMINATOR}
    </Typography>
  </FlexBoxColumn>;
};
