import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {ScoreRangeMax} from 'src/enums/ScoreName';
import {useParams} from 'react-router-dom';
import {useFacebookGrade} from 'src/hooks/facebook/useFacebookGrade';
import {GradeUtils} from 'src/utils/GradeUtils';
import {IFacebookGrade} from 'src/types/facebook/FacebookGrade';
import {ServiceType} from 'src/enums/ServiceType';
import {useContext, useMemo} from 'react';
import {ServiceContext} from 'src/components/providers/service';
import {useOverallGrade} from 'src/hooks/overall/useOverallGrade';
import {IOverallGrade} from 'src/types/overall/OverallGrades';
import FoundationIcon from 'src/assets/Foundation.svg';
import IntermediateIcon from 'src/assets/Intermediate.svg';
import AdvancedIcon from 'src/assets/Advanced.svg';
import styled from 'styled-components';
import {colors} from 'src/components/theme/common/colors';
import IntermediateGrayIcon from 'src/assets/IntermediateGray.svg';
import AdvancedGrayIcon from 'src/assets/AdvancedGray.svg';


interface Props {
  grade: IFacebookGrade | IOverallGrade;
  serviceType: ServiceType;
}

type Grade = 'low' | 'medium' | 'high';

const getIconAndStyle = (gradeType: Grade, level: 'foundation' | 'intermediate' | 'advanced') => {
  switch (gradeType) {
    case 'low':
      if (level === 'foundation') return {icon: FoundationIcon, style: {}};
      return {icon: level === 'intermediate' ? IntermediateGrayIcon : AdvancedGrayIcon, style: {border: `1px solid ${colors.gray.neutral}`}};
    case 'medium':
      if (level === 'foundation') return {icon: FoundationIcon, style: {}};
      if (level === 'intermediate') return {icon: IntermediateIcon, style: {}};
      return {icon: AdvancedGrayIcon, style: {border: `1px solid ${colors.gray.neutral}`}};
    case 'high':
      return {icon: level === 'foundation' ? FoundationIcon : level === 'intermediate' ? IntermediateIcon : AdvancedIcon, style: {}};
    default:
      return {icon: FoundationIcon, style: {}};
  }
};

const MainSectionWrapper = styled(FlexBoxRow).attrs((props: Props) => ({...props}))`
  width: 100%;
  gap: ${({theme}) => theme.spacing(1)};
  padding: ${({theme}) => theme.spacing(1)};
  border-radius: ${({theme}) => theme.spacing(6)};
  background: ${({theme}) => theme.palette.common.white};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.20);
  box-sizing: border-box;
  > div {
    width: calc(100%/3);
    padding: ${({theme}) => theme.spacing(1.25)} ${({theme}) => theme.spacing(2)};
    ${({theme}) => theme.breakpoints.down(600)} {
      padding: ${({theme}) => theme.spacing(1)} 0px;
    }
    &.low {
      border-radius: ${({theme}) => theme.spacing(3.75)} 0px 0px ${({theme}) => theme.spacing(3.75)};
      background-color: ${({theme, grade, serviceType}) => serviceType === ServiceType.FACEBOOK
    ? GradeUtils.getScoreColor(GradeUtils.getScoreName(grade as IFacebookGrade, serviceType), theme)
    : GradeUtils.getScoreColor(GradeUtils.getScoreNameFromNumber((grade as IOverallGrade).overallScore), theme)};
    }
    &.medium {
      background-color: ${({theme, grade, serviceType}) => serviceType === ServiceType.FACEBOOK
    ? (grade as IFacebookGrade).overallScore.overallScore > ScoreRangeMax.FOUNDATIONAL
      ? GradeUtils.getScoreColor(GradeUtils.getScoreName(grade as IFacebookGrade, serviceType), theme)
      : theme.palette.common.white
    : (grade as IOverallGrade).overallScore > ScoreRangeMax.FOUNDATIONAL
      ? GradeUtils.getScoreColor(GradeUtils.getScoreNameFromNumber((grade as IOverallGrade).overallScore), theme)
      : theme.palette.common.white};
    }
    &.high {
      border-radius: 0px ${({theme}) => theme.spacing(3.75)} ${({theme}) => theme.spacing(3.75)} 0px;
      background-color: ${({theme, grade, serviceType}) => serviceType === ServiceType.FACEBOOK
    ? (grade as IFacebookGrade).overallScore.overallScore > ScoreRangeMax.INTERMEDIATE
      ? GradeUtils.getScoreColor(GradeUtils.getScoreName(grade as IFacebookGrade, serviceType), theme)
      : theme.palette.common.white
    : (grade as IOverallGrade).overallScore > ScoreRangeMax.INTERMEDIATE
      ? GradeUtils.getScoreColor(GradeUtils.getScoreNameFromNumber((grade as IOverallGrade).overallScore), theme)
      : theme.palette.common.white};
    }
  }
`;

const ContentBar = ({gradeType}: {gradeType: Grade}): JSX.Element => {
  const foundationData = getIconAndStyle(gradeType, 'foundation');
  const intermediateData = getIconAndStyle(gradeType, 'intermediate');
  const advancedData = getIconAndStyle(gradeType, 'advanced');

  return <>
    <FlexBoxColumn className={'low'} sx={foundationData.style}>
      <img width={28} height={28} src={foundationData.icon} alt='Foundation Score Icon' />
    </FlexBoxColumn>
    <FlexBoxColumn className={'medium'} sx={intermediateData.style}>
      <img width={28} height={28} src={intermediateData.icon} alt='Intermediate Score Icon' />
    </FlexBoxColumn>
    <FlexBoxColumn className={'high'} sx={advancedData.style}>
      <img width={28} height={28} src={advancedData.icon} alt='Advanced Score Icon' />
    </FlexBoxColumn>
  </>;
};

export const MainSection = (): JSX.Element => {
  const {UUID} = useParams();
  const [service] = useContext(ServiceContext);
  const {grade} = service === ServiceType.FACEBOOK ? useFacebookGrade(UUID) : {grade: useOverallGrade(UUID).overallGrade};
  const gradeClass = useMemo(() => {
    if (!grade) return null;

    if (service === ServiceType.FACEBOOK) {
      return (grade as IFacebookGrade).overallScore.overallScore <= ScoreRangeMax.FOUNDATIONAL ? 'low' :
        (grade as IFacebookGrade).overallScore.overallScore <= ScoreRangeMax.INTERMEDIATE ? 'medium' : 'high';
    } else {
      return (grade as IOverallGrade).overallScore <= ScoreRangeMax.FOUNDATIONAL ? 'low' :
        (grade as IOverallGrade).overallScore <= ScoreRangeMax.INTERMEDIATE ? 'medium' : 'high';
    }
  }, [grade, service]);

  return <>
    {
      grade && <MainSectionWrapper serviceType={service} grade={grade}>
        <ContentBar gradeType={gradeClass as Grade} />
      </MainSectionWrapper>
    }
  </>;
};
