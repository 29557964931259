import React from 'react';
import {TableHead, TableRow, TableCell} from '@mui/material';
import {colors} from 'src/components/theme/common/colors';
import {useTranslation} from 'react-i18next';
import {GoogleAdType} from 'src/enums/GoogleAdType';
export const CampaignRankHead = (): JSX.Element => {
  const {t} = useTranslation([GoogleAdType.SHOPPING]);

  return (
    <TableHead>
      <TableRow sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
        <TableCell colSpan={6} size='small' align='right'>
          {t('grade.campaignRankings.table.colHeaders.spend')}
        </TableCell>
        <TableCell colSpan={2} size='small' align='right'>
          {t('grade.campaignRankings.table.colHeaders.impressionsShare')}
        </TableCell>
        <TableCell colSpan={2} size='small' align='right'>
          {t('grade.campaignRankings.table.colHeaders.ctr')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
