import {NavBarContainer} from '../../common/NavBarContainer';
import {GridType} from 'src/enums/GridType';
import {PageContent} from './PageContent';
import {Footer} from 'src/components/common/grade/footer/Footer';
import {useParams} from 'react-router-dom';
import {useContext} from 'react';
import {GoogleAdTypeContext} from '../../providers/adType';
import {IGoogleGrade} from 'src/types/google/GoogleGrade';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {useOverallGrade} from 'src/hooks/overall/useOverallGrade';
import {IOverallGrade} from 'src/types/overall/OverallGrades';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {useMediaQuery} from '@mui/material';
import {theme} from 'src/components/theme/Theme';
import {FadeInTransition} from 'src/components/common/FadeInTransition';
import {IFacebookGrade} from 'src/types/facebook/FacebookGrade';
import {LoadingContext} from 'src/components/providers/loading';

type ContentWithBackGroundProps = {
  gridType: GridType.TAB | GridType.FORM;
  elements: JSX.Element | JSX.Element[];
  additionalFooter?: JSX.Element;
  grade: IGoogleGrade | IOverallGrade | IFacebookGrade | undefined;
  isMobile: boolean;
};

export const FooterContent = ({...props}: ContentWithBackGroundProps): JSX.Element => {
  const {isProgressBarComplete} = useContext(LoadingContext);
  switch (props.gridType) {
    case 'TAB':
      if (isProgressBarComplete)
        return <FadeInTransition triggerAfter={750}>
          <Footer sx={{mt: props.isMobile ? 2 : 7, width: '100vw'}} />
        </FadeInTransition>;
      else
        return <FlexBoxColumn sx={{width: '100vw', alignItems: 'stretch'}}>
          {props.additionalFooter}
        </FlexBoxColumn>;
    default:
      return <FlexBoxColumn sx={{width: '100vw', alignItems: 'stretch'}}>
        {props.additionalFooter}
      </FlexBoxColumn>;
  }
};

const ContentWithFooter = ({...props}: ContentWithBackGroundProps) => {
  return <>
    <PageContent gridType={props.gridType}>
      {props.elements}
    </PageContent>
    <FlexBoxColumn>
      <FooterContent {...props} />
    </FlexBoxColumn>
  </>;
};

type Props = {
  gridType: GridType.TAB | GridType.FORM;
  children: JSX.Element | JSX.Element[];
  additionalFooter?: JSX.Element;
};

export const GoogleAdsPage = (props: Props): JSX.Element => {
  const {children, additionalFooter, gridType} = props;
  const {googleAdType} = useContext(GoogleAdTypeContext);
  const {UUID} = useParams();
  const {grade} = googleAdType !== GoogleAdType.NONE
    ? {grade: useOverallGrade(UUID).overallGrade}
    : {grade: undefined};
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <NavBarContainer>
    <ContentWithFooter
      additionalFooter={additionalFooter}
      elements={children}
      grade={grade}
      gridType={gridType}
      isMobile={isMobile}
    />
  </NavBarContainer>;
};
