import {Typography, Box, styled, useMediaQuery} from '@mui/material';
import {FlexBoxRow} from './FlexBoxRow';
import {theme} from '../theme/Theme';
import logo from 'src/assets/WordStreamLogo.svg';

const StyledRow = styled(FlexBoxRow)`
  height: ${({theme}) => theme.spacing(8)};
  width: 100%;
  background-color: ${props => props.theme.palette.background.default};
  flex-wrap: wrap;
`;

const ImageContainer = styled(Box)`
  flex: 0.96;
  display: flex;
  align-items: center;
  ${({theme}) => theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`;

const Link = styled('a')`
  cursor: pointer;
`;

type Props = {
  accountName?: string;
}

export const NavBar = ({accountName}: Props): JSX.Element => {
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return <StyledRow id='nav-bar'>
    <ImageContainer>
      <Link href='https://www.wordstream.com/' target='_blank'
        tabIndex={-1}
        aria-label='WordStream by LOCALiQ'>
        <img src={logo} id="logo" alt='WordStream By LOCALiQ' />
      </Link>
    </ImageContainer>
    {!isMobile && accountName && <Typography variant='subtitle1' color='common.black'>{accountName}</Typography>}
  </StyledRow>;
};
