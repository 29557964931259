import {OutlinedInputClasses, Theme, autocompleteClasses, outlinedInputClasses} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';
import {colors} from '../common/colors';

type ReturnType = Partial<OverridesStyleRules<keyof OutlinedInputClasses, 'MuiOutlinedInput'>>;

export const MuiOutlinedInputStyleOverrides = (theme: Theme): ReturnType => ({
  root: {    
    [`&.${outlinedInputClasses.root}`]: {
      padding: `${theme.spacing(2.25)} ${theme.spacing(2)}`
    },
    [`&.${outlinedInputClasses.root}.${autocompleteClasses.inputRoot}`]: {
      padding: `${theme.spacing(2.25)} ${theme.spacing(2)}`
    },
    [`&.${outlinedInputClasses.sizeSmall}`]: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`
    },
    [`.${outlinedInputClasses.input}`]: {
      paddingRight: 0,
      padding: 0
    },
    '.Mui-disabled': {
      opacity: 0.38
    },
    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: colors.gray.dark,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '2px',
      padding: 0,
      boxShadow: '0px 0px 0px 3px #6982C8'
    },
    [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
      boxShadow: '0px 0px 0px 2px #C20F1E'
    }
  },
  notchedOutline: {
    borderColor: colors.gray.dark,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '2px'
  }
});
