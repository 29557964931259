import {TableRow, TableCell, Table, TableBody} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {IAPStats} from 'src/types/facebook/FacebookGrade';
import {TableRowMobile} from '../TableRowMobile';
import {AudiencePerformanceStat} from './APTableCellMobile';
import {GradeUtils} from 'src/utils/GradeUtils';
import {ServiceType} from 'src/enums/ServiceType';
import {colors} from '../../../../theme/common/colors';
import {theme} from 'src/components/theme/Theme';
import {withSymbol} from '../utils';

interface Props extends IAPStats {
  currencySymbol: string;
}

export const SummaryRow = ({impressions, clicks, spend, results, currencySymbol}: Props): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);

  return <TableRow sx={{background: colors.gray.light, borderBottom: `1px solid ${colors.gray.neutral}`}}>
    <TableCell>
      {t('grade.audiencePerformance.table.total')}
    </TableCell>
    <TableCell colSpan={2} align='right'>
      {impressions?.toLocaleString()}
    </TableCell>
    <TableCell align='right'>
      {clicks?.toLocaleString()}
    </TableCell>
    <TableCell align='right'>
      {withSymbol(spend, currencySymbol)}
    </TableCell>
    <TableCell align='right'>
      {results?.toLocaleString()}
    </TableCell>
  </TableRow>;
};

export const SummaryMobile = ({impressions, clicks, spend, results, currencySymbol}: Props): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);

  return <Table sx={{width: '100%', tableLayout: 'fixed', borderSpacing: `0 ${theme.spacing(1)}`, borderCollapse: 'separate', background: colors.gray.light, marginTop: -1}}>
    <TableBody>
      <TableRow>
        <TableCell padding='none' sx={{px: 1, fontWeight: 600}}>
          {t('grade.audiencePerformance.table.total')}
        </TableCell>
      </TableRow>
      <TableRowMobile text={AudiencePerformanceStat.IMPRESSIONS} value={impressions?.toLocaleString() ?? '-'} isBold={true} />
      <TableRowMobile text={AudiencePerformanceStat.CLICKS} value={clicks?.toLocaleString() ?? '-'} isBold={true} />
      <TableRowMobile text={AudiencePerformanceStat.SPEND} value={spend ? `${currencySymbol}${GradeUtils.roundoff(spend, 2).toFixed(2)}` : '-'} isBold={true} />
      <TableRowMobile text={AudiencePerformanceStat.RESULTS} value={results?.toLocaleString() ?? '-'} isBold={true} />
    </TableBody>
  </Table>;
};
