import {SxProps, Theme, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {GoogleShoppingScoreSection} from 'src/enums/google/ScoreSection';
import {ScoreName} from 'src/enums/ScoreName';
import {useShoppingGradeScore} from 'src/hooks/shopping/useShoppingGradeScrore';
import {IShoppingGrade} from 'src/types/shopping/ShoppingGrade';
import {GradeUtils} from 'src/utils/GradeUtils';
import {ReactComponent as AlertIcon} from 'src/assets/AlertIconCurrent.svg';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {theme} from 'src/components/theme/Theme';
import styled from 'styled-components';

interface Props {
  sectionName: GoogleShoppingScoreSection;
}

const Metric = ({sectionName, grade}: Props & {grade: IShoppingGrade}) => {
  switch (sectionName) {
    case GoogleShoppingScoreSection.WASTED_SPEND:
      return grade.negativeKeywords.wasteLocal
        .toLocaleString(
          process.env.REACT_APP_LOCALE,
          {minimumFractionDigits: 2, style: 'currency', currency: grade.negativeKeywords.currencyCode}
        );
    case GoogleShoppingScoreSection.ACCOUNT_STRUCTURE:
      return grade.accountStructureMetrics.missingConversions
        .toLocaleString(
          process.env.REACT_APP_LOCALE,
          {minimumFractionDigits: 0}
        );
    case GoogleShoppingScoreSection.CAMPAIGN_RANKINGS:
      return grade.shoppingPerformanceMetrics.impressionShare ? (grade.shoppingPerformanceMetrics.impressionShare / 100).toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 0, style: 'percent'}) : '-';
  }
};

interface IconProps {
  scorename: ScoreName | undefined;
  theme: Theme;
}

const StyledAlertIcon = styled(AlertIcon)`
  fill: ${({scorename, theme}: IconProps) => scorename ? GradeUtils.getScoreColor(scorename, theme) : 'none'};
`;

const Duration = ({sectionName}: Props): JSX.Element => {
  const Text = (text: string) => <Typography variant='overline' textTransform={'uppercase'} sx={{fontWeight: 'fontWeightMedium'}}>
    {text}
  </Typography>;

  switch (sectionName) {
    case GoogleShoppingScoreSection.WASTED_SPEND:
      return Text('Next 12 Months');
    case GoogleShoppingScoreSection.ACCOUNT_STRUCTURE:
    case GoogleShoppingScoreSection.CAMPAIGN_RANKINGS:
      return Text('Last 90 Days');
    default:
      return <></>;
  }
};

interface ContentProps {
  title: string;
  scoreName: ScoreName | undefined;
  sectionName: GoogleShoppingScoreSection;
  metric: string;
}

const Content = ({title, scoreName, sectionName, metric}: ContentProps): JSX.Element => {
  const sx: SxProps = {
    whiteSpace: sectionName === GoogleShoppingScoreSection.WASTED_SPEND ? 'pre' : 'normal',
    width: sectionName === GoogleShoppingScoreSection.ACCOUNT_STRUCTURE ? theme.spacing(36) : 'auto'
  };

  return (
    <FlexBoxColumn gap={2} alignSelf={'stretch'}>
      <FlexBoxColumn gap={0.5} alignSelf={'stretch'} sx={{...sx}}>
        <BoldTypography textAlign={'center'} variant='h4'>
          {title}
        </BoldTypography>
        {Duration({sectionName})}
      </FlexBoxColumn>
      <FlexBoxRow gap={1}>
        {
          scoreName !== ScoreName.ADVANCED && <StyledAlertIcon scorename={scoreName} />
        }
        <Typography variant='h3' whiteSpace={'pre'}>{metric}</Typography>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export const ShoppingKeyMetric = ({sectionName, sectionKey}: Props & {sectionKey: string}): JSX.Element => {
  const {shoppingGrade, scoreName} = useShoppingGradeScore({sectionName});
  const metric = shoppingGrade && Metric({sectionName, grade: shoppingGrade});
  const {t} = useTranslation([GoogleAdType.SHOPPING]);

  return shoppingGrade
    ? Content(
      {
        title: t('grade.keyMetricScoreTitle', {context: sectionKey}),
        scoreName,
        sectionName,
        metric: metric ?? '-'
      }
    )
    : <></>;
};
