import {Typography, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {PerformanceMetrics} from 'src/components/common/metrics/PerformanceMetrics';
import {theme} from 'src/components/theme/Theme';
import {useDisplayGrade} from 'src/hooks/display/useDisplayGrade';
import {GoogleAdType} from 'src/enums/GoogleAdType';

export const DisplayMetrics = (): JSX.Element => {
  const {UUID} = useParams();
  const {displayGrade} = useDisplayGrade(UUID);
  const {t} = useTranslation(['display']);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <FlexBoxColumn gap={isMobile ? 3 : 4.5} sx={{width: '100%'}}>
    {
      displayGrade &&
        <PerformanceMetrics
          metrics={{
            ...displayGrade.displayPerformanceMetrics,
            currencySymbol: displayGrade.overallScore.currencySymbol ?? '$'
          }}
          adType={GoogleAdType.DISPLAY}
        />
    }
    {/*  Footer Starts */}
    <Typography variant='caption' sx={{color: 'text.disabled'}} textAlign={isMobile ? 'center' : 'left'}>
      {t('metrics.performanceFooter')}
    </Typography>
    {/*  Footer Ends */}
  </FlexBoxColumn>;
};
