import {CardClasses, Theme} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';
import {colors} from '../common/colors';

type ReturnType = Partial<OverridesStyleRules<keyof CardClasses, 'MuiCard'>>;

export const MuiCardStyleOverrides = (theme: Theme): ReturnType => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: `${theme.spacing(0.125)} solid ${colors.gray.dark}`,
    boxSizing: 'border-box',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow: 'none'
  }
});
