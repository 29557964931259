import React, {Component} from 'react';
import {Chart, ChartOptions, ChartDataset} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {LabelOptions} from 'chartjs-plugin-datalabels/types/options';
import {getResponsiveChartFont} from 'src/utils/helpers';
import {colors} from 'src/components/theme/common/colors';

export interface IHorizontalBarGraphProps {
  dataset: number[];
  labels: string[];
  xAxisText: string;
  options?: ChartOptions;
  isMobile?: boolean;
}

const barStyling: Partial<ChartDataset<'bar'>> = {
  borderColor: 'rgba(215, 215, 215, 0.25)',
  borderWidth: 1,
  barThickness: 24,
  borderRadius: {
    topLeft: 0,
    topRight: 24,
    bottomLeft: 0,
    bottomRight: 24
  }
};

export const labelOptions: LabelOptions = {
  anchor: 'end',
  align: 'right',
  color: '#000',
  offset: 8,
  font: (ctx) => {
    return {
      family: 'Unify Sans',
      weight: 'bolder',
      size: ctx.chart.width > 600
        ? 24 : 12,
      lineHeight: ctx.chart.width > 600
        ? '30px'
        : '16px'
    };
  }
};

export class HorizontalBarGraph extends Component<IHorizontalBarGraphProps> {
  canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();

  chart: Chart | undefined;

  options: ChartOptions = {
    ...this.props.options,
    layout: {
      padding: (ctx) => {
        const right = ctx.chart.width > 600
          ? 80
          : 16;
        return {
          left: 0,
          bottom: 0,
          top: 0,
          right
        };
      }
    },
    indexAxis: 'y',
    font: {
      size: this.props.isMobile ? 12 : 16,
      lineHeight: this.props.isMobile ? '16px' : '24px',
      weight: 'bold',
      family: 'Unify Sans'
    },
    scales: {
      x: {
        title: {
          display: true,
          text: this.props.xAxisText,
          color: '#000',
          font: getResponsiveChartFont,
        },
        grid: {
          display: true,
          drawTicks: false,
          drawOnChartArea: true,
          drawBorder: true,
          borderColor: '#737373',
          color: '#737373'
        },
        ticks: {
          ...this.props.options
          && this.props.options.scales
          && this.props.options.scales.x
            ? {
              ...this.props.options.scales.x.ticks,
              maxTicksLimit: 6,
              padding: this.props.isMobile ? 8 : 16,
              color: '#000',
              font: {
                size: this.props.isMobile
                  ? 12 : 14,
                lineHeight: '16px',
                family: 'Unify Sans',
                weight: 'normal'
              }
            } : {}
        }
      },
      y: {
        title: {
          display: false
        },
        grid: {
          display: true,
          drawTicks: false,
          drawOnChartArea: true,
          drawBorder: true,
          borderColor: '#737373',
          color: '#737373',
        },
        ticks: {
          ...this.props.options
          && this.props.options.scales
          && this.props.options.scales.y
            ? {
              ...this.props.options.scales.y.ticks,
              padding: this.props.isMobile ? 4 : 16,
              maxTicksLimit: 2,
              color: '#000',
              font: {
                size: this.props.isMobile
                  ? 12 : 14,
                lineHeight: '16px',
                family: 'Unify Sans',
                weight: 'normal'
              }
            } : {}
        },
        afterFit: (scaleInstance) => {
          if (scaleInstance.chart.width > 600) {
            scaleInstance.width = 120;
          } else {
            scaleInstance.width = 72;
          }
        }
      }
    }
  };

  drawChart (): void {
    const canvasContext = this.canvasRef.current?.getContext('2d') as CanvasRenderingContext2D;

    const barColors = [colors.chart.orange, colors.chart.blue];
    barStyling.backgroundColor = this.props.dataset.map((_, index) => barColors[index % barColors.length]);

    this.chart = new Chart(canvasContext, {
      type: 'bar',
      data: {
        labels: [
          this.props.labels[0],
          this.props.labels[1].split(' '),
        ],
        datasets: [
          {
            data: this.props.dataset,
            ...barStyling,
            datalabels: {
              ...labelOptions,
            }
          }
        ],
      },
      plugins: [
        ChartDataLabels
      ],
      options: {
        ...this.options
      }
    });
  }

  componentDidMount (): void {
    this.chart?.destroy();
    this.drawChart();
  }

  componentDidUpdate (): void {
    this.chart?.destroy();
    this.drawChart();
  }

  render (): JSX.Element {
    return <div style={{height: this.props.isMobile ? '200px' : '340px', width: '100%'}}>
      <canvas id='chart' ref={this.canvasRef} />
    </div>;
  }
}
