import {SxProps, Typography, useMediaQuery} from '@mui/material';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {ServiceType} from 'src/enums/ServiceType';
import {useTranslation} from 'react-i18next';
import {useFAGradeScore} from 'src/hooks/facebook/useFAGradeScore';
import {ServiceContext} from 'src/components/providers/service';
import {GradeSize, ScoreBox} from '../../ScoreBox';
import {useContext} from 'react';
import {useLeftPosition} from './useLeftPosition';
import {theme} from 'src/components/theme/Theme';
import {useOverallGradeScore} from 'src/hooks/overall/userOverallGradeScore';
import caret from 'src/assets/Caret.svg';
import styled from 'styled-components';

const PopOver = styled(FlexBoxRow)`
  background: ${({theme}) => theme.palette.common.white};
  padding: ${({theme}) => theme.spacing(2)};
  gap: ${({theme}) => theme.spacing(2)};
  border-radius: ${({theme}) => theme.spacing(1)};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14);
`;

type Props = {
  pointDown?: boolean;
  sx?: SxProps;
}

export const PopOverSection = ({pointDown = true, sx}: Props): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const {t} = useTranslation([service]);
  const {scoreName, scoreValue, date} = service === ServiceType.FACEBOOK
    ? useFAGradeScore({})
    : useOverallGradeScore();
  const {left} = useLeftPosition({scoreName});
  const absoluteSx: SxProps = {
    position: 'absolute',
    left: useMediaQuery(theme.breakpoints.down('sm'))
      ? 'initial' : left,
    bottom: '92px'
  };
  const caretLeftPosition = service === ServiceType.FACEBOOK
    ? '166px' // ( 340/2 + 32/2 - 40/2 ) -> (Box Width / 2  + padding-x / 2 - image-wdith / 2)
    : '157px'; // ( 322/2 + 32/2 - 40/2 ) -> (Box Width / 2  + padding-x / 2 - image-wdith / 2)

  return (
    <FlexBoxColumn sx={{alignSelf: 'stretch'}}>
      <PopOver sx={pointDown ? absoluteSx : {...sx}}>
        <ScoreBox scoreName={scoreName} scoreValue={scoreValue} size={GradeSize.Medium} />
        <FlexBoxColumn className='scoreinfo' sx={{alignItems: 'flex-start'}}>
          <Typography variant='overline' sx={{whiteSpace: 'pre'}}>{t('grade.header.popover.title')}</Typography>
          <Typography variant='h3' fontWeight={'fontWeightMedium'}>{scoreName}</Typography>
          <Typography variant='caption'>{t('grade.header.popover.asOf')} {date}</Typography>
        </FlexBoxColumn>
        {
          pointDown
            ? <img src={caret} style={{position: 'absolute', width: '40px', height: '40px', left: caretLeftPosition, bottom: '-20px'}} />
            : <></>
        }
      </PopOver>
    </FlexBoxColumn>
  );
};
