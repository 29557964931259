import {FormLabelClasses} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';
import {FontWeight} from './FontWeight';
import {pxToRem} from './Typography';
import {colors} from '../common/colors';

type ReturnType = Partial<OverridesStyleRules<keyof FormLabelClasses, 'MuiFormLabel'>>;

export const MuiFormLabelStyleOverrides = (): ReturnType => ({
  root: {
    color: colors.common.black,
    alignSelf: 'flex-start',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    letterSpacing: '0.25px',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: FontWeight.SemiBold
  }
});
