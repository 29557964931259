import {TableCell, TableRow} from '@mui/material';
import {GradeUtils} from 'src/utils/GradeUtils';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {GoogleDisplayScoreSection} from 'src/enums/google/ScoreSection';
import {useDisplayGradeScore} from 'src/hooks/display/useDisplayGradeScore';
import {useTranslation} from 'react-i18next';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {colors} from 'src/components/theme/common/colors';

type Props = {
  impressions: number;
  cpc: number;
  ctr: number;
  spend: number;
  clicks: number;
  sectionName: GoogleDisplayScoreSection;
  cellsWidth?: string;
}

export const AdPerformanceTableRow = ({impressions, cpc, ctr, spend, clicks, sectionName, cellsWidth}: Props): JSX.Element => {
  const {scoreName, currencyCode} = useDisplayGradeScore({sectionName});

  return (
    <TableRow sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
      <TableCell align='center' sx={{width: cellsWidth, backgroundColor: GradeUtils.getScoreColorLight(scoreName!)}}>
        <BoldTypography variant='subtitle2'>
          {impressions.toLocaleString(process.env.REACT_APP_LOCALE)}
        </BoldTypography>
      </TableCell>
      <TableCell align='center' sx={{width: cellsWidth}}>
        {cpc.toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2, style: 'currency', currency: currencyCode})}
      </TableCell>
      <TableCell align='center' sx={{width: cellsWidth}}>
        {(ctr / 100).toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2, style: 'percent'})}
      </TableCell>
      <TableCell align='center' sx={{width: cellsWidth}}>
        {spend.toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2, style: 'currency', currency: currencyCode})}
      </TableCell>
      <TableCell align='center' sx={{width: cellsWidth}}>
        {clicks.toLocaleString(process.env.REACT_APP_LOCALE)}
      </TableCell>
    </TableRow>
  );
};

export const TableRowHeader = (): JSX.Element => {
  const {t} = useTranslation([GoogleAdType.DISPLAY]);
  return (
    <>
      <TableRow sx={{borderBottom: `1px solid ${colors.gray.neutral}`}}>
        <TableCell align='center' size='small'>
          <BoldTypography variant='caption'>{t('grade.displayAdPlacements.impressions')}</BoldTypography>
        </TableCell>
        <TableCell align='center' size='small'>
          <BoldTypography variant='caption'>{t('grade.displayAdPlacements.cpc')}</BoldTypography>
        </TableCell>
        <TableCell align='center' size='small'>
          <BoldTypography variant='caption'>{t('grade.displayAdPlacements.ctr')}</BoldTypography>
        </TableCell>
        <TableCell align='center' size='small'>
          <BoldTypography variant='caption'>{t('grade.displayAdPlacements.spend')}</BoldTypography>
        </TableCell>
        <TableCell align='center' size='small'>
          <BoldTypography variant='caption'>{t('grade.displayAdPlacements.clicks')}</BoldTypography>
        </TableCell>
      </TableRow>
    </>
  );
};
