import {MenuItem, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useMetricSelect} from 'src/components/common/metrics/useMetricSelect';
import StyledSelect from 'src/components/common/styled/StyledSelect';
import {theme} from 'src/components/theme/Theme';
import {ServiceType} from 'src/enums/ServiceType';

export const MetricSelect = (): JSX.Element => {
  const {value, onSelect} = useMetricSelect();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {t} = useTranslation([ServiceType.GOOGLE]);

  return (
    <StyledSelect data-testid="metricSelect" value={value} onChange={onSelect} sx={{width: isMobile ? '100%' : '296px'}} size='small'>
      <MenuItem value="overallScore">{t('tracker.overall_score')}</MenuItem>
      <MenuItem value="wastedSpend">{t('tracker.wasted_spend')}</MenuItem>
      <MenuItem value="qualityScore">{t('tracker.quality_score')}</MenuItem>
      <MenuItem value="accountActivity">{t('tracker.account_activity')}</MenuItem>
      <MenuItem value="impressionShare">{t('tracker.impression_share')}</MenuItem>
      <MenuItem value="cpa">{t('tracker.cpa')}</MenuItem>
      <MenuItem value="spend">{t('tracker.spend')}</MenuItem>
    </StyledSelect>
  );
};
