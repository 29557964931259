import styled from 'styled-components';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {theme} from 'src/components/theme/Theme';

export const SpendFlexBoxColumn = styled(FlexBoxColumn)`
  background-color: ${({theme}) => theme.palette.background.default};
  box-sizing: border-box;
  border: ${({theme}) => theme.spacing(0.125)} solid ${({theme}) => theme.palette.common.black};
  border-radius: ${({theme}) => theme.spacing(1)};
  padding: ${({theme}) => `${theme.spacing(1)} ${theme.spacing(4)}`};
`;

export const SpendWrapperFlexBoxColumn = styled(FlexBoxColumn)({
  borderLeft: `${theme.spacing(0.125)} solid ${theme.palette.common.black}`,
  borderTop: `${theme.spacing(0.125)} solid ${theme.palette.common.black}`,
  borderRight: `${theme.spacing(0.125)} solid ${theme.palette.common.black}`,
  borderTopRightRadius: theme.spacing(1),
  borderTopLeftRadius: theme.spacing(1)
});
