import {Typography, useMediaQuery} from '@mui/material';
import {DataSegment} from './DataSegment';
import {GoogleDisplayScoreSection} from 'src/enums/google/ScoreSection';
import {AdditionalMetric} from './AdditionalMetrics';
import {useTranslation} from 'react-i18next';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {useParams} from 'react-router-dom';
import {useDisplayGrade} from 'src/hooks/display/useDisplayGrade';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {theme} from 'src/components/theme/Theme';

export const AudienceSegments = (): JSX.Element => {
  const {t} = useTranslation([GoogleAdType.DISPLAY]);
  const {UUID} = useParams();
  const {displayGrade} = useDisplayGrade(UUID);
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <FlexBoxColumn sx={{width: '100%', gap: 3}}>
      <FlexBoxColumn sx={{width: '100%', gap: isMobile ? 1 : 2}}>
        <Typography variant='h3' fontWeight={'fontWeightBold'} sx={{
          alignSelf: 'flex-start',
        }}>{t('grade.audiencePerformance.yourData')}</Typography>
        <DataSegment
          metric={displayGrade?.displayAudienceMetrics.remarketingImpressions.toLocaleString(process.env.REACT_APP_LOCALE)}
          metricLabel={t('grade.audiencePerformance.impressions')}
          title={t('grade.audiencePerformance.segmentTitle', {context: 'yourData'})}
          description={t('grade.audiencePerformance.segmentDescription', {context: 'yourData'})}
          sectionName={GoogleDisplayScoreSection.AUDIENCE_PERFORMANCE}
        />
        {displayGrade &&
          <AdditionalMetric
            key={'additional-metric-yourData'}
            metrics={{
              spend: displayGrade.displayAudienceMetrics.remarketingSpend,
              clicks: displayGrade.displayAudienceMetrics.remarketingClicks
            }}
          />}
      </FlexBoxColumn>
      <FlexBoxColumn sx={{width: '100%', gap: isMobile ? 1 : 2}}>
        <Typography variant='h3' fontWeight={'fontWeightBold'} sx={{
          alignSelf: 'flex-start',
        }}>{t('grade.audiencePerformance.segments')}</Typography>
        <DataSegment
          metric={displayGrade?.displayAudienceMetrics.affinityImpressions.toLocaleString(process.env.REACT_APP_LOCALE)}
          metricLabel={t('grade.audiencePerformance.impressions')}
          title={t('grade.audiencePerformance.segmentTitle', {context: 'affinity'})}
          description={t('grade.audiencePerformance.segmentDescription', {context: 'affinity'})}
          sectionName={GoogleDisplayScoreSection.AUDIENCE_PERFORMANCE}
        />
        {displayGrade &&
          <AdditionalMetric
            key={'additional-metric-affinity'}
            metrics={{
              spend: displayGrade.displayAudienceMetrics.affinitySpend,
              clicks: displayGrade.displayAudienceMetrics.affinityClicks
            }}
          />}
      </FlexBoxColumn>
      <FlexBoxColumn sx={{width: '100%', gap: isMobile ? 1 : 2}}>
        <DataSegment
          metric={displayGrade?.displayAudienceMetrics.intentImpressions.toLocaleString(process.env.REACT_APP_LOCALE)}
          metricLabel={t('grade.audiencePerformance.impressions')}
          title={t('grade.audiencePerformance.segmentTitle', {context: 'intent'})}
          description={t('grade.audiencePerformance.segmentDescription', {context: 'intent'})}
          sectionName={GoogleDisplayScoreSection.AUDIENCE_PERFORMANCE}
        />
        {displayGrade &&
          <AdditionalMetric
            key={'additional-metric-intent'}
            metrics={{
              spend: displayGrade.displayAudienceMetrics.intentSpend,
              clicks: displayGrade.displayAudienceMetrics.intentClicks
            }}
          />}
      </FlexBoxColumn>
      <FlexBoxColumn sx={{width: '100%', gap: isMobile ? 1 : 2}}>
        <DataSegment
          metric={displayGrade?.displayAudienceMetrics.otherDisplayImpressions.toLocaleString(process.env.REACT_APP_LOCALE)}
          metricLabel={t('grade.audiencePerformance.impressions')}
          title={t('grade.audiencePerformance.segmentTitle', {context: 'otherDisplay'})}
          description={t('grade.audiencePerformance.segmentDescription', {context: 'otherDisplay'})}
          sectionName={GoogleDisplayScoreSection.AUDIENCE_PERFORMANCE}
        />
        {displayGrade &&
          <AdditionalMetric
            key={'additional-metric-otherDisplay'}
            metrics={{
              spend: displayGrade.displayAudienceMetrics.otherDisplaySpend,
              clicks: displayGrade.displayAudienceMetrics.otherDisplayClicks
            }}
          />}
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};
