import {FormProvider} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import useShareModal from './useShareModal';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {StyledDialog} from 'src/components/common/styled/StyledDialog';
import {IEmailReportResponse} from '../../../types/common/IEmailReport';
import {FlexBoxColumn} from '../FlexBoxColumn';
import {StyledFormLabel} from 'src/components/common/forms/FormLabel';
import {FormInputText} from 'src/components/common/forms/inputs/FormInputText';
import {Button, useMediaQuery} from '@mui/material';
import {theme} from 'src/components/theme/Theme';

type Props = {
  open: boolean;
  onClose: () => void;
  onResponse: (response: IEmailReportResponse) => void;
}

export const ShareReportModal = ({open, onClose, onResponse}: Props): JSX.Element => {
  const {t} = useTranslation(['common']);
  const {methods, onSubmit} = useShareModal({open, onClose, onResponse});
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledDialog data-testid="shareReportModal" disableAutoFocus={true} open={open} onClose={onClose} headertext={t('shareReport')}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <FlexBoxColumn sx={{alignItems: 'stretch', gap: isMobile ? 2.5 : 3.5}}>
            <FlexBoxColumn sx={{gap: 1}}>
              <StyledFormLabel error={!!methods.formState.errors.shareEmail} data-testid='shareReportModalEmailLabel'>
                {t('emailSendTo')}
              </StyledFormLabel>
              <FormInputText
                name='shareEmail'
                data-testid='shareReportModalEmail'
                sx={{backgroundColor: 'common.white'}}
                placeholder='Email To'
              />
            </FlexBoxColumn>
            <FlexBoxColumn sx={{gap: 1}}>
              <StyledFormLabel error={!!methods.formState.errors.fromEmail} data-testid='shareReportModalFromEmailLabel'>
                {t('yourEmail')}
              </StyledFormLabel>
              <FormInputText
                name='fromEmail'
                data-testid='shareReportModalFromEmail'
                sx={{backgroundColor: 'common.white'}}
                placeholder='Email From'
              />
            </FlexBoxColumn>
            <FlexBoxColumn sx={{gap: 1}}>
              <StyledFormLabel>{t('emailMessage')}</StyledFormLabel>
              <FormInputText
                name='messageText'
                data-testid='shareReportModalMessageText'
                sx={{backgroundColor: 'common.white'}}
              />
            </FlexBoxColumn>
            <FlexBoxRow sx={{alignSelf: 'flex-start'}}>
              <Button data-testid="shareReportModalSubmit"
                type='submit' color='secondary'
                variant='contained' size='large'
              >
                {t('send')}
              </Button>
            </FlexBoxRow>
          </FlexBoxColumn>
        </form>
      </FormProvider>
    </StyledDialog>
  );
};
