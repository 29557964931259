import {GoogleIconSignIn} from 'src/components/googleAds/GoogleIconSignIn';
import {GoogleAdsPage} from 'src/components/googleAds/page/Page';
import {ContentCardForErrors} from 'src/components/common/ContentCard';
import {GridType} from 'src/enums/GridType';
import {useTranslation} from 'react-i18next';
import {Button, Link, Typography, useMediaQuery} from '@mui/material';
import ErrorFooter from 'src/components/googleAds/errors/ErrorFooter';
import {ErrorEvent, useErrorContent} from 'src/hooks/common/useErrorContent';
import {GradeMyAccountBtn} from 'src/components/common/GradeMyAccountBtn';
import {ServiceType} from 'src/enums/ServiceType';
import {theme} from 'src/components/theme/Theme';

export const GoogleAdsError = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.GOOGLE]);
  const {event, errorTitle, errorBody, email} = useErrorContent();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <GoogleAdsPage gridType={GridType.FORM} additionalFooter={<ErrorFooter />}>
      <ContentCardForErrors title={errorTitle} body={errorBody}>
        {
          event === ErrorEvent.TIMED_OUT
            // we are just showing content error body in this case
            ? <></>
            : event === ErrorEvent.PROCESS
              ? <>
                <Button
                  variant='contained'
                  color='secondary'
                  size='medium'
                  LinkComponent={Link}
                  href='https://www.wordstream.com/learn'
                  target='_blank'
                  style={{textDecoration: 'none'}}>
                  {t('error.button_nospend')}
                </Button>
              </>
              : email
                ? <><Typography variant='caption' sx={{pb: isMobile ? 2 : 3}}>{t('error.caption')}</Typography><GoogleIconSignIn /></>
                : <><Typography variant='caption' sx={{pb: isMobile ? 2 : 3}}>{t('error.caption')}</Typography><GradeMyAccountBtn /></>
        }
      </ContentCardForErrors>
    </GoogleAdsPage>
  );
};
