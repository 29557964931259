import {Trans, useTranslation} from 'react-i18next';
import {ServiceType} from 'src/enums/ServiceType';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {useParams} from 'react-router-dom';
import {useFacebookGrade} from 'src/hooks/facebook/useFacebookGrade';
import {Divider, styled, Typography, useMediaQuery} from '@mui/material';
import {theme} from 'src/components/theme/Theme';
import {getAdSetPlacement} from './utils';
import {StyledCard} from 'src/components/common/styled/StyledCard';
import {colors} from 'src/components/theme/common/colors';
import {LineHeight} from 'src/components/theme/overrides/LineHeight';

const StyledTextColumn = styled(FlexBoxColumn)`
  flex: 1 0 0;
  padding: ${({theme}) => theme.spacing(5)};
  gap: ${({theme}) => theme.spacing(1)};
  align-self: stretch;
  ${({theme}) => theme.breakpoints.down(600)} {
    padding: ${({theme}) => theme.spacing(3)};
  }
`;

export const AutomaticPlacement = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const {UUID} = useParams();
  const {grade} = useFacebookGrade(UUID);
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <FlexBoxColumn gap={2} sx={{paddingTop: 3, width: '100%'}}>
      <ResponsiveRow sx={{justifyContent: 'left'}} width={'100%'} breakpoint={600}>
        <BoldTypography variant='h5'>{t('metrics.platformOverview.automaticPlacement.heading')}</BoldTypography>
      </ResponsiveRow>
      <StyledCard sx={{width: '100%', p: 0}}>
        <ResponsiveRow breakpoint={600}
          sx={{width: '100%', height: '100%', justifyContent: 'stretch', alignItems: 'stretch'}}
        >
          <FlexBoxColumn gap={isMobile ? 1 : 2} sx={{p: isMobile ? 3 : 5, background: theme.palette.background.default}}>
            <BoldTypography variant='overline' textAlign={'center'} sx={{whiteSpace: isMobile ? 'normal' : 'break-spaces'}}>
              <Trans t={t} i18nKey='metrics.platformOverview.automaticPlacement.heading' />
            </BoldTypography>
            {
              grade && <BoldTypography variant='h3'>
                {getAdSetPlacement(grade).toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 0, style: 'percent'})}
              </BoldTypography>
            }
          </FlexBoxColumn>
          <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem={true} sx={{borderColor: colors.gray.dark}} />
          <StyledTextColumn sx={{alignItems: 'flex-start'}}>
            <FlexBoxRow>
              <BoldTypography variant='h5' lineHeight={LineHeight.LOOSE} >
                <Trans t={t}
                  i18nKey={t('metrics.platformOverview.automaticPlacement.statLine')}
                  values={{
                    activeAdSets: grade?.accountSummary.activeAdSets,
                    automaticPlacementsCount: grade?.accountSummary.automaticPlacementsCount
                  }}
                />
              </BoldTypography>
            </FlexBoxRow>
            <FlexBoxRow>
              <Typography variant='body1'>{t('metrics.platformOverview.automaticPlacement.body')}</Typography>
            </FlexBoxRow>
          </StyledTextColumn>
        </ResponsiveRow>
      </StyledCard>
    </FlexBoxColumn>
  );
};

