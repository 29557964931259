import {Divider, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CustomerJourneyHeader} from './CustomerJourneyHeader';
import {CustomerJourneyRow, CustomerJourneyRowMobile} from './CustomerJourneyRow';
import {FacebookAdsScoreSection} from 'src/enums/facebook/ScoreSection';
import {useFAGradeScore} from 'src/hooks/facebook/useFAGradeScore';
import {ServiceType} from 'src/enums/ServiceType';
import {BoldTypography} from '../../../../common/styled/BoldTypography';
import {theme} from 'src/components/theme/Theme';
import {colors} from 'src/components/theme/common/colors';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

export const CustomerJourney = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const {grade} = useFAGradeScore({sectionName: FacebookAdsScoreSection.CAMPAIGN_GOALS});
  const {campaignGoals} = grade || {};

  return (
    <Table sx={{tableLayout: 'fixed'}}>
      <CustomerJourneyHeader />
      <TableBody>
        <CustomerJourneyRow title={t('grade.campaignGoals.table.customerJourney.allCampaigns')} campaigns={campaignGoals?.totalCampaigns ?? 0}
          impressions={campaignGoals?.totalImpressions} clicks={campaignGoals?.totalClicks} results={campaignGoals?.totalResults}
          boldImpressions={true} boldClicks={true} boldResults={true} sx={{background: theme.palette.black.main, color: theme.palette.white.main}} />
        <CustomerJourneyRow title={t('grade.campaignGoals.table.customerJourney.campaignsForAwareness')} campaigns={campaignGoals?.awareness.campaignCount ?? 0}
          boldImpressions={true} impressions={campaignGoals?.awareness.impressions} clicks={campaignGoals?.awareness.clicks} results={campaignGoals?.awareness.totalResults} />
        <CustomerJourneyRow title={t('grade.campaignGoals.table.customerJourney.campaignsForConsideration')} campaigns={campaignGoals?.consideration.campaignCount ?? 0}
          boldClicks={true} impressions={campaignGoals?.consideration.impressions} clicks={campaignGoals?.consideration.clicks} results={campaignGoals?.consideration.totalResults} />
        <CustomerJourneyRow title={t('grade.campaignGoals.table.customerJourney.campaignsForConversion')} campaigns={campaignGoals?.conversion.campaignCount ?? 0}
          boldResults={true} impressions={campaignGoals?.conversion.impressions} clicks={campaignGoals?.conversion.clicks} results={campaignGoals?.conversion.totalResults} />
      </TableBody>
    </Table>
  );
};

export const CustomerJourneyMobile = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const {grade} = useFAGradeScore({sectionName: FacebookAdsScoreSection.CAMPAIGN_GOALS});
  const {campaignGoals} = grade || {};

  return (
    <Table sx={{width: '100%', tableLayout: 'fixed', borderSpacing: `0 ${theme.spacing(2)}`, borderCollapse: 'separate'}}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} padding='none'>
            <FlagOutlinedIcon sx={{fontSize: '24px'}} />
            <BoldTypography variant='h4' sx={{display: 'inline', pl: 1}}>{t('grade.campaignGoals.table.customerJourney.header')}</BoldTypography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <CustomerJourneyRowMobile title={t('grade.campaignGoals.table.customerJourney.allCampaigns')} boldTitle={true} campaigns={campaignGoals?.totalCampaigns ?? 0}
          impressions={campaignGoals?.totalImpressions} clicks={campaignGoals?.totalClicks} results={campaignGoals?.totalResults}
          boldImpressions={true} boldClicks={true} boldResults={true} sx={{background: theme.palette.black.main, color: theme.palette.white.main}} />
        <TableRow><TableCell colSpan={2} sx={{py: 0.5, px: 0}}><Divider sx={{width: '100%', borderColor: colors.gray.neutral}} /></TableCell></TableRow>
        <CustomerJourneyRowMobile title={t('grade.campaignGoals.table.customerJourney.campaignsForAwareness')} boldTitle={true} campaigns={campaignGoals?.awareness.campaignCount ?? 0}
          boldImpressions={true} impressions={campaignGoals?.awareness.impressions} clicks={campaignGoals?.awareness.clicks} results={campaignGoals?.awareness.totalResults}
          sx={{padding: 0}} />
        <TableRow><TableCell colSpan={2} sx={{py: 0.5, px: 0}}><Divider sx={{width: '100%', borderColor: colors.gray.neutral}} /></TableCell></TableRow>
        <CustomerJourneyRowMobile title={t('grade.campaignGoals.table.customerJourney.campaignsForConsideration')} boldTitle={true} campaigns={campaignGoals?.consideration.campaignCount ?? 0}
          boldClicks={true} impressions={campaignGoals?.consideration.impressions} clicks={campaignGoals?.consideration.clicks} results={campaignGoals?.consideration.totalResults}
          sx={{padding: 0}} />
        <TableRow><TableCell colSpan={2} sx={{py: 0.5, px: 0}}><Divider sx={{width: '100%', borderColor: colors.gray.neutral}} /></TableCell></TableRow>
        <CustomerJourneyRowMobile title={t('grade.campaignGoals.table.customerJourney.campaignsForConversion')} boldTitle={true} campaigns={campaignGoals?.conversion.campaignCount ?? 0}
          boldResults={true} impressions={campaignGoals?.conversion.impressions} clicks={campaignGoals?.conversion.clicks} results={campaignGoals?.conversion.totalResults}
          sx={{padding: 0}} />
      </TableBody>
    </Table>
  );
};
