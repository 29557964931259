import {BaseDialog} from 'src/components/common/styled/StyledDialog';
import {useTranslation} from 'react-i18next';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {FontWeight} from 'src/components/theme/overrides/FontWeight';
import {GradientLine} from 'src/components/common/GradientLine';
import {theme} from 'src/components/theme/Theme';
import TipsCarousel from '../tipsCarousel/TipsCarousel';
import LoadingHeader from './LoadingHeader';
import Backdrop from '../../common/Backdrop';
import useLoadingModal from './useLoadingModal';

const LoadingModal = (): JSX.Element => {
  const {open, onClose} = useLoadingModal();
  const {t} = useTranslation(['keywords']);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <BaseDialog
    disableAutoFocus={true}
    open={open}
    onClose={onClose}
    BackdropComponent={Backdrop}
    maxWidth={'lg'}
    PaperProps={{sx: {borderRadius: theme.spacing(1)}}}
  >
    <>
      <FlexBoxColumn sx={{p: 0, pb: isMobile ? 0 : 3.5}}>
        <FlexBoxRow sx={{width: '100%', paddingBottom: 3}}>
          <Typography variant='h4' fontWeight={FontWeight.SemiBold}>
            {t('form.label', {context: 'gettingKeywords'})}
          </Typography>
        </FlexBoxRow>
        <GradientLine />
      </FlexBoxColumn>
      <Box sx={{px: 4, pb: isMobile ? 2 : 3}}><LoadingHeader ismobile={isMobile} /></Box>
      <Box sx={{mx: -3, mb: -3}}><TipsCarousel /></Box>
    </>
  </BaseDialog>;
};

export default LoadingModal;
