import {Box, Link, styled, Typography, useMediaQuery, useTheme} from '@mui/material';
import {colors} from 'src/components/theme/common/colors';
import {Trans, useTranslation} from 'react-i18next';
import {FlexBoxColumn} from '../../common/FlexBoxColumn';
import PPCUni from 'src/assets/PPCUni.svg';
import BadgeFooter from 'src/components/common/BadgeFooter';
import {ServiceType} from 'src/enums/ServiceType';
import {BoldTypography} from '../../common/styled/BoldTypography';
import {ResponsiveRow} from '../../common/ResponsiveRow';
import {StyledPageContent} from 'src/components/common/styled/StyledPageElements';
import MetaPartnerSVG from 'src/assets/MetaPartner.svg';

const StyledLink = styled(Link)(({theme}) => ({
  cursor: 'pointer',
  color: theme.palette.primary.main,
}));

const ImageBox = styled(Box)`
  border: 1px solid ${colors.gray.dark};
  border-radius: ${({theme}) => theme.spacing(1)};
  padding: 0 ${({theme}) => theme.spacing(1)};
  background-color: ${({theme}) => theme.palette.common.white};
`;


const ErrorFooter = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FlexBoxColumn sx={{alignItems: 'stretch'}}>
      <StyledPageContent sx={{py: isMobile ? 3 : 5, alignSelf: 'center'}}>
        <ResponsiveRow breakpoint={'sm'} gap={isMobile ? 3 : 5}>
          <ImageBox><img src={PPCUni} alt='WordStream PPC University' /></ImageBox>
          <FlexBoxColumn gap={2} sx={{alignItems: isMobile ? 'center' : 'flex-start'}}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <BoldTypography variant="h4">{t('error.footer.header')}</BoldTypography>
            <Typography variant="body1">
              <Trans t={t} i18nKey='error.footer.content' components={[
                <StyledLink
                  key="ppc_uni"
                  href={t('error.footer.link_ppc_uni')}
                  target="_blank"
                />
              ]} />
            </Typography>
          </FlexBoxColumn>
        </ResponsiveRow>
      </StyledPageContent>
      <BadgeFooter i18nNameSpace={ServiceType.FACEBOOK} badgeSVG={MetaPartnerSVG} iconHref={t('facebookBadgeLink')} />
    </FlexBoxColumn>
  );
};

export default ErrorFooter;
