import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {styled} from '@mui/material';
import {IKeyword} from 'src/types/keywords/Keyword';
import {GradeUtils} from 'src/utils/GradeUtils';
import {capitalizeFirstLetter} from 'src/utils/helpers';
import adwordsIcon from 'src/assets/adwords-logo-small.svg';
import {Trans, useTranslation} from 'react-i18next';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {FontWeight} from 'src/components/theme/overrides/FontWeight';

type Props = {
  row: IKeyword;
}

const StripedTableRow = styled(TableRow)(({theme}) => ({
  boxShadow: '0px -1px 0px 0px #0000001F inset',
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.default
  }
}));

const FlexCell = styled(FlexBoxRow)(({theme}) => ({
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(0.5)
}));

export const KeywordTRow = ({row: {keywordText, searchVolume, lowTopPageBid, highTopPageBid, competition}}: Props): JSX.Element => {
  return (
    <StripedTableRow>
      <TableCell component="th" scope="row">
        {keywordText}
      </TableCell>
      <TableCell>{searchVolume.toLocaleString(process.env.REACT_APP_LOCALE)}</TableCell>
      <TableCell>
        {
          lowTopPageBid > 0
            ? `$${GradeUtils.roundoff(lowTopPageBid, 2).toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2})}`
            : '-'
        }
      </TableCell>
      <TableCell>
        {
          highTopPageBid > 0
            ? `$${GradeUtils.roundoff(highTopPageBid, 2).toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2})}`
            : '-'
        }
      </TableCell>
      <TableCell>{capitalizeFirstLetter(competition)}</TableCell>
    </StripedTableRow>
  );
};

export const KeywordTHead = (): JSX.Element => {
  const {t} = useTranslation(['keywords']);

  return (
    <StripedTableRow>
      <TableCell>
        {t('table.header.keyword')}
      </TableCell>
      <TableCell>
        <FlexCell>
          <img src={adwordsIcon} width={20} height={16} alt='Wordstream Keyword Search' />
          <>{t('table.header.volume')}</>
        </FlexCell>
      </TableCell>
      <TableCell sx={{fontWeight: FontWeight.Regular}}>
        <Trans t={t} i18nKey={'table.header.topPageBid'} />
        <br />
        <Trans t={t} i18nKey={'table.header.bidLow'} />
      </TableCell>
      <TableCell sx={{fontWeight: FontWeight.Regular}}>
        <Trans t={t} i18nKey={'table.header.topPageBid'} />
        <br />
        <Trans t={t} i18nKey={'table.header.bidHigh'} />
      </TableCell>
      <TableCell>
        <FlexCell>
          <img src={adwordsIcon} width={20} height={16} alt='Wordstream Keyword Results' />
          <>{t('table.header.competition')}</>
        </FlexCell>
      </TableCell>
    </StripedTableRow>
  );
};
